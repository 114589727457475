/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimePicker, Tooltip, Switch, Select, Input } from 'antd';
import dayjs from 'dayjs';
import { KTSVG } from '../../../../_metronic/helpers';
import { find, cloneDeep, min, map, parseInt } from 'lodash';
import { toast } from 'react-toastify';
import ResetModal from './ResetModal';
import request from '../../../../utils/request';
import { getIndonesiaConfigBaseUrl } from '../../../../utils/config';

import '../index.scss';

const format = 'HH:mm';
const { TextArea } = Input;
// const marginOptions = [{ value: '单支爆仓', label: '单支爆仓' }];
// const coverOptions = [{ value: 'T+1', label: 'T+1' }];
// const fixtureOptions = [{ value: '立即成交', label: '立即成交' }];
const BaseForm = (props) => {
	const {
		formData,
		settingType,
		leverageKey,
		tableAction,
		settingName,
		getSettings,
		apiUrl,
		tabActive,
	} = props;
	const [formDataClone, setFormDataClone] = useState(cloneDeep(formData));
	const [isError, setIsError] = useState(false);
	const [errorItem, setErrorItem] = useState();
	const [leverageData, setLeverageData] = useState([]);
	const [isResetModal, setIsResetModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// const formik = useFormik({
	// 	// validationSchema: editAdminSchema,
	// 	onSubmit: async (values, { setSubmitting }) => {
	// 		setSubmitting(true);
	// 		try {
	// 		} catch (ex) {
	// 			console.error(ex);
	// 		} finally {
	// 			setSubmitting(true);
	// 			// cancel(true);
	// 		}
	// 	},
	// });
	const resetForm = () => {
		setIsResetModal(true);
		// setFormDataClone(cloneDeep(formData));
	};
	const resetFormSure = async () => {
		console.log('formData', formData);
		const url = apiUrl
			? apiUrl
			: `${getIndonesiaConfigBaseUrl()}/v1/system_setting/reset`;
		await request.put(url, {
			setting_type: settingType,
		});
		// setFormDataClone(cloneDeep(formData));
		setIsResetModal(false);
		toast.success('重置成功');
		getSettings(settingType);
		tableAction.reload();
	};
	const saveSettings = async () => {
		console.log('formDataClone', formDataClone);
		window.canFormSubmit = false;
		const url = apiUrl
			? apiUrl
			: `${getIndonesiaConfigBaseUrl()}/v1/system_setting`;
		let settingSys = '';
		if (tabActive == 7) {
			settingSys = 'EXCHANGE';
		} else if (tabActive == 4) {
			settingSys = 'INDONESIA';
		} else if (tabActive == 8) {
			settingSys = 'INDONESIA_GOLD';
		}
		let resp;
		if (apiUrl) {
			setIsLoading(true);
			resp = await request.put(url, {
				setting_type: settingType,
				setting_sys: settingSys,
				data: formDataClone,
			});
			setIsLoading(false);
		} else {
			resp = await request.put(url, {
				setting_type: settingType,
				data: formDataClone,
			});
		}
		if (!apiUrl) {
			toast.success('设置成功');
			getSettings(settingType);
			resp.setting_type && tableAction.reload();
		}
	};
	useEffect(() => {
		setFormDataClone(cloneDeep(formData));
	}, [settingType]);
	useEffect(() => {
		settingType === 'LEVERAGE' &&
			setLeverageData(
				formDataClone?.find((item) => item.loan_type == leverageKey)?.data || []
			);
	}, [leverageKey]);
	return (
		<>
			<div
				id="kt_modal_add_user_form"
				className={`form risk-base-form ${
					settingType === 'LEVERAGE' ? 'd-flex flex-wrap col-12' : 'mt-3'
				}`}
				// onSubmit={formik.handleSubmit}
				// noValidate
			>
				{(settingType === 'LEVERAGE' ? leverageData : formDataClone)?.map(
					(item, index) => {
						return (
							// eslint-disable-next-line react/jsx-key
							<div
								key={index}
								className={`${
									settingType == 'LEVERAGE'
										? 'col-6'
										: settingType === 'ADMIN_IP'
										? ''
										: 'mx-3'
								}`}
							>
								{(item.type == 'float' || item.type == 'input') && (
									<div
										// className="row mb-4 my-3 ps-9"
										className="row mb-6 m-3"
										key={
											settingType == 'LEVERAGE'
												? `${item.key} + ${item.value} + ${leverageKey}`
												: `${item.key} + ${Date.parse(new Date())}`
										}
									>
										<div
											className="col-4 d-flex align-items-center"
											style={{ paddingRight: 0, paddingLeft: 0 }}
										>
											<label className="col-form-label fw-semibold fs-6">
												{item.name}
											</label>
											{item.tip && (
												<Tooltip title={item.tip}>
													<span style={{ width: '20px' }}>
														<KTSVG
															path="/media/icons/duotune/general/gen044.svg"
															className="svg-icon-1tx my-2"
															style={{ color: '#99A1B7' }}
														/>
													</span>
												</Tooltip>
											)}
										</div>
										<div className="col-8 fv-row fv-plugins-icon-container">
											<Input
												type={`${item.type == 'float' ? 'number' : 'text'}`}
												name={item.key}
												className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
												placeholder={`请输入${item.name}`}
												defaultValue={
													item.unit == '%'
														? (item.value * 1000000) / 10000
														: item.value
												}
												addonAfter={item.unit || ''}
												onChange={(e) => {
													// const value = e.target.value.split(',');
													const valueArr = map(
														e.target.value.split(','),
														parseInt
													);
													const minItem = min(valueArr);
													if (settingType == 'MARGIN') {
														if (item.type == 'input') {
															if (minItem < formDataClone[index - 1].value) {
																setIsError(true);
																setErrorItem(item);
															} else {
																setIsError(false);
															}
														} else {
															if (formDataClone[index + 1]?.type == 'input') {
																// const valueArr =
																// 	formDataClone[index + 1].value.split(',');
																const valueArr = map(
																	formDataClone[index + 1]?.value.split(','),
																	parseInt
																);
																const minItem = min(valueArr);
																if (minItem < Number(e.target.value)) {
																	setIsError(true);
																	setErrorItem(formDataClone[index + 1]);
																} else {
																	setIsError(false);
																}
															}
														}
													}
													if (settingType === 'LEVERAGE') {
														find(
															find(formDataClone, { loan_type: leverageKey })
																?.data,
															{ key: item.key }
														).value =
															(item.unit == '%'
																? e.target.value / 100
																: e.target.value) ||
															find(
																find(formDataClone, {
																	loan_type: leverageKey,
																})?.data,
																{ key: item.key }
															).value;
													} else {
														find(formDataClone, { key: item.key }).value =
															item.unit == '%'
																? e.target.value / 100 ||
																  find(formDataClone, { key: item.key }).value
																: e.target.value ||
																  find(formDataClone, { key: item.key }).value;
													}
												}}
											/>
											<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
										</div>
										{isError &&
											errorItem.key == item?.key &&
											errorItem.type == 'input' && (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														<span role="alert">
															请输入大于
															{formDataClone[index - 1]?.value || item.value}
															的数字
														</span>
													</div>
												</div>
											)}
										{/* <div className="col-lg-6 fv-row fv-plugins-icon-container">
									<input
										type="text"
										name="lname"
										className="form-control form-control-lg form-control-solid"
										placeholder="Last name"
										value="Smith"
									/>
									<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
								</div> */}
									</div>
								)}
								{item.type == 'time' && (
									<div
										className="row mb-6 m-3"
										key={`${item.key} + ${Date.parse(new Date())}`}
									>
										<div
											className="col-4 d-flex align-items-center"
											style={{ paddingRight: 0, paddingLeft: 0 }}
										>
											<label className="col-form-label fw-semibold fs-6">
												{item.name}
											</label>
											{item.tip && (
												<Tooltip title={item.tip}>
													<span style={{ width: '20px' }}>
														<KTSVG
															path="/media/icons/duotune/general/gen044.svg"
															className="svg-icon-1tx my-2"
															style={{ color: '#99A1B7' }}
														/>
													</span>
												</Tooltip>
											)}
										</div>
										<div className="col-8 fv-row fv-plugins-icon-container">
											<TimePicker
												onChange={(time, timeString) => {
													find(formDataClone, { key: item.key }).value =
														timeString ||
														find(formDataClone, { key: item.key }).value;
												}}
												showNow={false}
												defaultValue={dayjs(item.value, format)}
												format={format}
											/>
											<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
										</div>
									</div>
								)}
								{item.type == 'switch' && (
									<div
										// className="row mb-6 m-3 col-9 d-flex alignItemsCenter"
										className="row mb-6 m-3"
										key={index}
									>
										<label
											className="col-4 col-form-label fw-semibold fs-6"
											style={{ paddingLeft: 0 }}
										>
											{item.name}
										</label>
										<label className="col-5 form-check form-switch form-check-custom form-check-solid d-flex">
											<Switch
												checkedChildren="是"
												unCheckedChildren="否"
												defaultChecked={item.value}
												onChange={(e) => {
													find(formDataClone, { key: item.key }).value = e;
												}}
											/>
											{/* <input
												className="form-check-input"
												type="checkbox"
												defaultChecked={item.value}
												onChange={(e) => {
													find(formDataClone, { key: item.key }).value =
														e.target.checked;
												}}
											/> */}
										</label>
									</div>
								)}
								{item.type == 'select' && (
									<div className="row mb-6 m-3" key={index}>
										<label className="col-3 col-form-label fw-semibold fs-6">
											{item.name}
										</label>

										<div className="col-8 fv-row fv-plugins-icon-container">
											<Select
												defaultValue={item.select_value[0]}
												options={item.select_value?.map((item) => {
													return { label: item, value: item };
												})}
											/>
										</div>
									</div>
								)}
								{item.type == 'textarea' && (
									<div className="row mb-6 m-3" key={index}>
										<label className="col-form-label fw-semibold fs-6">
											{item.name}
										</label>

										<div className="col-10 fv-row fv-plugins-icon-container">
											<Input.TextArea
												rows={5}
												onChange={(e) => {
													find(formDataClone, { key: item.key }).value =
														e.target.value ||
														find(formDataClone, { key: item.key }).value;
												}}
												className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
												placeholder={`请输入${item.name}`}
											/>
										</div>
									</div>
								)}
							</div>
						);
					}
				)}
			</div>
			<div className="text-center pt-10">
				{!apiUrl && (
					<button
						type="reset"
						onClick={() => resetForm()}
						className="btn btn-light me-3"
					>
						重置
					</button>
				)}

				<button
					type="submit"
					className="btn btn-primary"
					onClick={saveSettings}
					disabled={isError}
				>
					{!isLoading && <span className="indicator-label">保存设置</span>}
					{isLoading && (
						<span className="indicator-progress" style={{ display: 'block' }}>
							等待中...{' '}
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
					)}
				</button>
			</div>
			{isResetModal && (
				<ResetModal
					settingName={settingName}
					setIsResetModal={setIsResetModal}
					resetFormSure={resetFormSure}
				/>
			)}
		</>
	);
};
BaseForm.propTypes = {
	formData: PropTypes.array,
	tableAction: PropTypes.object,
	settingType: PropTypes.string,
	leverageKey: PropTypes.string,
	settingName: PropTypes.string,
	getSettings: PropTypes.func,
	apiUrl: PropTypes.string,
	tabActive: PropTypes.number,
};
export default BaseForm;
