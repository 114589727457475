import request from './request';

export async function getRechargeUnauditNum() {
	return request('/deposit/deposits', {
		method: 'get',
		params: {
			filters: {
				status: 'SUBMITTED',
			},
		},
	});
}

export async function getWithdrawUnauditNum() {
	return request('/withdrawal/withdrawals', {
		method: 'get',
		params: {
			filters: {
				status: 'SUBMITTED',
			},
		},
	});
}

export async function verifyToken(token) {
	return request(`auth/verify`, {
		method: 'post',
		data: { token },
	});
}

export async function getSystemSettings() {
	return request('/system_setting', {
		method: 'get',
		params: {
			setting_type: 'LINK',
		},
	});
}

export async function getNotifications(type, pagination = {}, status = '') {
	return request('/notification', {
		method: 'get',
		params: {
			notification_type: type,
			status,
			...pagination,
		},
	});
}

export async function getNotificationSummary() {
	return request('/notification/summary', {
		method: 'get',
	});
}

export async function testWsNotification(params) {
	return request('/notification', {
		method: 'put',
		data: params,
	});
}
