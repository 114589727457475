import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { KTSVG } from '../../../_metronic/helpers';
import request from '../../../utils/request';

import './Uploader.scss';

const defaultImage = '/media/default/default_cover.png';
const defaultImageDark = '/media/default/default_cover_dark.png';

const Uploader = (props) => {
	const themeMode = useSelector((state) => state.global.themeMode);
	const { handleImageChange, initialImage } = props;
	const [imagePath, setImagePath] = useState(
		themeMode === 'light' ? defaultImage : defaultImageDark
	);
	useEffect(() => {
		setImagePath(initialImage);
	}, [initialImage]);

	const doUpload = async (file) => {
		let formData = new FormData();
		formData.append('file', file);
		try {
			const resp = await request.post('/upload', formData);
			const { path } = resp;
			setImagePath(path);
			handleImageChange(path);
		} catch (e) {
			console.log(e);
		}
	};
	return (
		<div className="image-input image-input-empty" data-kt-image-input="true">
			<div className="image-input-wrapper w-125px h-125px">
				<img
					src={imagePath}
					className="image"
					// style={{
					// 	filter:
					// 		themeMode === 'dark' && imagePath === defaultImage
					// 			? 'invert(100%)'
					// 			: 'invert(0)',
					// }}
				/>
			</div>

			<label
				className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
				data-kt-image-input-action="change"
				data-bs-toggle="tooltip"
				data-bs-dismiss="click"
				title="上传"
			>
				<KTSVG
					path="/media/icons/duotune/general/gen006.svg"
					className="svg-icon-1"
				/>
				<input
					type="file"
					name="file"
					accept=".png, .jpg, .jpeg"
					onChange={(e) => {
						const file = e.target.files[0];
						doUpload(file);
					}}
				/>
			</label>

			{imagePath !== defaultImage && (
				<label
					className="btn btn-icon btn-circle btn-color-muted btn-active-color-danger w-25px h-25px bg-body shadow btn-image-remove"
					data-bs-toggle="tooltip"
					data-bs-dismiss="click"
					title="删除"
					onClick={() => {
						setImagePath(
							themeMode === 'light' ? defaultImage : defaultImageDark
						);
						handleImageChange(
							themeMode === 'light' ? defaultImage : defaultImageDark
						);
					}}
				>
					<KTSVG
						path="/media/icons/duotune/general/gen027.svg"
						className="svg-icon-1"
					/>
				</label>
			)}
		</div>
	);
};

Uploader.propTypes = {
	initialImage: PropTypes.string,
	handleImageChange: PropTypes.func,
};

export default Uploader;
