import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/zh-cn.js';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';

const iconMap = {
	USER_STATUS: {
		color: 'primary',
		icon: 'communication/com014.svg',
	},
	BALANCE_STATUS: {
		color: 'warning',
		icon: 'finance/fin010.svg',
	},
	SYSTEM: {
		color: 'success',
		icon: 'general/gen062.svg',
	},
};

const subTypeMap = {
	status: {
		path: '/user-management/user-list',
		search: { status: '1' },
	},
	audit_status: {
		path: '/user-management/user-list',
		search: { auth_status: 'SUBMITTED' },
	},
	withdrawal: {
		path: '/order-management/withdraw-list',
		search: { status: 'SUBMITTED' },
	},
	deposit: {
		path: '/order-management/recharge-list',
		search: { status: 'SUBMITTED' },
	},
};

const NotificationItem = ({ data }) => {
	return (
		<>
			{data.length <= 0 && (
				<div
					className="d-flex flex-column align-items-center justify-content-center"
					style={{ height: '100%' }}
				>
					<img
						src={toAbsoluteUrl(
							'/media/illustrations/custom/notification-no-result.png'
						)}
						style={{ width: '175px', height: '175px' }}
					/>
					<span style={{ color: '#7e8299' }}>暂无消息内容</span>
				</div>
			)}
			{/* <div className="scroll-y mh-325px my-5 px-8"> */}
			{data.map(
				({
					id,
					notification_type,
					title,
					content,
					status,
					sub_type,
					create_time,
				}) => (
					<Link
						reloadDocument={true}
						key={id}
						// to={`${subTypeMap[sub_type].path}?search=${JSON.stringify(
						// 	subTypeMap[sub_type].search
						// )}`}
						to={`${subTypeMap[sub_type]?.path}`}
						onClick={(e) => {
							if (!subTypeMap[sub_type]) {
								e.preventDefault();
							}
						}}
						// state={subTypeMap[sub_type].search}
					>
						<div className="d-flex flex-stack py-4">
							<div className="d-flex align-items-center">
								<div className="symbol symbol-35px me-4">
									<span
										className={clsx(
											'symbol-label',
											`bg-light-${iconMap[notification_type].color}`
										)}
									>
										{' '}
										<KTSVG
											path={`/media/icons/duotune/${iconMap[notification_type].icon}`}
											className={`svg-icon-1 svg-icon-${iconMap[notification_type].color}`}
										/>
									</span>
									{status === 'UNREAD' && (
										<span
											className="symbol-badge badge badge-circle bg-danger start-100"
											style={{ width: '10px', height: '10px' }}
										/>
									)}
								</div>

								<div className="mb-0 me-2">
									<span className="fs-6 text-gray-800 fw-bolder">{title}</span>
									<div className="text-gray-600 fs-7">{content}</div>
								</div>
							</div>

							<span className="badge badge-light fs-8">
								{moment(create_time).fromNow()}
							</span>
						</div>
					</Link>
				)
			)}
			{/* </div> */}
		</>
	);
};

NotificationItem.propTypes = {
	data: PropTypes.array,
};

export default NotificationItem;
