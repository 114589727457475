/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import './index.scss';
const SureFundsModifyModalForm = ({
	user,
	setSureModal,
	setShowModal,
	available,
	amount,
	direction,
	action,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const submitManumal = async () => {
		setIsSubmitting(true);
		const resp = await request.post('/account/manual', {
			amount: direction === 'add' ? +amount : -amount,
			user_id: user.user_id,
		});
		setIsSubmitting(false);
		if (resp.user_id) {
			setShowModal(false);
			setSureModal(false);
			toast.success('修改成功');
			const simpleSearchBtn = document.querySelector(
				'.simple-table-search button'
			);
			if (simpleSearchBtn) {
				simpleSearchBtn.click();
			} else {
				action.reload();
			}
		}
	};
	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_sure_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-600px">
					{/* begin::Modal content */}
					<div className="modal-content">
						<div className="modal-header">
							{/* begin::Modal title */}
							<h2 className="fw-bolder">确认调整可用资金</h2>
							{/* end::Modal title */}

							{/* begin::Close */}
							<div
								className="btn btn-icon btn-sm btn-active-icon-primary"
								data-kt-users-modal-action="close"
								onClick={() => setSureModal(false)}
								style={{ cursor: 'pointer' }}
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon-1"
								/>
							</div>
							{/* end::Close */}
						</div>
						<div className="modal-body scroll-y mx-3 mx-xl-5 my-5">
							{/* <form id="kt_modal_sure_user_form" className="form" noValidate> */}
							{/* begin::Scroll */}
							<div
								className="d-flex flex-column scroll-y me-n7 pe-7"
								id="kt_modal_add_user_scroll"
								data-kt-scroll="true"
								data-kt-scroll-activate="{default: false, lg: true}"
								data-kt-scroll-max-height="auto"
								data-kt-scroll-dependencies="#kt_modal_add_user_header"
								data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
								data-kt-scroll-offset="300px"
							>
								<div className="fv-row mb-7">
									<div className="card shadow-sm badge-light p-3">
										<h5>{user.full_name}</h5>
										<div className="flex" style={{ lineHeight: '17px' }}>
											<span className="flex">
												<KTSVG path="/media/icons/duotune/electronics/elc002.svg" />
												<span className="ms-1">{user.phone}</span>
											</span>
											<span className="flex ms-3">
												<KTSVG path="/media/icons/duotune/communication/com006.svg" />
												<span className="ms-1">
													{user.user_type === 'TRADER'
														? '普通用户'
														: user.user_type === 'AGENT'
														? '代理'
														: '管理员'}
												</span>
											</span>
											<span className="flex ms-3">
												<KTSVG path="/media/icons/duotune/communication/com014.svg" />
												<span className="ms-1">
													{`${user.parent_username}(${
														user.parent_phone || '无'
													})`}
												</span>
											</span>
											<span className="flex ms-3">
												<KTSVG path="/media/icons/duotune/general/gen047.svg" />
												<span
													className="ms-1"
													style={{
														color: `${user.is_blocked ? '#ff4d4f' : '#50cd89'}`,
													}}
												>
													{user.is_blocked ? '锁定' : '正常'}
												</span>
											</span>
										</div>
									</div>
								</div>
								<div className="card mt-10 shadow-sm badge-light p-3">
									<div className="fs-6 d-flex justify-content-between mb-4 flex-direction-row">
										<div className="fw-semibold">原可用资金</div>
										<span className="fw-bold">{available}</span>
									</div>
									<div className="separator separator-dashed"></div>
									<div className="fs-6 d-flex justify-content-between mb-4 mt-4 flex-direction-row">
										<div className="fw-semibold">
											{direction === 'reduce' ? '减少' : '增加'}金额
										</div>
										<span
											className={`${
												direction === 'reduce' ? 'minus-data' : 'add-data'
											} fw-bold`}
										>
											{direction === 'reduce' ? '-' : '+'}
											{amount.toLocaleString()}
										</span>
									</div>
									<div className="separator separator-dashed"></div>
									<div className="fs-6 d-flex justify-content-between mb-4 mt-4 flex-direction-row">
										<div className="fw-semibold">新可用资金</div>
										<span className="fw-bold">
											{direction === 'reduce'
												? (Number(available) - Number(amount)).toLocaleString()
												: (Number(available) + Number(amount)).toLocaleString()}
										</span>
									</div>
									<div className="separator separator-dashed"></div>
								</div>
							</div>
							{/* end::Scroll */}

							{/* begin::Actions */}
							<div className="text-center pt-10">
								<button
									type="reset"
									onClick={() => setSureModal(false)}
									className="btn btn-light me-3"
								>
									取消
								</button>

								<button
									// type="submit"
									className="btn btn-primary"
									// data-kt-users-modal-action="submit"
									onClick={submitManumal}
									disabled={isSubmitting}
									data-kt-indicator={isSubmitting ? 'on' : 'off'}
								>
									<span className="indicator-label">确定</span>
									{isSubmitting && (
										<span className="indicator-progress">
											等待中...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</button>
							</div>
							{/* end::Actions */}
							{/* </form> */}
						</div>
					</div>
				</div>
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};

export { SureFundsModifyModalForm };
