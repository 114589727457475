import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toAbsoluteUrl } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, ConfigProvider, theme /*, Switch*/ } from 'antd';
import NotificationItem from '../../../../app/components/NotificationItem/NotificationItem';
import { setNotificationUnreadCount } from '../../../../store/slices/global';
import {
	getNotifications,
	getNotificationSummary,
} from '../../../../utils/service';
import { find } from 'lodash';
// import { notificationListItemRenderer } from '../../../../utils/utils';

const pageSize = 8;

const HeaderNotificationsMenuDrawer = ({ drawerShownTs, drawerHiddenTs }) => {
	const dispatch = useDispatch();
	const themeMode = useSelector((state) => state.global.themeMode);
	const [userAuditNotifications, setUserAuditNotifications] = useState([]);
	const [financeAuditNotifications, setFinanceAuditNotifications] = useState(
		[]
	);
	const [systemNotifications, setSystemNotifications] = useState([]);
	const [unReadCount, setUnReadCount] = useState(0);
	const [userAuditUnreadCount, setUserAuditUnreadCount] = useState(0);
	const [financeAuditUnreadCount, setFinanceAuditUnreadCount] = useState(0);
	const [systemUnreadCount, setSystemUnreadCount] = useState(0);
	const [currentPageUser, setCurrentPageUser] = useState(1);
	const [currentPageFinance, setCurrentPageFinance] = useState(1);
	const [currentPageSystem, setCurrentPageSystem] = useState(1);
	const [totalDataUser, setTotalDataUser] = useState(0);
	const [totalDataFinance, setTotalDataFinance] = useState(0);
	const [totalDataSystem, setTotalDataSystem] = useState(0);
	const [activeTab, setActiveTab] = useState('user');
	const [notificationType, setNotificationType] = useState('');
	// const [unReadSwitchOn, setUnReadSwitchOn] = useState(false);

	const getUserAuditNotifications = async () => {
		const resp = await getNotifications(
			'USER_STATUS',
			{
				each_page: pageSize,
				current_page: currentPageUser,
			},
			notificationType.toUpperCase()
		);
		const { data, pagination } = resp;
		setUserAuditNotifications(data);
		setTotalDataUser(pagination.total_data);
	};

	const getFinanceAuditNotifications = async () => {
		const resp = await getNotifications(
			'BALANCE_STATUS',
			{
				each_page: pageSize,
				current_page: currentPageFinance,
			},
			notificationType.toUpperCase()
		);
		const { data, pagination } = resp;
		setFinanceAuditNotifications(data);
		setTotalDataFinance(pagination.total_data);
	};

	const getSystemNotifications = async () => {
		const resp = await getNotifications(
			'SYSTEM',
			{
				each_page: pageSize,
				current_page: currentPageSystem,
			},
			notificationType.toUpperCase()
		);
		const { data, pagination } = resp;
		setSystemNotifications(data);
		setTotalDataSystem(pagination.total_data);
	};

	const getSummary = async () => {
		const resp = await getNotificationSummary();
		const count = resp.reduce(
			(accumulator, item) => accumulator + item.unread_count,
			0
		);
		setUserAuditUnreadCount(
			find(resp, { notification_type: 'USER_STATUS' }).unread_count
		);
		setFinanceAuditUnreadCount(
			find(resp, { notification_type: 'BALANCE_STATUS' }).unread_count
		);
		setSystemUnreadCount(
			find(resp, { notification_type: 'SYSTEM' }).unread_count
		);
		setUnReadCount(count);
		dispatch(setNotificationUnreadCount(count));
	};

	useEffect(() => {
		if (drawerShownTs > 0) {
			getUserAuditNotifications();
		}
	}, [currentPageUser]);

	useEffect(() => {
		if (drawerShownTs > 0) {
			getFinanceAuditNotifications();
		}
	}, [currentPageFinance]);

	useEffect(() => {
		if (drawerShownTs > 0) {
			getSystemNotifications();
		}
	}, [currentPageSystem]);

	useEffect(() => {
		if (drawerShownTs > 0) {
			getUserAuditNotifications();
			// getFinanceAuditNotifications();
			// getSystemNotifications();
			getSummary();
		}
	}, [drawerShownTs]);

	useEffect(() => {
		if (drawerHiddenTs > 0) {
			// getSummary();
		}
	}, [drawerHiddenTs]);

	useEffect(() => {
		if (drawerShownTs > 0) {
			if (activeTab === 'user') {
				getUserAuditNotifications();
			} else if (activeTab === 'finance') {
				getFinanceAuditNotifications();
			} else if (activeTab === 'system') {
				getSystemNotifications();
			}
			getSummary();
		}
	}, [notificationType]);

	// useEffect(() => {
	// 	getUserAuditNotifications();
	// 	getFinanceAuditNotifications();
	// }, [unReadSwitchOn]);

	return (
		<div className="menu menu-column" data-kt-menu="true">
			<div
				className="d-flex flex-column bgi-no-repeat rounded-top"
				style={{
					backgroundImage: `url('${toAbsoluteUrl(
						'/media/misc/menu-header-bg.jpg'
					)}')`,
					backgroundSize: '100%',
				}}
			>
				<div className="position-relative">
					<h3 className="text-white fw-bold px-9 mt-10 mb-6">
						消息中心{' '}
						{/* <span className="fs-8 opacity-75 ps-3">未读数量 {unReadCount}</span> */}
						<a
							onClick={() => {
								if (notificationType === 'unread') {
									setNotificationType('');
								} else {
									setNotificationType('unread');
								}
							}}
							onMouseOver={(e) => {
								e.target.style.textDecoration = 'underline';
							}}
							onMouseOut={(e) => {
								e.target.style.textDecoration = 'none';
							}}
							style={{ cursor: 'pointer' }}
						>
							<span className="fs-8 opacity-75 ps-3">
								{notificationType === ''
									? `未读数量 ${unReadCount}`
									: '全部消息'}
							</span>
						</a>
					</h3>

					<ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
						<li className="nav-item">
							<a
								className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
								data-bs-toggle="tab"
								href="#kt_topbar_notifications_drawer_1"
								onClick={() => {
									setActiveTab('user');
									getUserAuditNotifications();
									getSummary();
								}}
							>
								用户审核({userAuditUnreadCount})
							</a>
						</li>

						<li className="nav-item">
							<a
								className="nav-link text-white opacity-75 opacity-state-100 pb-4"
								data-bs-toggle="tab"
								href="#kt_topbar_notifications_drawer_2"
								onClick={() => {
									setActiveTab('finance');
									getFinanceAuditNotifications();
									getSummary();
								}}
							>
								资金审核({financeAuditUnreadCount})
							</a>
						</li>

						<li className="nav-item">
							<a
								className="nav-link text-white opacity-75 opacity-state-100 pb-4"
								data-bs-toggle="tab"
								href="#kt_topbar_notifications_drawer_3"
								onClick={() => {
									setActiveTab('system');
									getSystemNotifications();
									getSummary();
								}}
							>
								系统消息({systemUnreadCount})
							</a>
						</li>
					</ul>

					{/* <span
						className="position-absolute"
						style={{ top: '80px', right: '10px', color: '#fff' }}
					>
						仅显示未读信息&nbsp;
						<Switch
							onChange={(checked) => {
								setUnReadSwitchOn(checked);
								if (checked) {

								} else {

								}
							}}
						/>
					</span> */}
				</div>
			</div>

			<div className="tab-content" style={{ flexGrow: 1 }}>
				<div
					className="tab-pane fade show active"
					id="kt_topbar_notifications_drawer_1"
					role="tabpanel"
				>
					<div className="my-5 px-8">
						<NotificationItem data={userAuditNotifications} />
						{/* {notificationListItemRenderer(userAuditNotifications)} */}
					</div>
				</div>

				<div
					className="tab-pane fade"
					id="kt_topbar_notifications_drawer_2"
					role="tabpanel"
				>
					<div className="scroll-y my-5 px-8">
						<NotificationItem data={financeAuditNotifications} />
						{/* {notificationListItemRenderer(financeAuditNotifications)} */}
					</div>
				</div>

				<div
					className="tab-pane fade"
					id="kt_topbar_notifications_drawer_3"
					role="tabpanel"
				>
					<div className="scroll-y my-5 px-8">
						<NotificationItem data={systemNotifications} />
						{/* {notificationListItemRenderer(financeAuditNotifications)} */}
					</div>
				</div>
			</div>

			<div
				className="d-flex justify-content-center"
				style={{ paddingBottom: '20px' }}
			>
				{((activeTab === 'user' && totalDataUser > 0) ||
					(activeTab === 'finance' && totalDataFinance > 0) ||
					(activeTab === 'system' && totalDataSystem > 0)) && (
					<ConfigProvider
						theme={{
							algorithm:
								themeMode === 'light'
									? theme.lightAlgorithm
									: theme.darkAlgorithm,
						}}
					>
						<Pagination
							size="small"
							pageSize={pageSize}
							hideOnSinglePage={true}
							showSizeChanger={false}
							current={
								activeTab === 'user'
									? currentPageUser
									: activeTab === 'finance'
									? currentPageFinance
									: currentPageSystem
							}
							total={
								activeTab === 'user'
									? totalDataUser
									: activeTab === 'finance'
									? totalDataFinance
									: totalDataSystem
							}
							onChange={(page) => {
								if (activeTab === 'user') {
									setCurrentPageUser(page);
								} else if (activeTab === 'finance') {
									setCurrentPageFinance(page);
								} else if (activeTab === 'system') {
									setCurrentPageSystem(page);
								}
							}}
						/>
					</ConfigProvider>
				)}
			</div>
		</div>
	);
};

HeaderNotificationsMenuDrawer.propTypes = {
	drawerShownTs: PropTypes.number,
	drawerHiddenTs: PropTypes.number,
};

export { HeaderNotificationsMenuDrawer };
