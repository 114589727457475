import axios from 'axios';
import ls from 'local-storage';
import store from '../store/store';
import { toast } from 'react-toastify';
// import {setToken, setRefreshToken}  from '../store/slices/global';
import eventBus from './eventBus';

if (
	location.hostname.indexOf('test-') > -1 ||
	location.hostname.indexOf('localhost') > -1
) {
	axios.defaults.baseURL = 'https://api.rx6888.com/api/exchange/admin/v1';
} else {
	axios.defaults.baseURL = 'https://api.ipotid.com/api/exchange/admin/v1';
}

axios.interceptors.request.use(
	function (config) {
		let {
			global: { token },
		} = store.getState();
		token = token || ls.get('X-Auth-Token');
		if (token) {
			config = {
				...config,
				headers: {
					...config.headers,
					Authorization: `Bearer ${token}`,
				},
			};
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function (response) {
		const { status, data } = response;
		if (status === 200) {
			return data;
		} else {
			return Promise.reject(status);
		}
		// return response;
	},
	async (error) => {
		const { status, data } = error.response;
		const { error_code } = data;
		// if (error_code === 'TOKEN__EXPIRE_TOKEN') {
		// let {
		// 	global: { token, refreshToken },
		// } = store.getState();
		// token = token || ls.get('X-Auth-Token');
		// refreshToken = refreshToken || ls.get('X-Auth-Refresh-Token');
		// const { Authorization, ...rest } = error.config.headers
		// const resp = await axios({
		// 	method: 'post',
		// 	url: '/iam/v1/auth/refresh',
		// 	headers: rest,
		// 	data: {
		// 		token,
		// 		refresh_token: refreshToken
		// 	},
		// });
		// store.dispatch(setToken(resp.token));
		// store.dispatch(setRefreshToken(resp.refresh_token));
		// ls.set('X-Auth-Token', resp.token);
		// ls.set('X-Auth-Refresh-Token', resp.refresh_token);
		// error.config.headers['Authorization'] = `Bearer ${resp.token}`;
		// error.config.baseURL = undefined;
		// await axios.request(error.config);
		// eventBus.dispatch('needToRefresh');
		// window.location.reload();
		// } else if (status === 401) {
		// (status === 401 || status === 404) &&
		// error_code !== 'USER__PASSWORD_DOES_NOT_MATCH' &&
		// error_code !== 'USER__MFA_VERIFICATION_FAIL' &&
		// error_code !== 'FUND__PASSWORD_DOES_NOT_MATCH' &&
		// error_code !== 'FUND__FUND_PASSWORD_NEEDED')  {
		// window.location.href = '/auth/login';
		// eventBus.dispatch('backToLogin');
		switch (error_code) {
			case 'USER__PASSWORD_DOES_NOT_MATCH':
				toast.error('用户密码错误！');
				break;
			case 'USER__NOT_FOUND':
				toast.error('用户尚未注册！');
				break;
			case 'USER__DUPLICATE_EMAIL_OR_PHONE':
				toast.error('手机号码已存在，请勿重复添加！');
				break;
			case 'USER__USER_BLOCKED':
				toast.error('您的帐号已被锁定，无法登录！');
				break;
			case 'USER__AUTHORIZATION_FAIL':
				toast.error('账号信息不存在！');
				break;
			case 'NEWS__HOT_LIMIT':
				toast.error('最多只能设置3个首页热门新闻！');
				break;
			case 'STOCKS__HOT_LIMIT':
				toast.error('最多只能设置5支热门股票！');
				break;
			case 'BANNER__SHOW_LIMIT':
				toast.error('最多只能展示4个banner！');
				break;
		}
		if (status === 401) {
			// window.location.href = '/auth';
			eventBus.dispatch('backToAuth');
			return Promise.reject(error);
		} else {
			return Promise.reject(error);
		}
		// if (error.response.status === 401 && error_code !== 'USER__PASSWORD_DOES_NOT_MATCH' && error_code !== 'USER__MFA_VERIFICATION_FAIL') {
		// window.location.href = '/auth/login';
		// } else {
		// return Promise.reject(error);
		// }
	}
);

export default axios;
