export function getRequestBaseUrl() {
	switch (location.hostname) {
		case 'localhost':
		case 'test-indonesia-admin.rx6888.com':
			return 'https://test-api.rx6888.com/api/exchange/admin/v1';
		default:
			return 'https://api.ipotid.com/api/exchange/admin/v1';
	}
}

export function getNewsRequestBaseUrl() {
	switch (location.hostname) {
		case 'localhost':
		case 'test-indonesia-admin.rx6888.com':
			return 'https://test-api.rx6888.com/api/exchange/admin/v1';
		default:
			return 'https://api.ipotid.com/api/exchange/admin/v1';
	}
}

export function getBaseUrl() {
	switch (location.hostname) {
		case 'localhost':
		case 'test-indonesia-admin.rx6888.com':
			return 'https://test-api.rx6888.com/api';
		default:
			return 'https://api.ipotid.com/api';
	}
}

export function getIndonesiaBaseUrl() {
	switch (location.hostname) {
		case 'localhost':
		case 'test-indonesia-admin.rx6888.com':
			return 'https://test-api.rx6888.com/api/indonesia-data';
		default:
			return 'https://api.ipotid.com/api/indonesia-data';
	}
}

export function getIndonesiaConfigBaseUrl() {
	switch (location.hostname) {
		case 'localhost':
		case 'test-indonesia-admin.rx6888.com':
			return 'https://test-api.rx6888.com/api/indonesia';
		default:
			return 'https://api.ipotid.com/api/indonesia';
	}
}

export function getIndonesiaGoldBaseUrl() {
	switch (location.hostname) {
		case 'localhost':
		case 'test-indonesia-admin.rx6888.com':
			return 'https://test-api.rx6888.com/api/indonesia-gold';
		default:
			return 'https://api.ipotid.com/api/indonesia-gold';
	}
}
