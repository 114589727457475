/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { KTSVG } from '../../../_metronic/helpers';
import request from '../../../utils/request';
import '../../../plugins/keenicons/duotone/style.css';
import { initLightbox } from '../../../utils/utils';

const UserDetailModal = (props) => {
	const { user, setShowModal, detailOpentModify, id } = props;
	const [userDetail, setUserDetail] = useState({ user_type: '' });
	const [summaryMsg, setSummaryMsg] = useState({});
	const [userDetailDataLoaded, setUserDetailDataLoaded] = useState(false);
	const photoData = [
		{ label: '身份证人像面', key: 'front_photo' },
		{ label: '身份证国徽面', key: 'opposite_photo' },
		{ label: '手持身份证人像照', key: 'hold_photo' },
	];
	const getUserInfo = async () => {
		const resp = await request.get(`/users/${id || user.user_id}`);
		setUserDetail(resp);
		setUserDetailDataLoaded(true);
		setTimeout(() => {
			initLightbox('#info_container img');
		}, 300);
	};
	const getAccountSummary = async () => {
		const resp = await request.get(`/account/${id || user.user_id}/summary`);
		setSummaryMsg(resp);
	};
	const updateUser = () => {
		setShowModal(false);
		detailOpentModify(true);
	};
	useEffect(() => {
		getUserInfo();
		getAccountSummary();
	}, []);
	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_add_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-1000px">
					{/* begin::Modal content */}
					<div className="modal-content">
						<div className="modal-header">
							{/* begin::Modal title */}
							<h2 className="fw-bolder">
								{userDetail.user_type == 'TRADER' ? '用户详情' : '代理详情'}
							</h2>
							{/* end::Modal title */}

							{/* begin::Close */}
							<div
								className="btn btn-icon btn-sm btn-active-icon-primary"
								data-kt-users-modal-action="close"
								onClick={() => {
									setShowModal(false);
								}}
								style={{ cursor: 'pointer' }}
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon-1"
								/>
							</div>
							{/* end::Close */}
						</div>
						{/* begin::Modal body */}
						<div className="modal-body scroll-y mx-1 mx-xl-1 my-3">
							<div className="row g-5 g-xl-5 mb-xl-5">
								<div className="col-md-12 col-lg-8 col-xl-8 col-xxl-8 shadow-sm">
									<div className="card shadow-sm badge-light p-6">
										<h5>
											{userDetail.user_type?.indexOf('ADMINISTRATOR') > -1
												? userDetail?.username
												: userDetail?.full_name}
										</h5>
										{userDetailDataLoaded && (
											<div className="flex" style={{ lineHeight: '17px' }}>
												<span className="flex">
													<KTSVG path="/media/icons/duotune/electronics/elc002.svg" />
													<span className="ms-1">{userDetail.phone}</span>
												</span>
												<span className="flex ms-2">
													<KTSVG path="/media/icons/duotune/communication/com006.svg" />
													<span className="ms-1">
														{userDetail.user_type === 'TRADER'
															? '普通用户'
															: userDetail.user_type === 'AGENT'
															? '代理'
															: '管理员'}
													</span>
												</span>
												{userDetail.user_type.indexOf('ADMINISTRATOR') ==
													-1 && (
													<span className="flex ms-2">
														<KTSVG path="/media/icons/duotune/communication/com014.svg" />
														<span className="ms-1">
															{`${userDetail.parent_username}(${
																userDetail.parent_phone || '无'
															})`}
														</span>
													</span>
												)}
												<span className="flex ms-2">
													<KTSVG path="/media/icons/duotune/general/gen047.svg" />
													<span
														className="ms-1"
														style={{
															color: `${
																userDetail.is_blocked ? '#ff4d4f' : '#50cd89'
															}`,
														}}
													>
														{userDetail.is_blocked ? '锁定' : '正常'}
													</span>
												</span>
												{userDetail.user_type === 'AGENT' && (
													<span className="flex ms-2">
														<KTSVG path="/media/icons/duotune/coding/cod008.svg" />
														<span className="ms-1">
															{userDetail.referral_code}
														</span>
													</span>
												)}
												{userDetail.user_type === 'TRADER' && (
													<>
														{userDetail.is_verified && (
															<Tag
																bordered={false}
																className="bg-light-primary text-primary me-1"
															>
																实名认证
															</Tag>
														)}
														{userDetail.auth_status === 'APPROVED' && (
															<Tag
																bordered={false}
																className="bg-light-primary text-primary me-1"
															>
																高级认证
															</Tag>
														)}
														{userDetail.is_deposits && (
															<Tag
																bordered={false}
																className="bg-light-primary text-primary me-0"
															>
																已入金
															</Tag>
														)}
													</>
												)}
												<span className="flex ms-2">
													<KTSVG path="/media/icons/duotune/art/art010.svg" />
													<span className="ms-1">
														用户等级：{userDetail.vip_level}
													</span>
												</span>
											</div>
										)}
									</div>
									<div className="card shadow-sm badge-light p-6 mt-8">
										<div
											className="card-header"
											style={{ minHeight: '30px', padding: 0 }}
										>
											<h3 className="card-title" style={{ fontSize: '16px' }}>
												登录信息
											</h3>
										</div>
										<div className="fs-6 d-flex justify-content-between">
											<div>
												<div className="fw-bold mt-5">最后登录IP</div>
												<div className="text-gray-600">
													{userDetail.last_login_ip}(
													{userDetail.last_login_region || '无'})
												</div>
											</div>
											<div style={{ width: '50%' }}>
												<div className="fw-bold mt-5">最后登录时间</div>
												<div className="text-gray-600">
													{userDetail.last_login_time}
												</div>
											</div>
										</div>
									</div>
									<div className="card shadow-sm badge-light p-6 mt-8">
										<div
											className="card-header"
											style={{ minHeight: '30px', padding: 0 }}
										>
											<h3 className="card-title" style={{ fontSize: '16px' }}>
												认证信息
											</h3>
										</div>
										<div className="fs-6 d-flex justify-content-between">
											<div>
												<div className="fw-bold mt-5">真实姓名</div>
												<div className="text-gray-600">
													{userDetail.full_name}
												</div>
											</div>
											<div style={{ width: '50%' }}>
												<div className="fw-bold mt-5">身份证号码</div>
												<div className="text-gray-600">{userDetail.id_no}</div>
											</div>
										</div>
									</div>
									{userDetail.user_type == 'TRADER' && (
										<>
											<div className="card shadow-sm badge-light p-6 mt-8">
												<div
													className="card-header"
													style={{ minHeight: '30px', padding: 0 }}
												>
													<h3
														className="card-title"
														style={{ fontSize: '16px' }}
													>
														注册信息
													</h3>
												</div>
												<div className="fs-6 d-flex justify-content-between">
													<div>
														<div className="fw-bold mt-5">注册IP</div>
														<div className="text-gray-600">
															{userDetail.auth_ip}(
															{userDetail.auth_region || '无'})
														</div>
													</div>
													<div style={{ width: '50%' }}>
														<div className="fw-bold mt-5">注册时间</div>
														<div className="text-gray-600">
															{userDetail.auth_time}
														</div>
													</div>
												</div>
											</div>
											<div className="card shadow-sm badge-light p-6 mt-8">
												<div
													className="card-header"
													style={{ minHeight: '30px', padding: 0 }}
												>
													<h3
														className="card-title"
														style={{ fontSize: '16px' }}
													>
														证件照信息
													</h3>
												</div>
												<div
													className="fs-6 d-flex justify-content-between pb-6"
													id="info_container"
												>
													<div className="row">
														{photoData.map((item, index) => {
															return (
																<div
																	key={index}
																	className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
																>
																	<div className="fw-bold my-3">
																		{item.label}
																	</div>
																	{userDetail[item.key] ? (
																		<img
																			src={userDetail[item.key]}
																			style={{
																				width: 120,
																				height: 80,
																				cursor: 'zoom-in',
																			}}
																		/>
																	) : (
																		<div
																			style={{
																				width: 120,
																				height: 80,
																				lineHeight: '80px',
																				textAlign: 'center',
																				background: '#e4e4e4',
																				borderRadius: '6px',
																			}}
																		>
																			暂无照片
																		</div>
																	)}
																</div>
															);
														})}
													</div>
												</div>
											</div>
										</>
									)}
								</div>
								<div className="col-md-12 col-lg-4 col-xl-4 col-xxl-4 card badge-light shadow-sm">
									<div className="card-header">
										<h3 className="card-title">资产信息</h3>
									</div>
									{userDetail.user_type === 'TRADER' && (
										<div className="d-flex">
											<div className="card-px pt-5 pb-10 flex-grow-1">
												<div className="col">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-info">
																<i className="ki-duotone ki-bill fs-1 text-info">
																	<i className="path1"></i>
																	<i className="path2"></i>
																	<i className="path3"></i>
																	<i className="path4"></i>
																	<i className="path5"></i>
																	<i className="path6"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.holding_value?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																持仓成本
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-success">
																<i className="ki-duotone ki-basket fs-1 text-info">
																	<i className="path1"></i>
																	<i className="path2"></i>
																	<i className="path3"></i>
																	<i className="path4"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.available?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																可用资金
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-primary">
																<i className="ki-duotone ki-lock fs-1 text-info">
																	<i className="path1"></i>
																	<i className="path2"></i>
																	<i className="path3"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.margin?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																冻结保证金
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-danger">
																<i className="ki-duotone ki-chart-line-up-2 fs-1 text-danger">
																	<i className="path1"></i>
																	<i className="path2"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.float_earnings?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																浮动盈亏
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-danger">
																<i className="ki-duotone ki-chart-line-up-2 fs-1 text-danger">
																	<i className="path1"></i>
																	<i className="path2"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.total_earnings?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																总盈亏
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-primary">
																<KTSVG
																	path="/media/icons/duotune/finance/fin008.svg"
																	className={`svg-icon-1 svg-icon-danger`}
																/>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.total_deposits?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																存款金额
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-info">
																<KTSVG
																	path="/media/icons/duotune/finance/fin008.svg"
																	className={`svg-icon-1 svg-icon-danger`}
																/>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.total_withdrawals?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																取款金额
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-primary">
																<KTSVG
																	path="/media/icons/duotune/finance/fin008.svg"
																	className={`svg-icon-1 svg-icon-danger`}
																/>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.manual_increase?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																人工转入
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-info">
																<KTSVG
																	path="/media/icons/duotune/finance/fin008.svg"
																	className={`svg-icon-1 svg-icon-danger`}
																/>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.manual_decrease?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																人工转出
															</div>
														</div>
													</div>
												</div>
												<div className="col my-6">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-warning">
																<i className="ki-duotone ki-chart-simple fs-1 text-danger">
																	<i className="path1"></i>
																	<i className="path2"></i>
																	<i className="path3"></i>
																	<i className="path4"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.total_assets?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																总资金
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* <div className="col-xl-3 me-5 mt-3">
							<div
								className="card hoverable card-xl-stretch mb-xl-8"
								style={{ background: '#0095e8' }}
							>
								<div className="card-body">
									<i className="ki-duotone ki-briefcase text-white fs-2x ms-n1">
										<span className="path1"></span>
										<span className="path2"></span>
									</i>
									<div className="text-white fw-bold fs-2 mb-2 mt-5 mt-5">
										￥{summaryMsg.total_assets?.toLocaleString()}
									</div>
									<div className="fw-semibold text-white">总资金</div>
								</div>
							</div>
						</div> */}
										</div>
									)}
									{userDetail.user_type === 'AGENT' && (
										<div className="d-flex">
											<div className="card-px pt-5 pb-10 flex-grow-1">
												<div className="col">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-success">
																<i className="ki-duotone ki-basket fs-1 text-info">
																	<i className="path1"></i>
																	<i className="path2"></i>
																	<i className="path3"></i>
																	<i className="path4"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.stock_market_value?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																用户数量
															</div>
														</div>
													</div>
												</div>
												<div className="col mt-3">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-primary">
																<i className="ki-duotone ki-lock fs-1 text-info">
																	<i className="path1"></i>
																	<i className="path2"></i>
																	<i className="path3"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.available?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																下级代理数量
															</div>
														</div>
													</div>
												</div>
												<div className="col mt-3">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-success">
																<i className="ki-duotone ki-chart-simple fs-1 text-danger">
																	<i className="path1"></i>
																	<i className="path2"></i>
																	<i className="path3"></i>
																	<i className="path4"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.frozen?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																用户持仓订单金额
															</div>
														</div>
													</div>
												</div>
												<div className="col mt-3">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-warning">
																<i className="ki-duotone ki-chart-simple fs-1 text-danger">
																	<i className="path1"></i>
																	<i className="path2"></i>
																	<i className="path3"></i>
																	<i className="path4"></i>
																</i>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.total_earnings?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																用户平仓订单金额
															</div>
														</div>
													</div>
												</div>
												<div className="col mt-3">
													<div className="d-flex align-items-center me-2">
														<div className="symbol symbol-50px me-3">
															<div className="symbol-label bg-light-primary">
																<KTSVG
																	path="/media/icons/duotune/finance/fin008.svg"
																	className={`svg-icon-1 svg-icon-danger`}
																/>
															</div>
														</div>
														<div>
															<div className="fs-4 text-dark fw-bold">
																{summaryMsg.total_earnings?.toLocaleString()}
															</div>
															<div className="fs-7 text-muted fw-bold">
																用户总资金
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							{userDetail.user_type == 'TRADER' && (
								<div className="text-center mt-10">
									<button
										type="submit"
										className="btn btn-primary"
										data-kt-users-modal-action="submit"
										onClick={updateUser}
									>
										<span className="indicator-label">
											{userDetail.user_type == 'TRADER'
												? '修改用户'
												: '修改代理'}
										</span>
									</button>
								</div>
							)}
						</div>
						{/* end::Modal body */}
					</div>
					{/* end::Modal content */}
				</div>
				{/* end::Modal dialog */}
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};
UserDetailModal.propTypes = {
	detailOpentModify: PropTypes.func,
	setShowModal: PropTypes.func,
	user: PropTypes.object,
	id: PropTypes.number,
};
export { UserDetailModal };
