import React, { useState, useEffect } from 'react';
import { DrawerComponent } from '../../../assets/ts/components';
import { HeaderNotificationsMenuDrawer } from '../header-menus/HeaderNotificationsMenuDrawer';

const DrawerNotification = () => {
	const [drawerShownTs, setDrawerShownTs] = useState(0);
	const [drawerHiddenTs, setDrawerHiddenTs] = useState(0);
	useEffect(() => {
		setTimeout(() => {
			const drawer = DrawerComponent.getInstance('kt_drawer_notification');
			if (drawer) {
				drawer.on('kt.drawer.toggled', () => {
					setDrawerShownTs(Date.now());
				});
				drawer.on('kt.drawer.hide', () => {
					setDrawerHiddenTs(Date.now());
				});
			}
		}, 500);
	}, []);

	return (
		<div
			id="kt_drawer_notification"
			className="bg-body"
			data-kt-drawer="true"
			data-kt-drawer-name="chat"
			data-kt-drawer-activate="true"
			data-kt-drawer-overlay="true"
			data-kt-drawer-width="{default:'300px', 'md': '500px'}"
			data-kt-drawer-direction="end"
			data-kt-drawer-toggle="#kt_drawer_notification_toggle"
			data-kt-drawer-close="#kt_drawer_notification_close"
			style={{ borderRadius: '6px' }}
		>
			<HeaderNotificationsMenuDrawer
				drawerShownTs={drawerShownTs}
				drawerHiddenTs={drawerHiddenTs}
			/>
		</div>
	);
};

export { DrawerNotification };
