/* eslint-disable react/react-in-jsx-scope */
import { useQuery } from 'react-query';
import { UserModifyModalForm } from './UserModifyModalForm';
import { BankCardModifyModalForm } from './BankCardModifyModalForm';
import { FundsModifyModalForm } from './FundsModifyModalForm';
import { isNotEmpty, QUERIES } from '../../../../_metronic/helpers';
import { useListView } from '../../../modules/apps/user-management/users-list/core/ListViewProvider';
import { getUserById } from '../../../modules/apps/user-management/users-list/core/_requests';

const UserModifyModalFormWrapper = (props) => {
	const { type, user, setShowModal, action } = props;
	const { itemIdForUpdate, setItemIdForUpdate } = useListView();
	const enabledQuery = isNotEmpty(itemIdForUpdate);
	const {
		isLoading,
		// data: user,
		error,
	} = useQuery(
		`${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
		() => {
			return getUserById(itemIdForUpdate);
		},
		{
			cacheTime: 0,
			enabled: enabledQuery,
			onError: (err) => {
				setItemIdForUpdate(undefined);
				console.error(err);
			},
		}
	);

	if (!itemIdForUpdate) {
		if (type === 'bank')
			return (
				<BankCardModifyModalForm
					isUserLoading={isLoading}
					user={user}
					setShowModal={setShowModal}
					action={action}
				/>
			);
		if (
			type?.indexOf('ADMINISTRATOR') > -1 ||
			type === 'AGENT' ||
			type === 'TRADER'
		)
			return (
				<UserModifyModalForm
					isUserLoading={isLoading}
					user={user}
					setShowModal={setShowModal}
					action={action}
				/>
			);
		if (type === 'funds')
			return (
				<FundsModifyModalForm
					isUserLoading={isLoading}
					user={user}
					setShowModal={setShowModal}
					action={action}
				/>
			);
	}

	if (!isLoading && !error && user) {
		return (
			<UserModifyModalForm
				isUserLoading={isLoading}
				user={user}
				setShowModal={setShowModal}
				action={action}
			/>
		);
	}

	return null;
};

export { UserModifyModalFormWrapper };
