import React, { useEffect, useRef } from 'react';
import { Tab } from 'bootstrap';
import {
	MenuComponent,
	DrawerComponent,
	ScrollComponent,
	ScrollTopComponent,
	StickyComponent,
	ToggleComponent,
	SwapperComponent,
} from '../assets/ts/components';
import { ThemeModeComponent } from '../assets/ts/layout';
import { useLayout } from './core';
import eventBus from '../../utils/eventBus';

let firstRun = true;

export function MasterInit() {
	const { config } = useLayout();
	const isFirstRun = useRef(true);
	const bsInit = () => {
		ToggleComponent.bootstrap();
		ScrollTopComponent.bootstrap();
		DrawerComponent.bootstrap();
		StickyComponent.bootstrap();
		MenuComponent.bootstrap();
		ScrollComponent.bootstrap();
		SwapperComponent.bootstrap();
		document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
			Tab.getOrCreateInstance(tab);
		});
	};
	const pluginsInitialization = () => {
		isFirstRun.current = false;
		ThemeModeComponent.init();
		bsInit();
	};

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			pluginsInitialization();
		}
	}, [config]);

	useEffect(() => {
		const init = () => {
			if (firstRun) {
				setTimeout(() => {
					bsInit();
				}, 500);
				firstRun = false;
			}
		};
		eventBus.on('bootstrapInit', init);
		return () => {
			eventBus.remove('bootstrapInit', init);
		};
	}, []);

	return <></>;
}
