import React, { useEffect } from 'react';
import clsx from 'clsx';
import { QRCode } from 'antd';
import { useSelector } from 'react-redux';
import { KTSVG /*, toAbsoluteUrl*/ } from '../../../helpers';
import {
	HeaderUserMenu,
	// Search,
	ThemeModeSwitcher,
	HeaderNotificationsMenu,
} from '../../../partials';
// import { useLayout } from '../../core';

const itemClass = 'ms-1 ms-lg-3';
// const btnClass =
// 	'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
const userAvatarClass = 'symbol-35px symbol-md-40px';
// const btnIconClass = 'svg-icon-1';

const { host, protocol } = window.location;

const Navbar = () => {
	// const { config } = useLayout();
	const appPreviewOnly = useSelector((state) => state.global.appPreviewOnly);
	const notificationUnreadCount = useSelector(
		(state) => state.global.notificationUnreadCount
	);

	// let notificationInterval;
	// useEffect(() => {
	// 	if (notificationUnreadCount > 0 && !notificationInterval) {
	// 		notificationInterval = setInterval(() => {
	// 			speak('您有新的消息了');
	// 		}, 60 * 1000);
	// 	} else if (notificationUnreadCount <= 0 && notificationInterval) {
	// 		clearInterval(notificationInterval);
	// 		notificationInterval = null;
	// 	}
	// }, [notificationUnreadCount]);

	return (
		<div className="app-navbar flex-shrink-0">
			{/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
				<Search />
			</div> */}

			{/* <div className={clsx('app-navbar-item', itemClass)}>
				<div
					className={clsx('position-relative', btnClass)}
					id="kt_drawer_chat_toggle"
				>
					<KTSVG
						path="/media/icons/duotune/communication/com012.svg"
						className={btnIconClass}
					/>
					<span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
				</div>
			</div> */}

			<div className={clsx('app-navbar-item', itemClass)}>
				<a
					href="https://app.meiqia.com/signin"
					target="_blank"
					rel="noreferrer"
				>
					<KTSVG
						path="/media/icons/duotune/communication/com003.svg"
						className={clsx(
							'svg-icon-1',
							'btn btn-icon ',
							'btn-active-light-primary btn-custom'
						)}
					/>
				</a>
			</div>

			<div className={clsx('app-navbar-item', itemClass)}>
				<>
					{/* begin::Menu toggle */}
					<a
						href="#"
						className={clsx(
							'btn btn-icon ',
							'btn-active-light-primary btn-custom'
						)}
						data-kt-menu-trigger="hover"
						data-kt-menu-attach="parent"
						data-kt-menu-placement="bottom-end"
					>
						<KTSVG
							path="/media/icons/duotune/electronics/elc002.svg"
							className="svg-icon-1"
						/>
					</a>
					{/* begin::Menu toggle */}
					<div
						className="menu menu-sub menu-sub-dropdown menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-270px"
						data-kt-menu="true"
					>
						<div className="menu-item px-3 my-0 d-flex align-items-center justify-content-center">
							<QRCode
								value={`${protocol}//h5.rx6888.com/download/index.html`}
								bordered={false}
								size={140}
								bgColor="#fff"
							/>
							<p style={{ marginLeft: '10px' }}>
								复制保存二维码
								<br />
								扫码可下载App
							</p>
						</div>
					</div>
				</>
			</div>

			<div className={clsx('app-navbar-item', itemClass)}>
				<div
					data-kt-menu-trigger="{default: 'click'}"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
					className={clsx(
						'position-relative',
						'btn btn-icon ',
						'btn-active-light-primary btn-custom'
					)}
				>
					{/* <KTSVG
						path="/media/icons/duotune/general/gen022.svg"
						className="svg-icon-1"
					/> */}
					<span className="svg-icon fs-1">
						<i className="ki-duotone ki-message-text">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
						</i>
					</span>
					{notificationUnreadCount > 0 && (
						<span
							className="bullet bullet-dot bg-danger h-9px w-9px position-absolute translate-middle start-50 animation-blink"
							style={{ top: '4px' }}
						/>
					)}
				</div>
				{!appPreviewOnly && <HeaderNotificationsMenu />}
			</div>

			<div className={clsx('app-navbar-item', itemClass)}>
				<ThemeModeSwitcher
					toggleBtnClass={clsx('btn-active-light-primary btn-custom')}
				/>
			</div>

			<div className={clsx('app-navbar-item', itemClass)}>
				<div
					className={clsx('cursor-pointer symbol', userAvatarClass)}
					// data-kt-menu-trigger="{default: 'click'}"
					data-kt-menu-trigger="hover"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
				>
					{/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt="" /> */}
					<img
						src="https://api.dicebear.com/6.x/identicon/svg?rowColor=1e88e5&backgroundColor=ffdfbf"
						alt="avatar"
						style={{
							width: '30px',
							height: '30px',
						}}
					/>
				</div>
				<HeaderUserMenu />
			</div>

			{/* {config.app?.header?.default?.menu?.display && (
				<div
					className="app-navbar-item d-lg-none ms-2 me-n3"
					title="Show header menu"
				>
					<div
						className="btn btn-icon btn-active-color-primary w-35px h-35px"
						id="kt_app_header_menu_toggle"
					>
						<KTSVG
							path="/media/icons/duotune/text/txt001.svg"
							className={btnIconClass}
						/>
					</div>
				</div>
			)} */}
		</div>
	);
};

export { Navbar };
