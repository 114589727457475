import React, { useState, useEffect } from 'react';
import { toAbsoluteUrl, KTSVG } from '../../../helpers';
import { useDispatch } from 'react-redux';
import { MenuComponent } from '../../../assets/ts/components';
import NotificationItem from '../../../../app/components/NotificationItem/NotificationItem';
import { setNotificationUnreadCount } from '../../../../store/slices/global';
import {
	getNotifications,
	getNotificationSummary,
} from '../../../../utils/service';
import { find } from 'lodash';
// import { notificationListItemRenderer } from '../../../../utils/utils';

const paginationConfig = {
	each_page: 5,
	current_page: 1,
};

const HeaderNotificationsMenu = () => {
	const dispatch = useDispatch();
	const [userAuditNotifications, setUserAuditNotifications] = useState([]);
	const [financeAuditNotifications, setFinanceAuditNotifications] = useState(
		[]
	);
	const [systemNotifications, setSystemNotifications] = useState([]);
	const [unReadCount, setUnReadCount] = useState(0);
	const [userAuditUnreadCount, setUserAuditUnreadCount] = useState(0);
	const [financeAuditUnreadCount, setFinanceAuditUnreadCount] = useState(0);
	const [systemUnreadCount, setSystemUnreadCount] = useState(0);

	const getUserAuditNotifications = async () => {
		const resp = await getNotifications(
			'USER_STATUS',
			paginationConfig,
			'UNREAD'
		);
		setUserAuditNotifications(resp.data);
	};

	const getFinanceAuditNotifications = async () => {
		const resp = await getNotifications(
			'BALANCE_STATUS',
			paginationConfig,
			'UNREAD'
		);
		setFinanceAuditNotifications(resp.data);
	};

	const getSystemNotifications = async () => {
		const resp = await getNotifications('SYSTEM', paginationConfig, 'UNREAD');
		setSystemNotifications(resp.data);
	};

	const getSummary = async () => {
		const resp = await getNotificationSummary();
		const count = resp.reduce(
			(accumulator, item) => accumulator + item.unread_count,
			0
		);
		setUserAuditUnreadCount(
			find(resp, { notification_type: 'USER_STATUS' }).unread_count
		);
		setFinanceAuditUnreadCount(
			find(resp, { notification_type: 'BALANCE_STATUS' }).unread_count
		);
		setSystemUnreadCount(
			find(resp, { notification_type: 'SYSTEM' }).unread_count
		);
		setUnReadCount(count);
		dispatch(setNotificationUnreadCount(count));
	};

	const getNotificationsData = async () => {
		const resp = await getNotificationSummary();
		const userAuditUC = find(resp, {
			notification_type: 'USER_STATUS',
		}).unread_count;
		const financeAuditUC = find(resp, {
			notification_type: 'BALANCE_STATUS',
		}).unread_count;
		const systemUC = find(resp, {
			notification_type: 'SYSTEM',
		}).unread_count;

		const tabItems = document.querySelectorAll(
			'#header_notifications_menu .nav-link'
		);
		if (userAuditUC <= 0) {
			if (financeAuditUC > 0) {
				tabItems[1].click();
			} else if (systemUC > 0) {
				tabItems[2].click();
			}
		}
	};

	useEffect(() => {
		getSummary();
		setTimeout(() => {
			const menuEl = document.getElementById('header_notifications_menu');
			const menu = MenuComponent.getInstance(menuEl);
			if (menu) {
				menu.on('kt.menu.dropdown.show', () => {
					getNotificationsData();
				});
				menu.on('kt.menu.dropdown.hide', () => {
					getSummary();
				});
			}
		}, 2000);
	}, []);

	return (
		<div
			id="header_notifications_menu"
			className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
			data-kt-menu="true"
		>
			<div
				className="d-flex flex-column bgi-no-repeat rounded-top"
				style={{
					backgroundImage: `url('${toAbsoluteUrl(
						'/media/misc/menu-header-bg.jpg'
					)}')`,
				}}
			>
				<h3 className="text-white fw-bold px-9 mt-10 mb-6">
					消息列表{' '}
					<span className="fs-8 opacity-75 ps-3">未读数量 {unReadCount}</span>
				</h3>

				<ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
					<li className="nav-item">
						<a
							className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
							data-bs-toggle="tab"
							href="#kt_topbar_notifications_1"
							onClick={() => {
								getUserAuditNotifications();
								getSummary();
							}}
						>
							用户审核({userAuditUnreadCount})
						</a>
					</li>

					<li className="nav-item">
						<a
							className="nav-link text-white opacity-75 opacity-state-100 pb-4"
							data-bs-toggle="tab"
							href="#kt_topbar_notifications_2"
							onClick={() => {
								getFinanceAuditNotifications();
								getSummary();
							}}
						>
							资金审核({financeAuditUnreadCount})
						</a>
					</li>

					<li className="nav-item">
						<a
							className="nav-link text-white opacity-75 opacity-state-100 pb-4"
							data-bs-toggle="tab"
							href="#kt_topbar_notifications_3"
							onClick={() => {
								getSystemNotifications();
								getSummary();
							}}
						>
							系统消息({systemUnreadCount})
						</a>
					</li>
				</ul>
			</div>

			<div className="tab-content">
				<div
					className="tab-pane fade show active"
					id="kt_topbar_notifications_1"
					role="tabpanel"
				>
					<div className="my-5 px-8">
						<NotificationItem data={userAuditNotifications} />
						{/* {notificationListItemRenderer(userAuditNotifications)} */}
					</div>
				</div>

				<div
					className="tab-pane fade"
					id="kt_topbar_notifications_2"
					role="tabpanel"
				>
					<div className="my-5 px-8">
						<NotificationItem data={financeAuditNotifications} />
						{/* {notificationListItemRenderer(financeAuditNotifications)} */}
					</div>
				</div>

				<div
					className="tab-pane fade"
					id="kt_topbar_notifications_3"
					role="tabpanel"
				>
					<div className="my-5 px-8">
						<NotificationItem data={systemNotifications} />
						{/* {notificationListItemRenderer(financeAuditNotifications)} */}
					</div>
				</div>
			</div>
			<div
				className="py-3 text-center border-top"
				id="kt_drawer_notification_toggle"
				style={{ cursor: 'pointer' }}
			>
				查看全部{' '}
				<KTSVG
					path="/media/icons/duotune/arrows/arr064.svg"
					className="svg-icon-5"
				/>
			</div>
		</div>
	);
};

export { HeaderNotificationsMenu };
