import React, { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';

type Props = {
	to: string;
	title: string;
	badge: number;
	icon?: string;
	fontIcon?: string;
	hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
	children,
	to,
	title,
	badge = '',
	icon,
	fontIcon,
	hasBullet = false,
}) => {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, to);
	const { config } = useLayout();
	const { app } = config;

	return (
		<div className="menu-item" style={{ position: 'relative' }}>
			<Link
				className={clsx('menu-link without-sub', { active: isActive })}
				to={to}
			>
				{isActive && <span className="menu-active-highlight" />}
				{hasBullet && !isActive && (
					<span className="menu-bullet">
						<span className="bullet bullet-dot"></span>
					</span>
				)}
				{icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
					<span className="menu-icon">
						{' '}
						<KTSVG path={icon} className="svg-icon-2" />
					</span>
				)}
				{fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
					<i className={clsx('bi fs-3', fontIcon)}></i>
				)}
				<span className="menu-title">{title}</span>
				{badge !== '' && (
					<span className="menu-badge">
						<span className="badge badge-success">{badge}</span>
					</span>
				)}
			</Link>
			{children}
		</div>
	);
};

export { SidebarMenuItem };
