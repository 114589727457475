import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import eventBus from '../../../utils/eventBus';
import 'react-toastify/dist/ReactToastify.css';

const rwdWidth = 990;

const AuthLayout = () => {
	const navigate = useNavigate();
	const themeMode = useSelector((state) => state.global.themeMode);
	const [viewportWidth, setViewportWidth] = useState(10000);
	const getViewportWidthByResize = () => {
		window.addEventListener('resize', (e) => {
			setViewportWidth(e.target.innerWidth);
		});
	};

	useEffect(() => {
		getViewportWidthByResize();
	}, []);

	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.style.height = '100%';
		}
		return () => {
			if (root) {
				root.style.height = 'auto';
			}
		};
	}, []);

	useEffect(() => {
		const needToRefresh = () => {
			navigate(0);
		};
		eventBus.on('needToRefresh', needToRefresh);
		return () => {
			eventBus.remove('needToRefresh', needToRefresh);
		};
	}, []);

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={1500}
				hideProgressBar={false}
				newestOnTop={false}
				theme={themeMode}
			/>
			<div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
				{/* begin::Body */}
				<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
					{/* begin::Form */}
					<div className="d-flex flex-center flex-column flex-lg-row-fluid">
						{/* begin::Wrapper */}
						<div className="w-lg-500px p-10" style={{ width: '100%' }}>
							<Outlet />
						</div>
						{/* end::Wrapper */}
					</div>
					{/* end::Form */}

					{/* begin::Footer */}
					<div className="d-flex flex-center flex-wrap px-5">
						{/* begin::Links */}
						{/* <div className='d-flex fw-semibold text-primary fs-base'>
							<a href='#' className='px-5' target='_blank'>
								Terms
							</a>

							<a href='#' className='px-5' target='_blank'>
								Plans
							</a>

							<a href='#' className='px-5' target='_blank'>
								Contact Us
							</a>
						</div> */}
						{/* end::Links */}
					</div>
					{/* end::Footer */}
				</div>
				{/* end::Body */}

				{/* begin::Aside */}
				<div
					className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
					style={{
						backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`,
					}}
				>
					{/* begin::Content */}
					<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
						{/* begin::Logo */}
						<Link
							to="/"
							className={`${viewportWidth > rwdWidth ? 'mb-12' : ''}`}
						>
							<img
								alt="Logo"
								src={toAbsoluteUrl('/media/logos/logo-new.jpg')}
								style={{ width: '64px', height: '64px' }}
							/>
						</Link>
						{/* end::Logo */}

						{/* begin::Image */}
						{viewportWidth > rwdWidth && (
							<img
								className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
								src={toAbsoluteUrl('/media/misc/auth-screens.png')}
								alt=""
							/>
						)}
						{/* end::Image */}

						{/* begin::Title */}
						{viewportWidth > rwdWidth && (
							<h1 className="text-white fs-2qx fw-bolder text-center mb-7">
								欢迎登录
							</h1>
						)}
						{/* end::Title */}

						{/* begin::Text */}
						{viewportWidth > rwdWidth && (
							<div className="text-white fs-base text-center">
								{/* In this kind of post,{' '}
								<a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
									the blogger
								</a>
								introduces a person they’ve interviewed <br /> and provides some background information
								about
								<a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
									the interviewee
								</a>
								and their <br /> work following this is a transcript of the interview. */}
								您可以在后台管理系统，新增不同角色的用户、查看用户订单信息，
								<br />
								以及配置App相关设置
							</div>
						)}
						{/* end::Text */}
					</div>
					{/* end::Content */}
				</div>
				{/* end::Aside */}
			</div>
		</>
	);
};

export { AuthLayout };
