/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { BeforeCancel } from './BeforeCancel';

const UserModifyModalHeader = (props: { setShowModal: any; type: string }) => {
	const { setShowModal, type } = props;
	const [title, setTitle] = useState('修改用户');
	const [isCancel, setIsCancel] = useState(false);

	useEffect(() => {
		switch (type) {
			case 'TRADER':
				setTitle('修改用户');
				break;
			case 'AGENT':
				setTitle('修改代理');
				break;
			case 'ADMINISTRATOR':
				setTitle('修改管理员');
				break;
			case 'bank':
				setTitle('修改银行卡信息');
				break;
			case 'funds':
				setTitle('修改可用资金');
				break;
			default:
				setTitle('修改用户');
		}
	}, []);
	return (
		<div className="modal-header">
			{/* begin::Modal title */}
			<h2 className="fw-bolder">{title}</h2>
			{/* end::Modal title */}

			{/* begin::Close */}
			<div
				className="btn btn-icon btn-sm btn-active-icon-primary"
				data-kt-users-modal-action="close"
				onClick={() => {
					setShowModal(false);
				}}
				style={{ cursor: 'pointer' }}
			>
				{type != 'TRADER' && type != 'AGENT' && type != 'ADMINISTRATOR' && (
					<KTSVG
						path="/media/icons/duotune/arrows/arr061.svg"
						className="svg-icon-1"
					/>
				)}
			</div>
			{/* end::Close */}
			{isCancel && (
				<BeforeCancel setShowModal={setShowModal} setIsCancel={setIsCancel} />
			)}
		</div>
	);
};

export { UserModifyModalHeader };
