import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ls from 'local-storage';
import { ToastContainer } from 'react-toastify';
import { toAbsoluteUrl } from '../helpers';
import { HeaderWrapper } from './components/header';
// import { RightToolbar } from '../partials/layout/RightToolbar';
import { ScrollTop } from './components/scroll-top';
import { Content } from './components/content';
// import { FooterWrapper } from './components/footer';
import { Sidebar } from './components/sidebar';
import {
	DrawerNotification,
	ActivityDrawer,
	InviteUsers,
	UpgradePlan,
	ThemeModeProvider,
} from '../partials';
import { PageDataProvider } from './core';
import { reInitMenu } from '../helpers';
import { UserDetailModal } from '../../app/pages/user/userDetailModal';
import { AdminDetailModal } from '../../app/pages/user/adminDetailModal';
import { UserModifyModal } from '../../app/pages/user/user-modify-modal/UserModifyModal';
// import { ToolbarWrapper } from './components/toolbar';
import {
	setTableBreakPoint,
	setAppPreviewOnly,
} from '../../store/slices/global';
import eventBus from '../../utils/eventBus';
import request from '../../utils/request';
import {
	verifyToken,
	getSystemSettings,
	// testWsNotification,
} from '../../utils/service';
import { initWs, destroyWs } from '../../utils/ws';

const MasterLayout = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const themeMode = useSelector((state) => state.global.themeMode);
	const appPreviewOnly = useSelector((state) => state.global.appPreviewOnly);
	const [isTokenValid, setIsTokenValid] = useState(false);
	// 代理商、用户详情
	const [showDetailModal, setShowDetailModal] = useState(false);
	// 管理员详情
	const [showAdminDetailModal, setShowAdminDetailModal] = useState(false);
	const [showMidifyModal, setShowMidifyModal] = useState(false);
	const [data, setData] = useState();

	const doVerifyToken = async () => {
		const token = ls.get('X-Auth-Token') || '';
		try {
			const resp = await verifyToken(token);
			const { is_success } = resp;
			setIsTokenValid(is_success);
			if (!is_success) {
				eventBus.dispatch('backToAuth');
			}
		} catch (error) {
			console.log(error);
		}
	};

	const doGetSystemSettings = async () => {
		try {
			const resp = await getSystemSettings();
			const { data } = resp;
			if (data.length) {
				data[0] && ls.set('Android-Download-Link', data[0].value);
				data[1] && ls.set('IOS-Download-Link', data[1].value);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const isFromApp = () => {
		return (
			(location.pathname === '/content-management/news-list' ||
				location.pathname === '/system-management/agreement-list') &&
			location.search.indexOf('id') > -1
		);
	};

	eventBus.on('tableActionDetail', (data) => {
		setShowDetailModal(true);
		setData(data);
	});
	eventBus.on('tableActionAdminDetail', (data) => {
		setShowAdminDetailModal(true);
		setData(data);
	});
	eventBus.on('tableActionGlobalUserEdit', async (data) => {
		const { user_id } = data;
		const resp = await request.get('/users', {
			params: {
				filters: {
					user_id,
				},
				current_page: 1,
				each_page: 10,
			},
		});
		setData({ record: resp.data[0] });
		setShowMidifyModal(true);
	});

	const detailOpentModify = () => {
		setShowMidifyModal(true);
	};

	useEffect(() => {
		!appPreviewOnly && initWs();
		// testWsNotification({
		// 	notification_type: 'USER_STATUS',
		// 	title: '用户注册申请',
		// 	sub_type: 'status',
		// 	content: '用户注册申请016',
		// });
	}, []);

	useEffect(() => {
		reInitMenu();
	}, [location.key]);

	useEffect(() => {
		const backToAuth = () => {
			destroyWs();
			navigate('/auth');
		};
		if (isFromApp()) {
			dispatch(setAppPreviewOnly(true));
			setIsTokenValid(true);
		} else {
			doVerifyToken();
			doGetSystemSettings();
		}
		eventBus.on('backToAuth', backToAuth);
		return () => {
			eventBus.remove('backToAuth', backToAuth);
		};
	}, []);

	useEffect(() => {
		const iw = window.innerWidth;
		function handleWindowResize(e) {
			const innerWidth = e.target.innerWidth;
			if (innerWidth < 800) {
				dispatch(setTableBreakPoint({ status: 'narrow' }));
			} else {
				dispatch(setTableBreakPoint({ status: 'normal' }));
			}
		}
		if (iw < 800) {
			dispatch(setTableBreakPoint({ status: 'narrow' }));
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	});

	return (
		<PageDataProvider>
			<ThemeModeProvider>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					theme={themeMode}
					pauseOnHover={false}
				/>
				<div
					className="d-flex flex-column flex-root app-root"
					id="kt_app_root"
					style={{ position: 'relative' }}
				>
					{!isTokenValid && (
						<div
							style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
							}}
						>
							<img
								src={toAbsoluteUrl('/media/logos/logo-new.jpg')}
								style={{ width: '48px', height: '48px', marginBottom: '10px' }}
							/>
							<p style={{ color: themeMode === 'light' ? '#333' : '#fff' }}>
								加载中...
							</p>
						</div>
					)}
					{isTokenValid && (
						<div
							className="app-page flex-column flex-column-fluid"
							id="kt_app_page"
						>
							<HeaderWrapper show={!appPreviewOnly ? 'flex' : 'none'} />
							<div
								className="app-wrapper flex-column flex-row-fluid"
								id="kt_app_wrapper"
							>
								{!appPreviewOnly && <Sidebar />}
								<div
									className="app-main flex-column flex-row-fluid"
									id="kt_app_main"
								>
									<div className="d-flex flex-column flex-column-fluid">
										{/* <ToolbarWrapper /> */}
										<Content>
											<Outlet />
										</Content>
									</div>
								</div>
								{/* <FooterWrapper /> */}
							</div>
						</div>
					)}
				</div>

				{/* begin:: Drawers */}
				<ActivityDrawer />
				{/* <RightToolbar /> */}
				{!isFromApp() && <DrawerNotification />}
				{/* end:: Drawers */}

				{/* begin:: Modals */}
				<InviteUsers />
				<UpgradePlan />
				{/* end:: Modals */}
				<ScrollTop />
				{/* 代理商、用户详情 */}
				{showDetailModal && (
					<UserDetailModal
						setShowModal={setShowDetailModal}
						user={data?.record}
						id={data?.id}
						detailOpentModify={detailOpentModify}
					/>
				)}
				{showAdminDetailModal && (
					<AdminDetailModal
						setShowModal={setShowAdminDetailModal}
						user={data.record}
						detailOpentModify={detailOpentModify}
					/>
				)}
				{showMidifyModal && (
					<UserModifyModal
						setShowModal={setShowMidifyModal}
						type={
							data.record.user_type.indexOf('_') > -1
								? 'ADMINISTRATOR'
								: data.record.user_type
						}
						user={data.record}
						action={data?.action}
					/>
				)}
			</ThemeModeProvider>
		</PageDataProvider>
	);
};

export { MasterLayout };
