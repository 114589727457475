import React from 'react';
import clsx from 'clsx';
import qs from 'qs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/zh-cn.js';
import { KTSVG, toAbsoluteUrl } from '../_metronic/helpers';
import { toast } from 'react-toastify';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

moment.locale('zh-cn');

function unsecuredCopyToClipboard(text) {
	const textArea = document.createElement('textarea');
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();
	try {
		document.execCommand('copy');
	} catch (err) {
		console.error('Unable to copy to clipboard', err);
	}
	document.body.removeChild(textArea);
}

export async function copyToClipboard(text) {
	if (navigator.clipboard) {
		await navigator.clipboard.writeText(text);
	} else {
		unsecuredCopyToClipboard(text);
	}
	toast.success('已复制到剪贴板！');
}

export function getObjectKeyByValue(object, value) {
	return Object.keys(object).find((key) => object[key] === value);
}

export function currencyFormater(value, suffix = '', prefix = '', fracNum = 2) {
	let color;
	if (isNaN(value)) {
		return '';
	}
	if (value > 0) {
		color = '#d9214e';
		prefix += '+';
	} else {
		color = '#47be7d';
	}
	return (
		<span style={{ color }}>
			{prefix}
			{new Intl.NumberFormat('zh-CN', {
				currency: 'CNY',
				minimumFractionDigits: fracNum,
				maximumFractionDigits: fracNum,
			}).format(value)}
			{suffix}
		</span>
	);
}

export function dollarCurrencyFormater(value) {
	return new Intl.NumberFormat('en-HOSSDDG', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	}).format(value);
}

export function getSearchQs(search) {
	if (search !== '') {
		search = search.slice(1);
		return JSON.parse(qs.parse(search).search);
	} else {
		return {};
	}
}

export function notificationListItemRenderer(data = []) {
	const iconMap = {
		USER_STATUS: {
			color: 'primary',
			icon: 'communication/com014.svg',
		},
		BALANCE_STATUS: {
			color: 'warning',
			icon: 'finance/fin010.svg',
		},
	};

	const subTypeMap = {
		status: {
			path: '/user-management/user-list',
			search: { status: '1' },
		},
		audit_status: {
			path: '/user-management/user-list',
			search: { auth_status: 'SUBMITTED' },
		},
		withdrawal: {
			path: '/order-management/withdraw-list',
			search: { status: 'SUBMITTED' },
		},
		deposit: {
			path: '/order-management/recharge-list',
			search: { status: 'SUBMITTED' },
		},
	};

	return (
		<>
			{data.length <= 0 && (
				<div
					className="d-flex flex-column align-items-center justify-content-center"
					style={{ height: '100%' }}
				>
					<img
						src={toAbsoluteUrl(
							'/media/illustrations/custom/notification-no-result.png'
						)}
						style={{ width: '175px', height: '175px' }}
					/>
					<span style={{ color: '#7e8299' }}>暂无消息内容</span>
				</div>
			)}
			{/* <div className="scroll-y mh-325px my-5 px-8"> */}
			{data.map(
				({
					id,
					notification_type,
					title,
					content,
					status,
					sub_type,
					create_time,
				}) => (
					<Link
						reloadDocument={true}
						key={id}
						to={`${subTypeMap[sub_type].path}?search=${JSON.stringify(
							subTypeMap[sub_type].search
						)}`}
						// state={subTypeMap[sub_type].search}
					>
						<div className="d-flex flex-stack py-4">
							<div className="d-flex align-items-center">
								<div className="symbol symbol-35px me-4">
									<span
										className={clsx(
											'symbol-label',
											`bg-light-${iconMap[notification_type].color}`
										)}
									>
										{' '}
										<KTSVG
											path={`/media/icons/duotune/${iconMap[notification_type].icon}`}
											className={`svg-icon-1 svg-icon-${iconMap[notification_type].color}`}
										/>
									</span>
									{status === 'UNREAD' && (
										<span
											className="symbol-badge badge badge-circle bg-danger start-100"
											style={{ width: '10px', height: '10px' }}
										/>
									)}
								</div>

								<div className="mb-0 me-2">
									<span className="fs-6 text-gray-800 fw-bolder">{title}</span>
									<div className="text-gray-600 fs-7">{content}</div>
								</div>
							</div>

							<span className="badge badge-light fs-8">
								{moment(create_time).fromNow()}
							</span>
						</div>
					</Link>
				)
			)}
			{/* </div> */}
		</>
	);
}

export function initLightbox(selector) {
	const images = document.querySelectorAll(selector);
	for (let i = 0, len = images.length; i < len; i++) {
		images[i].setAttribute('data-fancybox', 'gallery');
	}
	Fancybox.bind('[data-fancybox="gallery"]', {
		// Thumbs: false,
	});
}
