import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'apexcharts';
import {
	getCSS,
	getCSSVariableValue,
} from '../../../../_metronic/assets/ts/_utils';
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';

const ApexChartInit = ({
	height = 150,
	color,
	name,
	xAxisData = [],
	yAxisData = [],
}) => {
	const chartRef = useRef(null);
	const { mode } = useThemeMode();
	const refreshChart = () => {
		if (!chartRef.current) {
			return;
		}

		const height = parseInt(getCSS(chartRef.current, 'height'));
		const labelColor = getCSSVariableValue('--bs-gray-800');
		const baseColor = getCSSVariableValue('--bs-' + color);
		const lightColor = getCSSVariableValue('--bs-' + color + '-light');

		const chart = new ApexCharts(chartRef.current, {
			series: [
				{
					name,
					data: yAxisData,
				},
			],
			chart: {
				fontFamily: 'inherit',
				type: 'area',
				height: height,
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
				sparkline: {
					enabled: true,
				},
				events: {
					mounted: (chart) => {
						chart.windowResizeHandler();
					},
				},
			},
			plotOptions: {},
			legend: {
				show: false,
			},
			dataLabels: {
				enabled: false,
			},
			fill: {
				type: 'gradient',
				opacity: 1,
			},
			stroke: {
				curve: 'smooth',
				show: true,
				width: 3,
				colors: [baseColor],
			},
			xaxis: {
				categories: xAxisData,
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: false,
					style: {
						colors: labelColor,
						fontSize: '12px',
					},
				},
				crosshairs: {
					show: false,
					position: 'front',
					stroke: {
						color: '#E4E6EF',
						width: 1,
						dashArray: 3,
					},
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				// min: 0,
				// max: 60,
				labels: {
					show: false,
					style: {
						colors: labelColor,
						fontSize: '12px',
					},
				},
			},
			states: {
				normal: {
					filter: {
						type: 'none',
						value: 0,
					},
				},
				hover: {
					filter: {
						type: 'none',
						value: 0,
					},
				},
				active: {
					allowMultipleDataPointsSelection: false,
					filter: {
						type: 'none',
						value: 0,
					},
				},
			},
			tooltip: {
				style: {
					fontSize: '12px',
				},
				// y: {
				// 	formatter: function (val) {
				// 		return '$' + val + ' thousands';
				// 	},
				// },
			},
			// colors: [lightColor],
			markers: {
				colors: [lightColor],
				strokeColors: [baseColor],
				strokeWidth: 3,
			},
		});
		if (chart) {
			chart.render();
		}

		return chart;
	};

	useEffect(() => {
		const chart = refreshChart();
		return () => {
			if (chart) {
				chart.destroy();
			}
		};
	}, [chartRef, color, mode]);

	return (
		<div
			ref={chartRef}
			className="statistics-widget-4-chart card-rounded-bottom"
			style={{ height: `${height}px` }}
		></div>
	);
};

ApexChartInit.propTypes = {
	height: PropTypes.number,
	color: PropTypes.string,
	name: PropTypes.string,
	xAxisData: PropTypes.array,
	yAxisData: PropTypes.array,
};

export default ApexChartInit;
