/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, useRef } from 'react';
import {
	Select,
	Tabs,
	Table,
	InputNumber,
	Slider,
	Tooltip,
	Spin,
	Switch,
	ConfigProvider,
	theme,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { initialUser } from '../../../modules/apps/user-management/users-list/core/_models';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import request from '../../../../utils/request';
import { ResetSetting } from './ResetSetting';
import { SureSetting } from './SureSetting';
import { Tips } from './Tips';
import { BeforeCancel } from './BeforeCancel';
import {
	editUserSchema1,
	editUserSchema2,
	editUserSchema5,
	editUserSchema6,
	editAgentSchema2,
	editAdminSchema1,
	editAdminSchema2,
	userRoleArr,
	items1,
	items2,
	items3,
	items4,
	enumText,
} from './const';
import { find, findIndex } from 'lodash';
import './index.scss';

import BaseForm from '../../risk-settings-stock/components/BaseForm';
import Uploader from '../../../components/Uploader/Uploader';

let transactionStockTableDataResp;
let transactionGoldTableDataResp;
let feeStockTableDataResp;
let feeGoldTableDataResp;
let withdrawDepositTableDataResp;

const UserModifyModalForm = (props) => {
	const navigate = useNavigate();
	const { setShowModal, user, isUserLoading, action } = props;
	const mfa = useSelector((state) => state.global.mfa);
	const themeMode = useSelector((state) => state.global.themeMode);
	const defaultIdImage = toAbsoluteUrl(
		themeMode === 'light'
			? '/media/default/default_cover.png'
			: '/media/default/default_cover_dark.png'
	);
	const [userMsg, setUserMsg] = useState({});
	const [tabActive, setTabActive] = useState(1);
	const [activeSettingTab, setActiveSettingTab] = useState('transaction');
	const [agentId, setAgentId] = useState();
	const [defaultAgentValue, setDefaultAgentValue] = useState();
	const [options, setOptions] = useState([]);
	const [searchValue, setSearchValue] = useState();
	const [items, setItems] = useState([]);
	const [settingTable, setSettingTable] = useState(false);
	const [settingTableData, setSettingTableData] = useState([]);
	const [setting, setSetting] = useState({});
	const [oldDataObj, setOldDataObj] = useState({ max_lever: 10 });
	const [maxLever, setMaxLever] = useState(1);
	const [isFinancing, setIsFinancing] = useState(false);
	const [isShortSale, setIsShortSale] = useState(false);
	const [transactionCom, setTransactionCom] = useState(null);
	const [stopBuyingSwitch, setStopBuyingSwitch] = useState(false);
	const [resetSetting, setResetSetting] = useState(false);
	const [sureSetting, setSureSetting] = useState(false);
	const [defaultSelectedRowKeys, setDefaultSelectedRowKeys] = useState([]);
	const [isShouldCellUpdate, setIsShouldCellUpdate] = useState(false);
	const [isCancel, setIsCancel] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [hasChange, setHasChange] = useState(false);
	const [transactionUserConfig, setTransactionUserConfig] = useState([]);
	const [isDemoUser, setIsDemoUser] = useState(false);
	const [idFrontPhoto, setIdFrontPhoto] = useState(
		!user.front_photo || user.front_photo === ''
			? defaultIdImage
			: user.front_photo
	);
	const [idOppositePhoto, setIdOppositePhoto] = useState(
		!user.opposite_photo || user.opposite_photo === ''
			? defaultIdImage
			: user.opposite_photo
	);
	const [idHoldPhoto, setIdHoldPhoto] = useState(
		!user.hold_photo || user.hold_photo === ''
			? defaultIdImage
			: user.hold_photo
	);
	const [userForEdit] = useState({
		...user,
		username: user.username || initialUser.username,
		email: user.email || initialUser.email,
		phone: user.phone || initialUser.phone,
		full_name: user.full_name || initialUser.full_name,
		id_no: user.id_no || initialUser.id_no,
		is_margin_trading_and_allow: false,
		is_increase_limit_for_purchases: false,
		user_type: user.user_type || initialUser.user_type,
	});
	// const selectedRowsRef = useRef([]);
	const repeatValueRef = useRef([]);
	// const referralCode = useRef('');
	const columns = [
		{
			title: '选择字段',
			dataIndex: 'name',
			width: 150,
			render: (text, record) => {
				return (
					<>
						<span className="me-2">{text}</span>
						{record.tip && (
							<Tooltip title={record.tip}>
								<span style={{ width: '20px' }}>
									<KTSVG
										path="/media/icons/duotune/general/gen044.svg"
										className="svg-icon-1tx my-2"
										style={{ color: '#99A1B7' }}
									/>
								</span>
							</Tooltip>
						)}
					</>
				);
			},
		},
		{
			title: '平台设置',
			dataIndex: 'platform',
			width: 150,
			render: (text, record) => {
				const { type, value } = record;
				if (type === 'switch') {
					return <Switch value={value} />;
				} else if (type === 'float') {
					return value;
				}
			},
		},
		{
			title: '用户设置',
			dataIndex: 'userSetting',
			render: (text, record) => {
				const { key, type, value } = record;
				const hasSelected = find(selectedRows, { key: key });
				let r = { ...record };
				let v;
				if (Array.isArray(setting)) {
					const item = find(setting, { key });
					if (item) {
						v = item.value;
					}
				}
				return (
					<>
						{type === 'switch' && hasSelected && (
							<Switch
								defaultValue={v ? v : value}
								onChange={(checked) => {
									r.value = checked;
									let config = [...transactionUserConfig];
									const index = findIndex(transactionUserConfig, { key });
									if (index >= 0) {
										config[index] = r;
									} else {
										config.push(r);
									}
									setTransactionUserConfig(config);
								}}
							/>
						)}
						{type === 'float' && hasSelected && (
							<InputNumber
								step="0.1"
								// max="1"
								min="0"
								defaultValue={v ? v : value}
								onChange={(value) => {
									r.value = value;
									let config = [...transactionUserConfig];
									const index = findIndex(transactionUserConfig, { key });
									if (index >= 0) {
										config[index] = r;
									} else {
										config.push(r);
									}
									setTransactionUserConfig(config);
								}}
							/>
						)}
					</>
				);
			},
		},
		// {
		// 	title: '用户设置',
		// 	dataIndex: 'userSetting',
		// 	render: (text, record) => {
		// 		const { key, userSetting, field } = record;
		// 		const hasSelected = find(selectedRows, { key: key });
		// 		return (
		// 			<>
		// 				{key == 'max_lever' && hasSelected && (
		// 					<div className="d-flex align-items-center">
		// 						<span className="me-2">1X</span>
		// 						<Slider
		// 							min={1}
		// 							max={10}
		// 							onChange={(newValue) => {
		// 								setMaxLever(newValue);
		// 								// 刷新table,不然修改后maxLeverX展示不会变化（编辑时候）
		// 								setIsShouldCellUpdate(true);
		// 								compareValue(key, newValue, field);
		// 								newValue && onChangeData();
		// 							}}
		// 							defaultValue={typeof maxLever === 'number' ? maxLever : 1}
		// 							style={{ width: '90%' }}
		// 						/>
		// 						<span className="ms-2">{maxLever}X</span>
		// 					</div>
		// 				)}
		// 				{key == 'transaction_commission' && hasSelected && (
		// 					<div className="d-flex align-items-center">
		// 						<input
		// 							className="form-control form-control-solid me-1"
		// 							// addonAfter="%"
		// 							type="number"
		// 							defaultValue={transactionCom}
		// 							onChange={(e) => {
		// 								setTransactionCom(e.target.value);
		// 								compareValue(key, e.target.value, field);
		// 								e.target.value && onChangeData();
		// 							}}
		// 						/>
		// 						%
		// 					</div>
		// 				)}
		// 				{(key == 'is_financing' ||
		// 					key == 'is_short_sale' ||
		// 					key == 'stop_buying_switch') &&
		// 					hasSelected && (
		// 						<label className="form-check form-switch form-check-custom form-check-solid d-flex">
		// 							<input
		// 								className="form-check-input"
		// 								defaultChecked={
		// 									key == 'is_financing'
		// 										? isFinancing
		// 										: key == 'is_short_sale'
		// 										? isShortSale
		// 										: stopBuyingSwitch
		// 								}
		// 								name={`is_financing${key}`}
		// 								type="checkbox"
		// 								onChange={(e) => {
		// 									key == 'is_financing' && setIsFinancing(e.target.checked);
		// 									key == 'is_short_sale' &&
		// 										setIsShortSale(e.target.checked);
		// 									key == 'stop_buying_switch' &&
		// 										setStopBuyingSwitch(e.target.checked);
		// 									compareValue(key, e.target.checked, field);
		// 									e.target.checked && onChangeData();
		// 								}}
		// 							/>
		// 							<span className="ms-3">{enumText[key][userSetting]}</span>
		// 						</label>
		// 					)}
		// 			</>
		// 		);
		// 	},
		// 	shouldCellUpdate: () => {
		// 		if (isShouldCellUpdate) {
		// 			return true;
		// 		}
		// 	},
		// },
	];
	const data = [
		{
			key: 'max_lever',
			field: '最大合约杠杆倍数',
			platform: `${oldDataObj?.max_lever}X`,
			userSetting: maxLever,
			tip: '交易时可选择杠杆为1X~10X',
		},
		{
			key: 'transaction_commission',
			field: '交易佣金',
			platform: `${oldDataObj?.transaction_commission || 0}%`,
			userSetting: transactionCom,
			tip: '按照成交金额，买卖双向收取',
		},
		{
			key: 'is_financing',
			field: '是否可以融资',
			platform: oldDataObj?.is_financing ? '可融资' : '不可融资',
			userSetting: isFinancing,
		},
		{
			key: 'is_short_sale',
			field: '是否可以融券',
			platform: oldDataObj?.is_short_sale ? '可融券' : '不可融券',
			userSetting: isShortSale,
		},
		{
			key: 'stop_buying_switch',
			field: '涨停是否能购买',
			platform: oldDataObj?.stop_buying_switch ? '是' : '否',
			userSetting: stopBuyingSwitch,
		},
	];
	const compareValue = (key, value, field) => {
		const index = repeatValueRef.current.indexOf(field);
		if (oldDataObj[key] === value && index == -1) {
			repeatValueRef.current.push(field);
		} else if (oldDataObj[key] !== value && index > -1) {
			repeatValueRef.current.splice(index, 1);
		}
	};
	const settingTabItems = [
		{
			key: 'transaction',
			label: '交易设置',
			// disabled: settingTable,
		},
		{
			key: 'fee',
			label: '手续费设置',
			// disabled: settingTable,
		},
	];
	const formik1 = useFormik({
		initialValues: userForEdit,
		validationSchema:
			user.user_type === 'TRADER'
				? editUserSchema1
				: user.user_type === 'AGENT'
				? editUserSchema1
				: editAdminSchema1,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await updateUser(values);
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				setShowModal(false);
			}
		},
	});
	const formik2 = useFormik({
		initialValues: userForEdit,
		validationSchema: editUserSchema2,
		// user.user_type === 'TRADER'
		// 	? editUserSchema2
		// 	: user.user_type === 'AGENT'
		// 	? editAgentSchema2
		// 	: editAdminSchema2,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await updateUser(values);
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				setShowModal(false);
			}
		},
	});
	const formik3 = useFormik({
		initialValues: userForEdit,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await updateUser(values);
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				setShowModal(false);
			}
		},
	});
	const formik4 = useFormik({
		initialValues: userForEdit,
		// validationSchema: user.user_type === 'TRADER' ? editUserSchema4 : '',
		onSubmit: async (values, { setSubmitting }) => {
			if (window.canFormSubmit) {
				let data = [...selectedRows];
				for (let i = 0, len = data.length; i < len; i++) {
					const key = data[i].key;
					const index = findIndex(transactionUserConfig, { key });
					if (index >= 0) {
						data[i] = transactionUserConfig[index];
					}
				}
				let settingSys = '';
				if (tabActive == 7) {
					settingSys = 'EXCHANGE';
				} else if (tabActive == 4) {
					settingSys = 'INDONESIA';
				} else if (tabActive == 8) {
					settingSys = 'INDONESIA_GOLD';
				}
				await request.put(`/users/setting/${user.user_id}`, {
					data,
					setting_type: activeSettingTab.toUpperCase(),
					setting_sys: settingSys,
				});
			}
			toast.success('设置成功');
			if (settingTable) {
				setSettingTable(false);
				getSetting();
			}
			// cancel();
			window.canFormSubmit = true;
			// setSubmitting(true);
			// try {
			// 	if (user.user_type === 'TRADER' && selectedRows?.length > 0) {
			// 		updateSetting();
			// 	}
			// } catch (ex) {
			// 	console.error(ex);
			// }
		},
	});
	const formik5 = useFormik({
		initialValues: userForEdit,
		validationSchema: editUserSchema5,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await updateUser(values);
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				setShowModal(false);
			}
		},
	});
	const formik6 = useFormik({
		initialValues: userForEdit,
		validationSchema: editUserSchema6,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await updateUser({
					...values,
					front_photo: idFrontPhoto,
					opposite_photo: idOppositePhoto,
					hold_photo: idHoldPhoto,
				});
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				setShowModal(false);
			}
		},
	});
	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			// selectedRowsRef.current = selectedRows;
			setIsShouldCellUpdate(true);
			// 页面新一轮渲染
			setSelectedRows(selectedRows);
			console.log(
				`selectedRowKeys: ${selectedRowKeys}`,
				'selectedRows: ',
				selectedRows
			);
		},
		onSelect: (record, selected) => {
			// 再次选中去掉之前的值
			if (!selected) {
				switch (record.key) {
					case 'max_lever':
						setMaxLever(1);
						break;
					case 'transaction_commission':
						setTransactionCom();
						break;
					case 'is_financing':
						setIsFinancing(false);
						break;
					case 'is_short_sale':
						setIsShortSale(false);
						break;
					case 'stop_buying_switch':
						setStopBuyingSwitch(false);
						break;
				}
				// 与原来设置一致的提示去掉
				const index = repeatValueRef.current.indexOf(record.field);
				index > -1 && repeatValueRef.current.splice(index, 1);
			}
		},
		getCheckboxProps: (record) => ({
			// Column configuration not to be checked
			name: record.name,
		}),
	};
	const getUserInfo = async () => {
		const resp = await request.get(`/users/${user.user_id}`);
		setAgentId(resp.parent_user_id);
		setIsDemoUser(resp.is_demo);
		resp.parent_user_id &&
			setDefaultAgentValue(`${resp.parent_username}(${resp.parent_phone})`);
		setUserMsg(resp);
		formik2.setFieldValue('id_no', resp.id_no);
		// formik1.setFieldValue('mfa_code', '');
		formik4.setFieldValue('max_lever', 10);
		formik4.setFieldValue('commission', 0.001);
	};
	const getSetting = async () => {
		setLoading(true);
		let settingSys = '';
		if (tabActive == 4) {
			settingSys = 'INDONESIA';
		} else if (tabActive == 7) {
			settingSys = 'EXCHANGE';
		} else if (tabActive == 8) {
			settingSys = 'INDONESIA_GOLD';
		}
		const resp = await request.get(
			`/users/setting/${
				user.user_id
			}?setting_type=${activeSettingTab.toUpperCase()}&setting_sys=${settingSys}`
		);
		setLoading(false);
		const { data } = resp;
		setSetting(data);
		// if (Object.keys(setting).length > 0) setSettingTable(true);
		if (!Array.isArray(data)) return;
		setDefaultSelectedRowKeys(data.map((item) => item.key));
		const selectedRowArr = [];
		// selectedRowsRef.current = [];
		data.forEach((item) => {
			if (Object.keys(setting).includes(item.key)) {
				item.userSetting =
					item.key === 'transaction_commission'
						? Number((setting[item.key] * 100).toFixed(2))
						: setting[item.key];
				selectedRowArr.push(item);
			}
		});
		// setSelectedRows(selectedRowArr);
		setSelectedRows(data);
		setMaxLever(setting?.max_lever || 1);
		setTransactionCom(
			Number((setting?.transaction_commission * 100).toFixed(2))
		);
		setIsFinancing(setting?.is_financing || false);
		setIsShortSale(setting?.is_short_sale || false);
	};

	const getSystemSetting = async () => {
		if (!transactionStockTableDataResp) {
			transactionStockTableDataResp = await request.get(
				`/system_setting?setting_type=TRANSACTION&setting_sys=INDONESIA`
			);
		}
		if (!transactionGoldTableDataResp) {
			transactionGoldTableDataResp = await request.get(
				`/system_setting?setting_type=TRANSACTION&setting_sys=INDONESIA_GOLD`
			);
		}
		if (!feeStockTableDataResp) {
			feeStockTableDataResp = await request.get(
				`/system_setting?setting_type=FEE&setting_sys=INDONESIA`
			);
		}
		if (!feeGoldTableDataResp) {
			feeGoldTableDataResp = await request.get(
				`/system_setting?setting_type=FEE&setting_sys=INDONESIA_GOLD`
			);
		}
		if (!withdrawDepositTableDataResp) {
			withdrawDepositTableDataResp = await request.get(
				`/system_setting?setting_type=WITHDRAW_DEPOSIT&setting_sys=EXCHANGE`
			);
		}
		let resp;
		switch (activeSettingTab) {
			case 'transaction':
				if (tabActive == 4) {
					resp = transactionStockTableDataResp;
				} else if (tabActive == 8) {
					resp = transactionGoldTableDataResp;
				}
				if (!resp) return;
				setSettingTableData(resp.data);
				// setOldDataObj({
				// 	...oldDataObj,
				// 	transaction_commission: Number(
				// 		(
				// 			find(feeTableDataResp?.data, {
				// 				key: 'transaction_commission',
				// 			}).value * 100
				// 		).toFixed(2)
				// 	),
				// 	stop_buying_switch: find(resp?.data, {
				// 		key: 'stop_buying_switch',
				// 	}).value,
				// 	is_financing: find(resp?.data, {
				// 		key: 'is_financing',
				// 	}).value,
				// 	is_short_sale: find(resp?.data, {
				// 		key: 'is_short_sale',
				// 	}).value,
				// });
				break;
			case 'fee':
				if (tabActive == 4) {
					resp = feeStockTableDataResp;
				} else if (tabActive == 8) {
					resp = feeGoldTableDataResp;
				}
				if (!resp) return;
				setSettingTableData(resp.data);
				break;
			case 'withdraw_deposit':
				setSettingTableData(
					withdrawDepositTableDataResp && withdrawDepositTableDataResp.data
				);
				break;
		}
	};
	const updateUser = async (value) => {
		// value.parent_user_id = agentInfo.user_id;
		let obj = null;
		const {
			full_name,
			password1,
			password2,
			username,
			phone,
			id_no,
			// is_margin_trading_and_allow,
			// is_increase_limit_for_purchases,
			lever,
			max_lever,
			commission,
			user_type,
			fund_password1,
			fund_password2,
			mfa_code,
			front_photo,
			opposite_photo,
			hold_photo,
		} = value;
		if (user.user_type === 'TRADER' || user.user_type === 'AGENT') {
			if (tabActive == 1) {
				obj = { password1, password2 };
			} else if (tabActive == 2) {
				obj = { username, phone };
				// user.user_type === 'TRADER'
				// 	? { full_name, id_no: String(id_no) }
				// 	: user.user_type === 'AGENT'
				// 	? { full_name, username }
				// 	: { username, user_type };
			} else if (tabActive == 3) {
				obj = {
					parent_user_id: agentId || 0,
				};
			} else if (tabActive == 4) {
				obj = {
					// is_margin_trading_and_allow,
					// is_increase_limit_for_purchases,
					lever,
					max_lever,
					commission,
				};
			} else if (tabActive == 5) {
				obj = {
					fund_password1,
					fund_password2,
					mfa_code,
				};
			}
		} else {
			if (tabActive == 1) {
				obj = { password1, password2 };
			} else if (tabActive == 2) {
				obj = { username, phone };
			}
		}
		if (tabActive == 6) {
			obj = {
				full_name,
				id_no,
				front_photo,
				opposite_photo,
				hold_photo,
			};
		}
		const resp = await request.put(`/users/${user.user_id}`, {
			...obj,
			is_demo: isDemoUser,
		});
		if (resp.user_id) {
			toast.success('修改成功');
			action.reload();
		}
	};
	const updateSetting = async () => {
		setSureSetting(true);
	};
	const onChangeTab = (value) => {
		setTabActive(Number(value));
		if (value == 7) {
			setActiveSettingTab('withdraw_deposit');
		} else if (value == 4 || value == 8) {
			setActiveSettingTab('transaction');
		}
		setSettingTable(false);
	};
	const getAgentOptions = async () => {
		const resp = await request.get('/users', {
			params: {
				search: searchValue,
				filters: { user_type: 'AGENT' },
				current_page: 1,
			},
		});
		const arr = resp.data.map((item) => {
			return {
				label: `${item.username}(${item.phone})`,
				value: item.user_id,
			};
		});
		setOptions(arr);
	};
	const onSearch = (value) => {
		setSearchValue(value);
	};
	const handleChangeAgent = (value) => {
		setAgentId(value);
	};
	const tipsContent = () => {
		return (
			<>
				{!mfa && '您当前未绑定Google验证码,无法修改用户交易密码'}
				<span
					onClick={() => navigate('/my/validate')}
					style={{ color: '#3E97FF', cursor: 'pointer' }}
					className="ms-3"
				>
					{!mfa ? '去绑定' : '解除绑定'}
				</span>
			</>
		);
	};
	const cancel = () => {
		!hasChange && setShowModal(false);
		hasChange && setIsCancel(true);
	};
	const onChangeData = () => {
		setHasChange(true);
	};
	const doSettingTabChange = (key) => {
		setActiveSettingTab(key);
		setSettingTable(false);
	};

	useEffect(() => {
		// 点击保存到确认页数据有延迟
		selectedRows.forEach((item) => {
			if (item.key === 'max_lever') item.userSetting = maxLever;
			if (item.key === 'transaction_commission')
				item.userSetting = transactionCom;
			if (item.key === 'is_financing') item.userSetting = isFinancing;
			if (item.key === 'is_short_sale') item.userSetting = isShortSale;
			if (item.key === 'stop_buying_switch')
				item.userSetting = stopBuyingSwitch;
		});
	}, [maxLever, isFinancing, isShortSale, transactionCom, stopBuyingSwitch]);
	useEffect(() => {
		getUserInfo();
		getAgentOptions();
		if (user.user_type === 'TRADER') {
			// getSystemSetting();
			// getSetting();
		}
		user.user_type === 'TRADER'
			? setItems(items1.concat(items2, items3, items4))
			: user.user_type === 'AGENT'
			? setItems(items1.concat(items2, items4))
			: setItems(items1.concat(items4));
	}, []);

	useEffect(() => {
		if (tabActive != 4 && tabActive != 7 && tabActive != 8) {
			return;
		}
		getSetting();
		getSystemSetting();
	}, [tabActive, activeSettingTab]);

	return (
		<>
			{/* begin::Scroll */}
			<div
				className="d-flex flex-column modify-content scroll-y me-n7 pe-7"
				id="kt_modal_add_user_scroll"
				data-kt-scroll="true"
				data-kt-scroll-activate="{default: false, lg: true}"
				data-kt-scroll-max-height="auto"
				data-kt-scroll-dependencies="#kt_modal_add_user_header"
				data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
				data-kt-scroll-offset="300px"
			>
				<div className="fv-row mb-7 ms-1" style={{ position: 'relative' }}>
					<div className="card shadow-sm badge-light p-3">
						<h5>
							{user.user_type.indexOf('ADMINISTRATOR') > -1
								? user.username
								: user.full_name}
						</h5>
						<div className="flex" style={{ lineHeight: '17px' }}>
							<span className="flex">
								<KTSVG path="/media/icons/duotune/electronics/elc002.svg" />
								<span className="ms-1">{user.phone}</span>
							</span>
							<span className="flex ms-3">
								<KTSVG path="/media/icons/duotune/communication/com006.svg" />
								<span className="ms-1">
									{user.user_type === 'TRADER'
										? '普通用户'
										: user.user_type === 'AGENT'
										? '代理'
										: '管理员'}
								</span>
							</span>
							{user.user_type.indexOf('ADMINISTRATOR') == -1 && (
								<span className="flex ms-3">
									<KTSVG path="/media/icons/duotune/communication/com014.svg" />
									<span className="ms-1">
										{`${userMsg.parent_username || ''}(${
											userMsg.parent_phone || '无'
										})`}
									</span>
								</span>
							)}
							<span className="flex ms-3">
								<KTSVG path="/media/icons/duotune/general/gen047.svg" />
								<span
									className="ms-1"
									style={{
										color: `${userMsg.is_blocked ? '#ff4d4f' : '#50cd89'}`,
									}}
								>
									{userMsg.is_blocked ? '锁定' : '正常'}
								</span>
							</span>
						</div>
						<div
							className="fv-row"
							style={{
								position: 'absolute',
								top: '22px',
								right: '10px',
							}}
						>
							<label
								className="fs-7 mb-2"
								style={{
									display: 'inline',
								}}
							>
								是否模拟用户&nbsp;&nbsp;
							</label>
							<Switch
								checkedChildren="是"
								unCheckedChildren="否"
								checked={isDemoUser}
								onChange={async (checked) => {
									setIsDemoUser(checked);
									await request.put(`/users/${user.user_id}`, {
										is_demo: checked,
									});
									toast.success('设置成功！');
								}}
							/>
						</div>
					</div>
				</div>
				<ConfigProvider
					theme={{
						algorithm:
							themeMode === 'light'
								? theme.lightAlgorithm
								: theme.darkAlgorithm,
					}}
				>
					<Tabs items={items} onChange={onChangeTab} />
				</ConfigProvider>
				<div className="tab-content">
					<div
						className={`tab-pane fade ${tabActive == 1 ? 'show active' : ''}`}
					>
						<form
							id="kt_modal_add_user_form"
							className="form modify-form"
							onSubmit={formik1.handleSubmit}
							noValidate
						>
							{/* begin::Input group */}
							<div className="fv-row mb-7 ms-1">
								{/* begin::Label */}
								<label className="required fw-bold fs-6 mb-2">密码</label>
								{/* end::Label */}

								{/* begin::Input */}
								<input
									placeholder="密码"
									{...formik1.getFieldProps('password1')}
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{
											'is-invalid':
												formik1.touched.password1 && formik1.errors.password1,
										},
										{
											'is-valid':
												formik1.touched.password1 && !formik1.errors.password1,
										}
									)}
									type="text"
									name="password1"
									autoComplete="off"
									disabled={formik1.isSubmitting || isUserLoading}
									onChange={(e) => {
										formik1.setFieldValue('password1', e.target.value);
										e.target.value && onChangeData();
									}}
								/>
								{/* end::Input */}
								<p
									className={`text-gray-400 mt-3 ${
										formik1.touched.password1 && formik1.errors.password1
											? 'fv-plugins-message-container'
											: ''
									}`}
								>
									<span className="fv-help-block">
										8～12位，包含大小写字母、数字或特殊字符
									</span>
								</p>
							</div>
							<div className="fv-row mb-7 ms-1">
								{/* begin::Label */}
								<label className="required fw-bold fs-6 mb-2">确认密码</label>
								<input
									placeholder="确认密码"
									{...formik1.getFieldProps('password2')}
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{
											'is-invalid':
												formik1.touched.password2 && formik1.errors.password2,
										},
										{
											'is-valid':
												formik1.touched.password2 && !formik1.errors.password2,
										}
									)}
									type="text"
									name="password2"
									autoComplete="off"
									disabled={formik1.isSubmitting || isUserLoading}
									onChange={(e) => {
										formik1.setFieldValue('password2', e.target.value);
										e.target.value && onChangeData();
									}}
								/>
								{/* end::Input */}
								{formik1.touched.password2 && formik1.errors.password2 && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik1.errors.password2}</span>
										</div>
									</div>
								)}
							</div>
							<div className="text-center pt-10">
								<button
									type="reset"
									onClick={() => cancel()}
									className="btn btn-light me-3"
									data-kt-users-modal-action="cancel"
									disabled={formik1.isSubmitting || isUserLoading}
								>
									取消
								</button>
								<button
									type="submit"
									className="btn btn-primary"
									data-kt-users-modal-action="submit"
									disabled={
										isUserLoading ||
										formik1.isSubmitting ||
										!formik1.isValid ||
										!formik1.touched
									}
									data-kt-indicator={
										formik1.isSubmitting || isUserLoading ? 'on' : 'off'
									}
								>
									<span className="indicator-label">确定</span>
									{(formik1.isSubmitting || isUserLoading) && (
										<span className="indicator-progress">
											等待中...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</button>
							</div>
						</form>
					</div>
					<div
						className={`tab-pane fade ${tabActive == 2 ? 'show active' : ''}`}
					>
						<form
							id="kt_modal_add_user_form"
							className="form modify-form"
							onSubmit={formik2.handleSubmit}
							noValidate
						>
							{user.user_type !== 'TRADER' && (
								<div className="fv-row mb-7 ms-1">
									{/* begin::Label */}
									<label className="required fw-bold fs-6 mb-2">
										{user.user_type === 'AGENT' ? '代理姓名' : '管理员名称'}
									</label>
									<input
										placeholder={
											user.user_type === 'AGENT' ? '代理姓名' : '管理员名称'
										}
										{...formik2.getFieldProps('username')}
										type="text"
										name="username"
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{
												'is-invalid':
													formik2.touched.username && formik2.errors.username,
											},
											{
												'is-valid':
													formik2.touched.username && !formik2.errors.username,
											}
										)}
										autoComplete="off"
										disabled={formik2.isSubmitting || isUserLoading}
										onChange={(e) => {
											formik2.setFieldValue('username', e.target.value);
											e.target.value && onChangeData();
										}}
									/>
									{formik2.touched.username && formik2.errors.username && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik2.errors.username}</span>
											</div>
										</div>
									)}
									{/* end::Input */}
								</div>
							)}
							{/* begin::Input group */}
							{user.user_type.indexOf('ADMINISTRATOR') == -1 && (
								<>
									{/* <div className="fv-row mb-7 ms-1"> */}
									{/* begin::Label */}
									{/* <label className="required fw-bold fs-6 mb-2">
											手机号码
										</label> */}
									{/* end::Label */}
									{/* begin::Input */}
									{/* <input
											placeholder="手机号码"
											{...formik2.getFieldProps('phone')}
											type="text"
											name="phone"
											className={clsx(
												'form-control form-control-solid mb-3 mb-lg-0',
												{
													'is-invalid':
														formik2.touched.phone && formik2.errors.phone,
												},
												{
													'is-valid':
														formik2.touched.phone && !formik2.errors.phone,
												}
											)}
											autoComplete="off"
											disabled={formik2.isSubmitting || isUserLoading}
										/>
										{formik2.touched.phone && formik2.errors.phone && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{formik2.errors.phone}</span>
												</div>
											</div>
										)} */}
									{/* end::Input */}
									{/* </div> */}
									<div className="fv-row mb-7 ms-1">
										{/* begin::Label */}
										<label className="required fw-bold fs-6 mb-2">用户名</label>
										<input
											placeholder="用户名"
											{...formik2.getFieldProps('username')}
											type="text"
											name="username"
											className={clsx(
												'form-control form-control-solid mb-3 mb-lg-0',
												{
													'is-invalid':
														formik2.touched.username && formik2.errors.username,
												},
												{
													'is-valid':
														formik2.touched.username &&
														!formik2.errors.username,
												}
											)}
											autoComplete="off"
											disabled={formik2.isSubmitting || isUserLoading}
											onChange={(e) => {
												formik2.setFieldValue('username', e.target.value);
												e.target.value && onChangeData();
											}}
										/>
										{formik2.touched.username && formik2.errors.username && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{formik2.errors.username}</span>
												</div>
											</div>
										)}
										{/* end::Input */}
									</div>
								</>
							)}
							{user.user_type === 'TRADER' && (
								<div className="fv-row mb-7 ms-1">
									{/* begin::Label */}
									<label className="required fw-bold fs-6 mb-2">手机号码</label>
									<input
										placeholder="手机号码"
										{...formik2.getFieldProps('phone')}
										type="number"
										name="phone"
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{
												'is-invalid':
													formik2.touched.phone && formik2.errors.phone,
											},
											{
												'is-valid':
													formik2.touched.phone && !formik2.errors.phone,
											}
										)}
										autoComplete="off"
										disabled={formik2.isSubmitting || isUserLoading}
										onChange={(e) => {
											formik2.setFieldValue('phone', e.target.value);
											e.target.value && onChangeData();
										}}
									/>
									{formik2.touched.phone && formik2.errors.phone && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik2.errors.phone}</span>
											</div>
										</div>
									)}
									{/* end::Input */}
								</div>
							)}
							{user.user_type.indexOf('ADMINISTRATOR') > -1 && (
								<div className="fv-row mb-7 mx-1">
									{/* begin::Label */}
									<label className="required fw-bold fs-6 mb-2">角色类型</label>
									<select
										{...formik2.getFieldProps('user_type')}
										className="form-select mb-2"
										name="user_type"
										data-control="select2"
										data-hide-search="true"
										data-placeholder="角色类型"
										onChange={(e) => {
											formik2.setFieldValue('user_type', e.target.value);
											e.target.value && onChangeData();
										}}
									>
										{userRoleArr.map((item) => {
											return (
												<option value={item.value} key={item.value}>
													{item.label}
												</option>
											);
										})}
									</select>
									{formik2.touched.user_type && formik2.errors.user_type && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik2.errors.user_type}</span>
											</div>
										</div>
									)}
									{/* end::Input */}
								</div>
							)}
							{/* end::Input group */}
							{/* begin::Actions */}
							<div className="text-center pt-10">
								<button
									type="reset"
									onClick={() => cancel()}
									className="btn btn-light me-3"
									data-kt-users-modal-action="cancel"
									disabled={formik2.isSubmitting || isUserLoading}
								>
									取消
								</button>

								<button
									type="submit"
									className="btn btn-primary"
									data-kt-users-modal-action="submit"
									disabled={
										isUserLoading ||
										formik2.isSubmitting ||
										!formik2.isValid ||
										!formik2.touched
									}
									data-kt-indicator={
										formik2.isSubmitting || isUserLoading ? 'on' : 'off'
									}
								>
									<span className="indicator-label">确定</span>
									{(formik2.isSubmitting || isUserLoading) && (
										<span className="indicator-progress">
											等待中...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</button>
							</div>
							{/* end::Actions */}
						</form>
					</div>
					<div
						className={`tab-pane fade ${tabActive == 3 ? 'show active' : ''}`}
					>
						<form
							id="kt_modal_add_user_form"
							className="form modify-form"
							onSubmit={formik3.handleSubmit}
							noValidate
						>
							{/* begin::Input group */}
							<div className="fv-row mb-7 ms-1">
								{/* begin::Label */}
								<label
									className="require fw-bold fs-6 mb-2 justify-content-between"
									style={{ width: '100%', display: 'flex' }}
								>
									所属代理
								</label>
								{/* end::Label */}
								<p className="text-gray-400">
									点击选择代理，搜索代理名称或手机号
								</p>
							</div>
							<Select
								showSearch
								allowClear
								defaultValue={defaultAgentValue}
								key={defaultAgentValue}
								style={{ width: '100%' }}
								placeholder="请输入代理名称或手机号"
								onChange={handleChangeAgent}
								onSearch={onSearch}
								options={options}
								name="parent_user_id"
								dropdownStyle={{ zIndex: 1099 }}
								filterOption={(input, option) =>
									(option?.label ?? '').includes(input)
								}
							/>
							{/* {user.user_type.indexOf('TRADER') > -1 &&
								!agentId &&
								tabActive == 3 && (
									<p style={{ color: '#e74c3c', margin: '15px 0 0 5px' }}>
										代理商不能为空
									</p>
								)} */}
							{/* end::Input group */}
							{/* begin::Actions */}
							<div className="text-center pt-10">
								<button
									type="reset"
									onClick={() => cancel()}
									className="btn btn-light me-3"
									data-kt-users-modal-action="cancel"
									disabled={formik3.isSubmitting || isUserLoading}
								>
									取消
								</button>

								<button
									type="submit"
									className="btn btn-primary"
									data-kt-users-modal-action="submit"
									disabled={
										isUserLoading ||
										formik3.isSubmitting ||
										!formik3.isValid ||
										!formik3.touched ||
										(user.user_type.indexOf('TRADER') > -1 && !agentId)
									}
									data-kt-indicator={
										formik3.isSubmitting || isUserLoading ? 'on' : 'off'
									}
								>
									<span className="indicator-label">确定</span>
									{(formik3.isSubmitting || isUserLoading) && (
										<span className="indicator-progress">
											等待中...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</button>
							</div>
							{/* end::Actions */}
						</form>
					</div>
					<div
						className={`tab-pane fade ${
							tabActive == 4 || tabActive == 7 || tabActive == 8
								? 'show active'
								: ''
						}`}
					>
						{tabActive != 7 && (
							<ConfigProvider
								theme={{
									algorithm:
										themeMode === 'light'
											? theme.lightAlgorithm
											: theme.darkAlgorithm,
								}}
							>
								<Tabs
									defaultActiveKey="transaction"
									activeKey={activeSettingTab}
									items={settingTabItems}
									onChange={doSettingTabChange}
								/>
							</ConfigProvider>
						)}
						<form
							id="kt_modal_add_user_form"
							className="form modify-form"
							onSubmit={formik4.handleSubmit}
							noValidate
						>
							{!loading &&
								Object.keys(setting).length == 0 &&
								!settingTable && (
									<>
										<div className="d-flex justify-content-center">
											<img
												src="/media/illustrations/sketchy-1/5.png"
												className="svg-icon-2x svg-icon-primary me-4"
												style={{ width: 160, height: 200 }}
											/>
										</div>
										<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed px-6 py-3">
											<KTSVG
												path="/media/icons/duotune/coding/cod009.svg"
												className="svg-icon-2tx svg-icon-primary me-4"
											/>
											<div className="d-flex flex-stack flex-grow-1">
												<div className="fw-bold">
													<h4 className="text-gray-800">
														该用户暂无特殊交易设置
													</h4>
													<div className="fs-6 text-gray-600">
														如需特殊设置，可点击右侧新增设置按钮
													</div>
												</div>
												<div
													className="btn btn-primary"
													onClick={() => setSettingTable(true)}
												>
													新增设置
												</div>
											</div>
										</div>
									</>
								)}
							{!loading && Object.keys(setting).length > 0 && !settingTable && (
								<>
									<div className="card shadow-sm badge-light p-3 mb-5">
										<h4>已设置内容</h4>
										<BaseForm
											formData={setting}
											settingType={activeSettingTab.toUpperCase()}
											settingName="交易设置"
											apiUrl={`/users/setting/${user.user_id}`}
											tabActive={Number(tabActive)}
											// tableAction={action}
											// getSettings={getSettings}
										/>
										{/* {selectedRows.map((item, index) => {
											return (
												<div key={index}>
													<div className="fs-6 d-flex justify-content-between my-4 flex-direction-row">
														<div className="fw-semibold">{item.field}</div>
														<span className="fw-bold">
															{item.key == 'transaction_commission'
																? `${item.userSetting}%`
																: item.key == 'max_lever'
																? `${item.userSetting}X`
																: enumText[item.key][item.userSetting]}
														</span>
													</div>
													<div className="separator separator-dashed"></div>
												</div>
											);
										})} */}
									</div>
									<div className="notice d-flex bg-light-primary align-items-center rounded border-primary border border-dashed px-6 py-3">
										<KTSVG
											path="/media/icons/duotune/coding/cod009.svg"
											className="svg-icon-2tx svg-icon-primary me-4"
										/>
										<div className="d-flex flex-stack flex-grow-1">
											<div className="fw-bold">
												<h4 className="fs-6 text-gray-600">
													如需调整已设置内容，可点击调整用户设置按钮
												</h4>
												<div className="fs-6 text-gray-600">
													如需清空用户设置，可点击清空设置按钮
												</div>
											</div>
											<div className="d-flex flex-column">
												<div
													className="btn btn-primary mb-1"
													onClick={() => setSettingTable(true)}
													style={{ padding: '5px 24px' }}
												>
													调整用户设置
												</div>
												<div
													className="btn btn-success"
													onClick={() => setResetSetting(true)}
													style={{ padding: '5px 10px' }}
												>
													清空用户设置
												</div>
											</div>
										</div>
									</div>
								</>
							)}
							{loading && (
								<Spin
									style={{
										padding: 60,
										width: '100%',
										textAlign: 'center',
									}}
								/>
							)}
							{settingTable && (
								<div className="card shadow-sm badge-light p-3">
									<div className="d-flex justify-content-between align-items-center mb-3">
										<h4>选择设置内容</h4>
										{/* {Object.keys(setting).length > 0 && (
											<div
												className="btn btn-success"
												onClick={() => setResetSetting(true)}
											>
												清除用户设置
											</div>
										)} */}
									</div>

									<Table
										rowSelection={{
											...rowSelection,
											defaultSelectedRowKeys,
										}}
										columns={columns}
										dataSource={settingTableData}
										pagination={false}
									/>
								</div>
							)}
							{selectedRows.length == 0 && settingTable && (
								<Tips tip="点击字段前的勾选框，选择需要特殊设置的字段" />
							)}
							{selectedRows.length > 0 && settingTable && (
								<Tips tip="若想取消用户设置，可将字段前的勾选取消" />
							)}
							{repeatValueRef.current.length > 0 && (
								<Tips
									tip={`${repeatValueRef.current.join(
										'、'
									)}设置与平台设置一致，无需设置，请取消勾选`}
								/>
							)}
							{/* begin::Actions */}
							{settingTable ? (
								<div className="text-center pt-10">
									<button
										type="reset"
										onClick={() => cancel()}
										className="btn btn-light me-3"
										data-kt-users-modal-action="cancel"
									>
										取消
									</button>
									<button
										type="submit"
										className="btn btn-primary"
										data-kt-users-modal-action="submit"
										disabled={
											selectedRows?.length == 0 ||
											repeatValueRef.current.length > 0 ||
											// 选择交易佣金并没有输入值的时候确定按钮不可点击
											(findIndex(selectedRows, {
												key: 'transaction_commission',
											}) > -1 &&
												transactionCom == null)
										}
										data-kt-indicator={
											formik4.isSubmitting || isUserLoading ? 'on' : 'off'
										}
									>
										<span className="indicator-label">确定</span>
										{(formik4.isSubmitting || isUserLoading) && (
											<span className="indicator-progress">
												等待中...{' '}
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
										)}
									</button>
								</div>
							) : (
								<div className="text-center pt-10">
									<button
										type="reset"
										onClick={() => cancel()}
										className="btn btn-light me-3"
										data-kt-users-modal-action="cancel"
									>
										取消
									</button>
								</div>
							)}
							{/* end::Actions */}
						</form>
					</div>
					<div
						className={`tab-pane fade ${tabActive == 5 ? 'show active' : ''}`}
					>
						{/* {!mfa && ( */}
						<Tips
							tip={tipsContent()}
							path="/media/icons/duotune/general/gen044.svg"
							colorClass="bg-light-warning border-warning"
						/>
						{/* )} */}
						<form
							id="kt_modal_add_user_form"
							className="form modify-form mt-5"
							onSubmit={formik5.handleSubmit}
							noValidate
						>
							{/* begin::Input group */}
							<div className="fv-row mb-7 ms-1">
								{/* begin::Label */}
								<label className="required fw-bold fs-6 mb-2">交易密码</label>
								<input
									placeholder="交易密码"
									{...formik5.getFieldProps('fund_password1')}
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{
											'is-invalid':
												formik5.touched.fund_password1 &&
												formik5.errors.fund_password1,
										},
										{
											'is-valid':
												formik5.touched.fund_password1 &&
												!formik5.errors.fund_password1,
										}
									)}
									type="text"
									name="fund_password1"
									autoComplete="off"
									disabled={formik5.isSubmitting || isUserLoading}
									onChange={(e) => {
										formik5.setFieldValue('fund_password1', e.target.value);
										e.target.value && onChangeData();
									}}
								/>
								{/* end::Input */}
								{/* {formik.touched.fund_password1 && formik.errors.fund_password1 && (
										<div className="fv-plugins-message-container">
											<span role="alert">{formik.errors.fund_password1}</span>
										</div>
									)} */}
								<p
									className={`text-gray-400 mt-3 ${
										formik5.touched.fund_password1 &&
										formik5.errors.fund_password1
											? 'fv-plugins-message-container'
											: ''
									}`}
								>
									<span className="fv-help-block">6位数字</span>
								</p>
							</div>
							<div className="fv-row mb-7 ms-1">
								{/* begin::Label */}
								<label className="required fw-bold fs-6 mb-2">
									确认交易密码
								</label>
								<input
									placeholder="确认交易密码"
									{...formik5.getFieldProps('fund_password2')}
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{
											'is-invalid':
												formik5.touched.fund_password2 &&
												formik5.errors.fund_password2,
										},
										{
											'is-valid':
												formik5.touched.fund_password2 &&
												!formik5.errors.fund_password2,
										}
									)}
									type="text"
									name="fund_password2"
									autoComplete="off"
									disabled={formik5.isSubmitting || isUserLoading}
									onChange={(e) => {
										formik5.setFieldValue('fund_password2', e.target.value);
										e.target.value && onChangeData();
									}}
								/>
								{formik5.touched.fund_password2 &&
									formik5.errors.fund_password2 && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">
													{formik5.errors.fund_password2}
												</span>
											</div>
										</div>
									)}
							</div>
							<div className="fv-row mb-7 ms-1">
								{/* begin::Label */}
								<label className="required fw-bold fs-6 mb-2">
									Google验证码
								</label>
								<input
									placeholder="Google验证码"
									{...formik5.getFieldProps('mfa_code')}
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{
											'is-invalid':
												formik5.touched.mfa_code && formik5.errors.mfa_code,
										},
										{
											'is-valid':
												formik5.touched.mfa_code && !formik5.errors.mfa_code,
										}
									)}
									type="text"
									name="mfa_code"
									autoComplete="off"
									disabled={formik5.isSubmitting || isUserLoading}
									onChange={(e) => {
										formik5.setFieldValue('mfa_code', e.target.value);
										e.target.value && onChangeData();
									}}
								/>
								{formik5.touched.mfa_code && formik5.errors.mfa_code && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik5.errors.mfa_code}</span>
										</div>
									</div>
								)}
							</div>
							<div className="text-center pt-10">
								<button
									type="reset"
									onClick={() => cancel()}
									className="btn btn-light me-3"
									data-kt-users-modal-action="cancel"
									disabled={formik5.isSubmitting || isUserLoading}
								>
									取消
								</button>
								<button
									type="submit"
									className="btn btn-primary"
									data-kt-users-modal-action="submit"
									disabled={
										isUserLoading ||
										formik5.isSubmitting ||
										!formik5.isValid ||
										!formik5.touched ||
										!mfa
									}
									data-kt-indicator={
										formik5.isSubmitting || isUserLoading ? 'on' : 'off'
									}
								>
									<span className="indicator-label">确定</span>
									{(formik5.isSubmitting || isUserLoading) && (
										<span className="indicator-progress">
											等待中...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</button>
							</div>
						</form>
					</div>
					<div
						className={`tab-pane fade ${tabActive == 6 ? 'show active' : ''}`}
					>
						<form
							id="kt_modal_add_user_form"
							className="form modify-form"
							onSubmit={formik6.handleSubmit}
							noValidate
						>
							<div className="fv-row mb-7 ms-1">
								<label className="required fw-bold fs-6 mb-2">
									用户真实姓名
								</label>
								<input
									placeholder="用户真实姓名"
									{...formik6.getFieldProps('full_name')}
									type="number"
									name="full_name"
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{
											'is-invalid':
												formik6.touched.full_name && formik6.errors.full_name,
										},
										{
											'is-valid':
												formik6.touched.full_name && !formik6.errors.full_name,
										}
									)}
									autoComplete="off"
									disabled={formik6.isSubmitting || isUserLoading}
									onChange={(e) => {
										formik6.setFieldValue('full_name', e.target.value);
										e.target.value && onChangeData();
									}}
								/>
								{formik6.touched.full_name && formik6.errors.full_name && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik6.errors.full_name}</span>
										</div>
									</div>
								)}
							</div>
							<div className="fv-row mb-7 ms-1">
								<label className="required fw-bold fs-6 mb-2">身份证号</label>
								<input
									placeholder="身份证号"
									{...formik6.getFieldProps('id_no')}
									type="number"
									name="id_no"
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{
											'is-invalid':
												formik6.touched.id_no && formik6.errors.id_no,
										},
										{
											'is-valid':
												formik6.touched.id_no && !formik6.errors.id_no,
										}
									)}
									autoComplete="off"
									disabled={formik6.isSubmitting || isUserLoading}
									onChange={(e) => {
										formik6.setFieldValue('id_no', e.target.value);
										e.target.value && onChangeData();
									}}
								/>
								{formik6.touched.id_no && formik6.errors.id_no && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik6.errors.id_no}</span>
										</div>
									</div>
								)}
							</div>
							<div className="row">
								<div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
									<label className="required fw-bold fs-6 mb-2">
										身份证人像面
									</label>
									<div>
										<Uploader
											handleImageChange={(value) => {
												setIdFrontPhoto(value);
											}}
											initialImage={idFrontPhoto}
										/>
									</div>
								</div>
								<div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
									<label className="required fw-bold fs-6 mb-2">
										身份证国徽面
									</label>
									<div>
										<Uploader
											handleImageChange={(value) => {
												setIdOppositePhoto(value);
											}}
											initialImage={idOppositePhoto}
										/>
									</div>
								</div>
								<div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
									<label className="required fw-bold fs-6 mb-2">
										手持身份证人像照
									</label>
									<div>
										<Uploader
											handleImageChange={(value) => {
												setIdHoldPhoto(value);
											}}
											initialImage={idHoldPhoto}
										/>
									</div>
								</div>
							</div>

							<div className="text-center pt-10">
								<button
									type="reset"
									onClick={() => cancel()}
									className="btn btn-light me-3"
									data-kt-users-modal-action="cancel"
									disabled={formik6.isSubmitting || isUserLoading}
								>
									取消
								</button>

								<button
									type="submit"
									className="btn btn-primary"
									data-kt-users-modal-action="submit"
									disabled={
										isUserLoading ||
										formik6.isSubmitting ||
										!formik6.isValid ||
										!formik6.touched ||
										idFrontPhoto == '/media/default/default_cover.png' ||
										idOppositePhoto == '/media/default/default_cover.png' ||
										idHoldPhoto == '/media/default/default_cover.png'
									}
									data-kt-indicator={
										formik6.isSubmitting || isUserLoading ? 'on' : 'off'
									}
								>
									<span className="indicator-label">确定</span>
									{(formik6.isSubmitting || isUserLoading) && (
										<span className="indicator-progress">
											等待中...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			{resetSetting && (
				<ResetSetting
					setShowModal={setResetSetting}
					data={userMsg}
					setSettingTable={setSettingTable}
					getSetting={getSetting}
					action={action}
					type={activeSettingTab}
					tabActive={Number(tabActive)}
				/>
			)}
			{sureSetting && (
				<SureSetting
					setShowModal={setShowModal}
					setSureSetting={setSureSetting}
					user={userMsg}
					selectedRows={selectedRows}
					action={action}
				/>
			)}
			{isCancel && hasChange && (
				<BeforeCancel setShowModal={setShowModal} setIsCancel={setIsCancel} />
			)}
			{/* end::Scroll */}
			{/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
		</>
	);
};
UserModifyModalForm.propTypes = {
	isSearch: PropTypes.bool,
	setShowModal: PropTypes.func,
	user: PropTypes.object,
	isUserLoading: PropTypes.bool,
	action: PropTypes.object,
};
export { UserModifyModalForm };
