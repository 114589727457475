import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';

export const globalSlice = createSlice({
	name: 'global',
	initialState: {
		token: '',
		refreshToken: '',
		userId: '',
		mfa: ls.get('X-Auth-Mfa') ? ls.get('X-Auth-Mfa') : null,
		referralCode: '',
		userInfo: ls.get('X-Auth-User-Info') || {},
		agentUserId: '',
		drawerAgentList: [],
		themeMode: ls.get('kt_theme_mode_value') || 'light',
		tableBreakPoint: 'normal',
		appPreviewOnly: false,
		notificationUnreadCount: 0,
	},
	reducers: {
		setToken: (state, action) => {
			const { token } = action.payload;
			ls.set('X-Auth-Token', token);
			return {
				...state,
				token,
			};
		},

		setRefreshToken: (state, action) => {
			const { refreshToken } = action.payload;
			ls.set('X-Auth-Refresh-Token', refreshToken);
			return {
				...state,
				refreshToken,
			};
		},

		setUserId: (state, action) => {
			const { userId } = action.payload;
			ls.set('X-Auth-User-Id', userId);
			return {
				...state,
				userId,
			};
		},

		setAgentUserId: (state, action) => {
			return {
				...state,
				agentUserId: action.payload.uId,
			};
		},

		setDrawerAgentList: (state, action) => {
			return {
				...state,
				drawerAgentList: action.payload.drawerAgentList,
			};
		},

		setMfa: (state, action) => {
			const { mfa } = action.payload;
			ls.set('X-Auth-Mfa', mfa);
			return {
				...state,
				mfa,
			};
		},

		setReferralCode: (state, action) => {
			return {
				...state,
				referralCode: action.payload.referralCode,
			};
		},

		setUserInfo: (state, action) => {
			const { userInfo } = action.payload;
			ls.set('X-Auth-User-Info', userInfo);
			return {
				...state,
				userInfo,
			};
		},

		setThemeMode: (state, action) => {
			return {
				...state,
				themeMode: action.payload.theme,
			};
		},

		setTableBreakPoint: (state, action) => {
			return {
				...state,
				tableBreakPoint: action.payload.status,
			};
		},

		setAppPreviewOnly: (state, action) => {
			return {
				...state,
				appPreviewOnly: action.payload,
			};
		},

		setNotificationUnreadCount: (state, action) => {
			return {
				...state,
				notificationUnreadCount: action.payload,
			};
		},
	},
});

export const {
	setToken,
	setRefreshToken,
	setUserId,
	setAgentUserId,
	setDrawerAgentList,
	setMfa,
	setReferralCode,
	setUserInfo,
	setThemeMode,
	setTableBreakPoint,
	setAppPreviewOnly,
	setNotificationUnreadCount,
} = globalSlice.actions;

export default globalSlice.reducer;
