/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { KTSVG } from '../../../../_metronic/helpers';

const ResetModal = (props) => {
	const { settingName, setIsResetModal, resetFormSure } = props;
	useEffect(() => {
		document.body.classList.add('modal-open');
		return () => {
			document.body.classList.remove('modal-open');
		};
	}, []);
	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_add_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-500px">
					{/* begin::Modal content */}
					<div className="modal-content">
						{/* begin::Modal body */}
						<div
							className="modal-body flex-column mx-3 mx-xl-5 my-7"
							style={{ textAlign: 'center' }}
						>
							{/* end::Input group */}
							<KTSVG
								path="/media/icons/duotune/general/gen044.svg"
								className="svg-icon-3tx svg-icon-warning me-4"
							/>
							<div className="d-flex flex-column mt-3">
								<p className="text-gray-800">确定重置 {settingName}？</p>
								<p className="text-muted">重置后，将恢复成默认值，可重新设置</p>
							</div>
						</div>
						<div className="text-center mb-10">
							<button
								type="reset"
								onClick={() => setIsResetModal(false)}
								className="btn btn-light me-3"
								data-kt-users-modal-action="cancel"
							>
								取消
							</button>

							<button
								type="submit"
								className="btn btn-primary"
								data-kt-users-modal-action="submit"
								onClick={resetFormSure}
							>
								<span className="indicator-label">确定</span>
							</button>
						</div>
						{/* end::Modal body */}
					</div>
					{/* end::Modal content */}
				</div>
				{/* end::Modal dialog */}
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};
ResetModal.propTypes = {
	settingName: PropTypes.string,
	setIsResetModal: PropTypes.func,
	resetFormSure: PropTypes.func,
};
export default ResetModal;
