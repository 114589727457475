/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { KTSVG } from '../../../../_metronic/helpers';
import PropTypes from 'prop-types';
import { initAmountSummary } from '../../../modules/apps/user-management/users-list/core/_models';
import clsx from 'clsx';
import { UsersListLoading } from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading';
import { SureFundsModifyModalForm } from './SureFundsModifyModalForm';
import request from '../../../../utils/request';

import './index.scss';

// type Props = {
// 	isUserLoading: boolean;
// 	user: User;
// 	setShowModal: any;
// };

const editUserSchema = Yup.object().shape({
	amount: Yup.string()
		.required('调账金额是必填项')
		.matches(/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/, '请输入大于0的数字'),
});

const FundsModifyModalForm = ({
	user,
	setShowModal,
	action,
	isUserLoading,
}) => {
	const [userForEdit] = useState({
		amount: initAmountSummary.amount,
	});
	const [summaryMsg, setSummaryMsg] = useState({
		total_assets: undefined,
		available: undefined,
		frozen: undefined,
	});
	const [direction, setDirection] = useState('add');
	const [sureModal, setSureModal] = useState(false);
	const [userMsg, setUserMsg] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	// const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
	// const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

	const formik = useFormik({
		initialValues: userForEdit,
		validationSchema: editUserSchema,
		onSubmit: () => {
			if (direction === 'reduce') {
				if (formik.getFieldProps('amount').value > summaryMsg.available) {
					setIsDisabled(true);
					return;
				} else {
					setIsDisabled(false);
				}
			}
			setSureModal(true);
		},
	});
	const getAccountSummary = async () => {
		const resp = await request.get(`/account/${user.user_id}/summary`);
		setSummaryMsg(resp);
	};
	const getUserInfo = async () => {
		const resp = await request.get(`/users/${user.user_id}`);
		setUserMsg(resp);
	};
	useEffect(() => {
		getAccountSummary();
		getUserInfo();
	}, []);
	return (
		<>
			<form
				id="kt_modal_funds_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				{/* begin::Scroll */}
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_funds_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					{/* begin::Input group */}
					{/* <div className='fv-row mb-7'> */}
					{/* begin::Label */}
					{/* <label className='d-block fw-bold fs-6 mb-5'>Avatar</label> */}
					{/* end::Label */}

					{/* begin::Image input */}
					{/* <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: `url('${blankImg}')`}}
            > */}
					{/* begin::Preview existing avatar */}
					{/* <div
                className='image-input-wrapper w-125px h-125px'
                style={{backgroundImage: `url('${userAvatarImg}')`}}
              ></div> */}
					{/* end::Preview existing avatar */}

					{/* begin::Label */}
					{/* <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='bi bi-pencil-fill fs-7'></i>

              <input type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label> */}
					{/* end::Label */}

					{/* begin::Cancel */}
					{/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
					{/* end::Cancel */}

					{/* begin::Remove */}
					{/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Remove avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
					{/* end::Remove */}
					{/* </div> */}
					{/* end::Image input */}

					{/* begin::Hint */}
					{/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
					{/* end::Hint */}
					{/* </div> */}
					{/* end::Input group */}
					<div className="fv-row mb-7">
						<div className="card shadow-sm badge-light p-3">
							<h5>{user.full_name}</h5>
							<div className="flex" style={{ lineHeight: '17px' }}>
								<span className="flex">
									<KTSVG path="/media/icons/duotune/electronics/elc002.svg" />
									<span className="ms-1">{user.phone}</span>
								</span>
								<span className="flex ms-3">
									<KTSVG path="/media/icons/duotune/communication/com006.svg" />
									<span className="ms-1">
										{user.user_type === 'TRADER'
											? '普通用户'
											: user.user_type === 'AGENT'
											? '代理'
											: '管理员'}
									</span>
								</span>
								{(userMsg.parent_username || userMsg.parent_phone) && (
									<span className="flex ms-3">
										<KTSVG path="/media/icons/duotune/communication/com014.svg" />
										<span className="ms-1">
											{`${userMsg.parent_username}(${
												userMsg.parent_phone || '无'
											})`}
										</span>
									</span>
								)}
								<span className="flex ms-3">
									<KTSVG path="/media/icons/duotune/general/gen047.svg" />
									<span
										className="ms-1"
										style={{
											color: `${userMsg.is_blocked ? '#ff4d4f' : '#50cd89'}`,
										}}
									>
										{userMsg.is_blocked ? '锁定' : '正常'}
									</span>
								</span>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column flex-grow-1 align-items-center">
						<div className="d-flex flex-wrap">
							<div className="border border-gray-300 border-dashed rounded min-w-175px py-3 px-4 me-3 mb-3">
								<div className="d-flex align-items-center">
									<div className="fs-2 fw-bolder">
										{summaryMsg.total_assets?.toLocaleString()}
									</div>
								</div>

								<div className="fw-bold fs-6 text-gray-400 d-flex justify-content-between">
									总资金
									<span
										className={`${
											direction === 'reduce' ? 'minus-data' : 'add-data'
										}`}
										style={{ fontSize: '12' }}
									>
										{formik.getFieldProps('amount').value
											? `${direction === 'reduce' ? '-' : '+'}${formik
													.getFieldProps('amount')
													.value.toLocaleString()}`
											: ''}
									</span>
								</div>
							</div>

							<div className="border border-gray-300 border-dashed rounded min-w-175px py-3 px-4 me-3 mb-3">
								<div className="d-flex align-items-center">
									<div className="fs-2 fw-bolder">
										{summaryMsg.available?.toLocaleString()}
									</div>
								</div>

								<div className="fw-bold fs-6 text-gray-400 d-flex justify-content-between">
									可用资金
									<span
										className={`${
											direction === 'reduce' ? 'minus-data' : 'add-data'
										}`}
										style={{ fontSize: '12' }}
									>
										{formik.getFieldProps('amount').value
											? `${direction === 'reduce' ? '-' : '+'}${formik
													.getFieldProps('amount')
													.value.toLocaleString()}`
											: ''}
									</span>
								</div>
							</div>

							<div className="border border-gray-300 border-dashed rounded min-w-175px py-3 px-4 me-3 mb-3">
								<div className="d-flex align-items-center">
									<div className="fs-2 fw-bolder">
										{summaryMsg.frozen?.toLocaleString()}
									</div>
								</div>

								<div className="fw-bold fs-6 text-gray-400">冻结资金</div>
							</div>
						</div>
					</div>
					{/* begin::Input group */}
					<div className="mb-5">
						{/* begin::Label */}
						<label className="required fw-bold fs-6 mb-5">调整方向</label>
						{/* end::Label */}
						{/* begin::Roles */}
						{/* begin::Input row */}
						<div className="d-flex fv-row">
							{/* begin::Radio */}
							<div className="form-check form-check-custom form-check-solid me-10">
								{/* begin::Input */}
								<input
									className="form-check-input me-3"
									name="role"
									type="radio"
									value="Administrator"
									id="kt_modal_update_role_option_0"
									onChange={() => setDirection('add')}
									checked={direction === 'add'}
								/>

								{/* end::Input */}
								{/* begin::Label */}
								<label
									className="form-check-label"
									htmlFor="kt_modal_update_role_option_0"
								>
									增加可用资金
								</label>
								{/* end::Label */}
							</div>
							{/* end::Radio */}
							{/* begin::Radio */}
							<div className="form-check form-check-custom form-check-solid">
								{/* begin::Input */}
								<input
									className="form-check-input me-3"
									name="role"
									type="radio"
									value="Administrator"
									id="kt_modal_update_role_option_0"
									onChange={() => setDirection('reduce')}
									checked={direction === 'reduce'}
								/>

								{/* end::Input */}
								{/* begin::Label */}
								<label
									className="form-check-label"
									htmlFor="kt_modal_update_role_option_0"
								>
									减少可用资金
								</label>
								{/* end::Label */}
							</div>
							{/* end::Radio */}
						</div>
						{/* end::Input row */}
						{/* <div className='separator separator-dashed my-5'></div> */}

						{/* end::Roles */}
					</div>
					{/* end::Input group */}
					{/* begin::Input group */}
					<div className="fv-row mb-5">
						{/* begin::Label */}
						<label className="required fw-bold fs-6 mb-2">
							{direction === 'reduce' ? '减少' : '增加'}金额
						</label>
						{/* end::Label */}

						{/* begin::Input */}
						<input
							placeholder={direction === 'reduce' ? '减少金额' : '增加金额'}
							{...formik.getFieldProps('amount')}
							type="number"
							name="amount"
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0 ms-1',
								{ 'is-invalid': formik.touched.amount && formik.errors.amount },
								{
									'is-valid': formik.touched.amount && !formik.errors.amount,
								}
							)}
							autoComplete="off"
							// disabled={formik.isSubmitting || isUserLoading}
						/>
						{formik.touched.amount && formik.errors.amount && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.amount}</span>
								</div>
							</div>
						)}
						{isDisabled && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">新可用资金最低为0元，不可为负值！</span>
								</div>
							</div>
						)}
						{/* end::Input */}
					</div>
					{/* end::Input group */}
					<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed px-6 py-3">
						<KTSVG
							path="/media/icons/duotune/general/gen044.svg"
							className="svg-icon-2tx svg-icon-warning me-4"
						/>
						<div className="d-flex flex-stack flex-grow-1">
							<div className="fw-bold">
								<h4 className="text-gray-800">
									选择调整类型：增加或减少该用户的可用资金
								</h4>
								<div className="fs-6 text-gray-600">
									调整后，该用户的可用资金、总资金都会自动更新
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* end::Scroll */}

				{/* begin::Actions */}
				<div className="text-center pt-10">
					<button
						type="reset"
						onClick={() => setShowModal(false)}
						className="btn btn-light me-3"
						data-kt-users-modal-action="cancel"
					>
						取消
					</button>

					<button
						type="submit"
						className="btn btn-primary"
						data-kt-users-modal-action="submit"
						disabled={
							isUserLoading ||
							// formik.isSubmitting ||
							!formik.isValid ||
							!formik.touched
						}
						// data-kt-indicator={
						// 	formik.isSubmitting || isUserLoading ? 'on' : 'off'
						// }
					>
						<span className="indicator-label">确定</span>
						{/* {(formik.isSubmitting || isUserLoading) && (
							<span className="indicator-progress">
								等待中...{' '}
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)} */}
					</button>
				</div>
				{/* end::Actions */}
			</form>
			{/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
			{sureModal && (
				<SureFundsModifyModalForm
					user={userMsg}
					setSureModal={setSureModal}
					setShowModal={setShowModal}
					available={summaryMsg.available}
					amount={formik.getFieldProps('amount').value}
					direction={direction}
					action={action}
				/>
			)}
		</>
	);
};
FundsModifyModalForm.propTypes = {
	setShowModal: PropTypes.func,
	user: PropTypes.object,
	type: PropTypes.string,
	isUserLoading: PropTypes.bool,
	action: PropTypes.object,
};
export { FundsModifyModalForm };
