import React, { useEffect, useState } from 'react';
import ls from 'local-storage';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import VerificationInput from 'react-verification-input';
import { toast } from 'react-toastify';
import { doLogin, doVerify } from '../core/_requests';
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import {useAuth} from '../core/Auth'
import { useDispatch, useSelector } from 'react-redux';
// import store from '../../../../store/store';
import {
	setToken,
	setRefreshToken,
	setUserId,
	setUserInfo,
	setMfa,
} from '../../../../store/slices/global';
// import eventBus from '../../../../utils/eventBus';

import './login.scss';

const loginSchema = Yup.object().shape({
	phone: Yup.string()
		.required('手机号不能为空')
		.matches(/^[1][3,4,5,6.7,8,9][0-9]{9}$/, '请正确填写手机号'),
	password: Yup.string()
		.min(6, '密码不能少于6位')
		.max(16, '密码不能多于16位')
		.required('密码不能为空'),
});

const initialValues = {
	phone: '',
	password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
	const themeMode = useSelector((state) => state.global.themeMode);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userId = useSelector((state) =>
		state.global.userId === '' ? ls.get('X-Auth-User-Id') : state.global.userId
	);
	const [loading, setLoading] = useState(false);
	// const {saveAuth, setCurrentUser} = useAuth()
	const [isDoubleCheck, setIsDoubleCheck] = useState(false);
	const [isDoubleCheckValidate, setIsDoubleCheckValidate] = useState(false);
	const [loginData, setLoginData] = useState({});

	useEffect(() => {
		const modalOverlay = document.querySelector('.drawer-overlay');
		if (modalOverlay) {
			modalOverlay.style.display = 'none';
		}
	}, []);

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values /*{ setStatus, setSubmitting }*/) => {
			setLoading(true);
			try {
				const { mfa_token, token, refresh_token, ...rest } = await doLogin({
					phone: values.phone,
					password: values.password,
				});
				dispatch(setUserId({ userId: rest.user_id }));
				dispatch(setUserInfo({ userInfo: rest }));
				dispatch(setMfa({ mfa: rest.mfa_required }));
				setIsDoubleCheck(rest.mfa_required);
				setLoginData({ mfa_token });
				if (!rest.mfa_required) {
					dispatch(setToken({ token }));
					dispatch(setRefreshToken({ refreshToken: refresh_token }));
					setLoading(false);
					if (rest.user_type === 'ADMINISTRATOR_CUS') {
						window.location.href = '/user-management/user-list';
					} else {
						window.location.href = '/dashboard';
					}
				}
				// saveAuth(auth)
				// const {data: user} = await getUserByToken(auth.api_token)
				// setCurrentUser(user)
			} catch (error) {
				// console.error(error);
				setLoading(false);
				// toast.error('用户尚未注册！');
				// saveAuth(undefined)
				// setStatus('The login details are incorrect')
				// setSubmitting(false)
				// setLoading(false)
			}
		},
	});

	const verify = async (value) => {
		try {
			const resp = await doVerify(userId, {
				...loginData,
				mfa_code: value,
			});
			const { token, refresh_token, user_type, mfa_required } = resp;
			dispatch(setToken({ token }));
			dispatch(setRefreshToken({ refreshToken: refresh_token }));
			dispatch(setMfa({ mfa: mfa_required }));
			if (user_type === 'ADMINISTRATOR_CUS') {
				window.location.href = '/user-management/user-list';
			} else {
				window.location.href = '/dashboard';
			}
		} catch (error) {
			// console.log(error);
			toast.error('验证码输入错误！', {
				onClose: () => {
					// eventBus.dispatch('needToRefresh');
				},
			});
		}
	};

	return (
		<>
			{!isDoubleCheck && (
				<form
					className="form w-100"
					onSubmit={formik.handleSubmit}
					noValidate
					id="kt_login_signin_form"
				>
					{/* begin::Heading */}
					<div className="text-center mb-11">
						<h1 className="text-dark fw-bolder mb-3">登录</h1>
						{/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
					</div>
					{/* begin::Heading */}

					{/* begin::Form group */}
					<div className="fv-row mb-8">
						<label className="form-label fs-6 fw-bolder text-dark">
							手机号
						</label>
						<input
							placeholder="请填写手机号"
							{...formik.getFieldProps('phone')}
							className={clsx(
								'form-control bg-transparent',
								{ 'is-invalid': formik.touched.phone && formik.errors.phone },
								{
									'is-valid': formik.touched.phone && !formik.errors.phone,
								}
							)}
							type="text"
							name="phone"
							autoComplete="off"
							maxLength="11"
						/>
						{formik.touched.phone && formik.errors.phone && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.phone}</span>
								</div>
							</div>
						)}
					</div>
					{/* end::Form group */}

					{/* begin::Form group */}
					<div className="fv-row mb-3">
						<label className="form-label fw-bolder text-dark fs-6 mb-0">
							密码
						</label>
						<input
							type="password"
							placeholder="请填写密码"
							autoComplete="off"
							{...formik.getFieldProps('password')}
							className={clsx(
								'form-control bg-transparent',
								{
									'is-invalid':
										formik.touched.password && formik.errors.password,
								},
								{
									'is-valid':
										formik.touched.password && !formik.errors.password,
								}
							)}
						/>
						{formik.touched.password && formik.errors.password && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.password}</span>
								</div>
							</div>
						)}
					</div>
					{/* end::Form group */}

					{/* begin::Action */}
					<div className="d-grid mb-10 mt-5">
						<button
							type="submit"
							id="kt_sign_in_submit"
							className="btn btn-primary"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{!loading && <span className="indicator-label">登录</span>}
							{loading && (
								<span
									className="indicator-progress"
									style={{ display: 'block' }}
								>
									等待中...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
					{/* end::Action */}
				</form>
			)}

			{/* <!--begin::Wrapper--> */}
			{isDoubleCheck && (
				<div>
					{/* <!--begin::Form--> */}
					<form
						className="form w-100 mb-13"
						noValidate="novalidate"
						id="kt_sing_in_two_factor_form"
					>
						{/* <!--begin::Heading--> */}
						<div className="text-center mb-10">
							{/* <!--begin::Title--> */}
							<h1 className="text-dark mb-3">二次验证</h1>
							{/* <!--end::Title--> */}
							{/* <!--begin::Sub-title--> */}
							<div className="text-muted fw-semibold fs-5 mb-5">
								Google身份验证器
							</div>
							{/* <!--end::Sub-title--> */}
							{/* <!--begin::Mobile no--> */}
							{/* <div className="fw-bold text-dark fs-3">******8180</div> */}
							{/* <!--end::Mobile no--> */}
						</div>
						{/* <!--end::Heading--> */}
						{/* <!--begin::Section--> */}
						<div className="mb-10">
							{/* <!--begin::Label--> */}
							<div className="fw-bold text-start text-dark fs-6 mb-1 ms-1">
								输入6位Google验证码
							</div>
							{/* <!--end::Label--> */}
							{/* <!--begin::Input group--> */}
							<div
								className={`verification-container ${
									themeMode === 'light' ? '' : 'verification-container--dark'
								}`}
							>
								<VerificationInput
									validChars="0-9"
									inputProps={{ inputMode: 'numeric' }}
									placeholder=""
									classNames={{
										container: 'container',
										character: 'character',
										characterInactive: 'character--inactive',
										characterSelected: 'character--selected',
									}}
									onChange={() => {
										setIsDoubleCheckValidate(false);
									}}
									onComplete={(value) => {
										setIsDoubleCheckValidate(true);
										verify(value);
									}}
								/>
							</div>
							{/* <!--begin::Input group--> */}
						</div>
						{/* <!--end::Section--> */}
						{/* <!--begin::Submit--> */}
						<div className="d-grid mb-10 mt-5">
							<button
								type="button"
								id="kt_sing_in_two_factor_submit"
								className="btn btn-lg btn-primary fw-bold"
								disabled={!isDoubleCheckValidate}
							>
								<span className="indicator-label">登录</span>
								<span className="indicator-progress">
									等待中...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							</button>
						</div>
						{/* <!--end::Submit--> */}
					</form>
					{/* <!--end::Form--> */}
					{/* <!--begin::Notice--> */}
					{/* <div className="text-center fw-semibold fs-5">
            <span className="text-muted me-1">没有收到验证码 ?</span>
            <a href="#" className="link-primary fs-5 me-1">重新发送</a>
          </div> */}
					{/* <!--end::Notice--> */}
				</div>
			)}
			{/* <!--end::Wrapper--> */}
		</>
	);
}
