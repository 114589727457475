/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { Tag, Space } from 'antd';
import { UserModifyModal } from './user-modify-modal/UserModifyModal';
import request from '../../../utils/request';
import _ from 'lodash';

const userRoleEnum = [
	{
		label: '超级管理员',
		value: 'ADMINISTRATOR',
	},
	{
		label: '系统管理员',
		value: 'ADMINISTRATOR_SYS',
	},
	{
		label: '客服管理员',
		value: 'ADMINISTRATOR_CUS',
	},
];
const AdminDetailModal = (props) => {
	const { setShowModal, user, detailOpentModify } = props;
	const [userDetail, setUserDetail] = useState({});
	const getUserInfo = async () => {
		const resp = await request.get(`/users/${user.user_id}`);
		setUserDetail(resp);
	};
	useEffect(() => {
		getUserInfo();
		document.body.classList.add('modal-open');
		return () => {
			document.body.classList.remove('modal-open');
		};
	}, []);
	const updateAdmin = () => {
		setShowModal(false);
		detailOpentModify();
	};
	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_add_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-700px">
					{/* begin::Modal content */}
					<div className="modal-content">
						<div className="modal-header">
							{/* begin::Modal title */}
							<h2 className="fw-bolder">管理员详情</h2>
							{/* end::Modal title */}

							{/* begin::Close */}
							<div
								className="btn btn-icon btn-sm btn-active-icon-primary"
								data-kt-users-modal-action="close"
								onClick={() => {
									setShowModal(false);
								}}
								style={{ cursor: 'pointer' }}
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon-1"
								/>
							</div>
							{/* end::Close */}
						</div>
						{/* begin::Modal body */}
						<div className="modal-body mx-3 mx-xl-5">
							<div className="card shadow-sm badge-light p-3">
								<h5>{userDetail.username}</h5>
								<div className="flex" style={{ lineHeight: '17px' }}>
									<span className="flex">
										<KTSVG path="/media/icons/duotune/electronics/elc002.svg" />
										<span className="ms-1">{userDetail.phone}</span>
									</span>
									<span className="flex ms-3">
										<KTSVG path="/media/icons/duotune/communication/com006.svg" />
										<span className="ms-1">
											{
												_.find(userRoleEnum, { value: userDetail.user_type })
													?.label
											}
										</span>
									</span>
									{(userDetail.parent_username || userDetail.parent_phone) && (
										<span className="flex ms-3">
											<KTSVG path="/media/icons/duotune/communication/com014.svg" />
											<span className="ms-1">
												{`${userDetail.parent_username}(${
													userDetail.parent_phone || '无'
												})`}
											</span>
										</span>
									)}
									<span className="flex ms-3">
										<KTSVG path="/media/icons/duotune/general/gen047.svg" />
										<span
											className="ms-1"
											style={{
												color: `${
													userDetail.is_blocked ? '#ff4d4f' : '#50cd89'
												}`,
											}}
										>
											{userDetail.is_blocked ? '锁定' : '正常'}
										</span>
									</span>
								</div>
							</div>
						</div>
						<div className="card shadow-sm badge-light mx-12 p-3 mt-3">
							<div className="card-header" style={{ minHeight: '40px' }}>
								<h3 className="card-title" style={{ fontSize: '16px' }}>
									登录信息
								</h3>
							</div>
							<div className="fs-6 d-flex px-8 justify-content-between">
								<div>
									<div className="fw-bold mt-5">最后登录IP</div>
									<div className="text-gray-600">
										{userDetail.last_login_ip}(
										{userDetail.last_login_region || '无'})
									</div>
								</div>
								<div>
									<div className="fw-bold mt-5">最后登录时间</div>
									<div className="text-gray-600">
										{userDetail.last_login_time}
									</div>
								</div>
							</div>
						</div>
						<div className="card shadow-sm badge-light mx-12 p-3 my-3">
							<div className="card-header" style={{ minHeight: '40px' }}>
								<h3 className="card-title" style={{ fontSize: '16px' }}>
									角色类型
								</h3>
							</div>
							<div className="fs-6 d-flex mt-3">
								<Space size={[0, 8]} wrap className="mx-9">
									<Tag>
										{
											userRoleEnum.filter(
												(item) => item.value == userDetail.user_type
											)[0]?.label
										}
									</Tag>
								</Space>
							</div>
						</div>
						<div className="text-center mb-10 mt-6">
							<button
								type="submit"
								className="btn btn-primary"
								data-kt-users-modal-action="submit"
								onClick={updateAdmin}
							>
								<span className="indicator-label">修改管理员</span>
							</button>
						</div>
						{/* end::Modal body */}
					</div>
					{/* end::Modal content */}
				</div>
				{/* end::Modal dialog */}
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};

export { AdminDetailModal };
