import React from 'react';
import pako from 'pako';
import { toast } from 'react-toastify';
import store from '../store/store';
import { setNotificationUnreadCount } from '../store/slices/global';
import { getNotificationSummary } from './service';

let socket;

const getWsNotificationToastNode = (title, content) => {
	return (
		<>
			<h1 style={{ fontSize: '14px' }}>{title}</h1>
			<p>{content}</p>
		</>
	);
};

const getSummary = async () => {
	const resp = await getNotificationSummary();
	const count = resp.reduce(
		(accumulator, item) => accumulator + item.unread_count,
		0
	);
	store.dispatch(setNotificationUnreadCount(count));
};

export function initWs() {
	const { hostname } = location;
	let url;
	if (hostname.indexOf('localhost') >= 0 || hostname.indexOf('test-') >= 0) {
		url = 'wss://test-wss.rx6888.com';
	} else {
		url = 'wss://wss.ipotid.com';
	}
	socket = new WebSocket(url);
	socket.binaryType = 'arraybuffer';

	socket.addEventListener('open', () => {
		socket.send(
			JSON.stringify({
				sub: 'indonesia.notification.publish.signal.1',
				id: Date.now(),
			})
		);
	});

	socket.addEventListener('message', (event) => {
		const jsonString = pako.inflate(event.data, { to: 'string' });
		const msg = JSON.parse(jsonString);
		if (msg.ping) {
			socket.send(
				JSON.stringify({
					pong: msg.ping,
				})
			);
		}
		if (msg.notification) {
			const { title, content } = msg.notification;
			toast(getWsNotificationToastNode(title, content));
			window.speak(title);
			getSummary();
		}
	});

	socket.addEventListener('close', (event) => {
		if (event.wasClean) {
			// const { code, reason } = event;
			// toast.error(`${code}: ${reason}`);
		} else {
			console.log('[close] Connection died');
		}
	});

	socket.addEventListener('error', (error) => {
		toast.error(error);
	});
}

export function destroyWs() {
	if (socket) {
		socket.close();
		socket = null;
	}
}
