import React from 'react';
import PropTypes from 'prop-types';
import { KTSVG } from '../../../../_metronic/helpers';
import ApexChartInit from './ApexChart';

const ListItem = (props) => {
	const {
		title,
		icon,
		iconType = 'duotune',
		iconColor = 'primary',
		iconPathCount = 0,
		numberData,
		numberRenderer,
		chartData,
		fractionDigits = 2,
	} = props;
	return (
		<div
			className="d-flex align-items-center bg-light-secondary rounded mb-7"
			style={{ padding: '0 1.25rem', height: '60px' }}
		>
			{/* begin::Icon */}
			{iconType === 'duotune' && (
				<span className="svg-icon me-5">
					<KTSVG
						path={`/media/icons/duotune/${icon}.svg`}
						className={`svg-icon-1 svg-icon-${iconColor}`}
					/>
				</span>
			)}
			{iconType === 'keenicon' && (
				<span className={`svg-icon me-5 svg-icon-${iconColor} fs-1`}>
					<i className={`ki-duotone ki-${icon}`}>
						{[...new Array(iconPathCount).keys()].map((count) => (
							<span key={count} className={`path${count + 1}`}></span>
						))}
					</i>
				</span>
			)}
			{/* end::Icon */}
			{/* begin::Title */}
			<div className="flex-grow-1 me-2">
				<span
					className="fw-bold text-gray-800 fs-6"
					style={{ wordBreak: 'keep-all' }}
				>
					{title}
				</span>
				{/* <span className="text-muted fw-semibold d-block">Due in 2 Days</span> */}
			</div>
			{/* end::Title */}
			{/* begin::Chart */}
			{chartData && (
				<ApexChartInit
					height={30}
					color="primary"
					name="数量"
					xAxisData={chartData.x || []}
					yAxisData={chartData.y || []}
				/>
			)}
			{/* end::Chart */}
			{/* begin::Lable */}
			<span
				className="fw-bold text-dark py-1 d-flex flex-column align-items-end"
				style={{ marginLeft: '5px' }}
			>
				{numberRenderer && numberRenderer}
				{!numberRenderer &&
					(isNaN(numberData)
						? ''
						: new Intl.NumberFormat('zh-CN', {
								currency: 'CNY',
								minimumFractionDigits: fractionDigits,
								maximumFractionDigits: fractionDigits,
						  }).format(numberData))}
			</span>
			{/* end::Lable */}
		</div>
	);
};

ListItem.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
	iconType: PropTypes.string,
	iconColor: PropTypes.string,
	iconPathCount: PropTypes.number,
	numberData: PropTypes.number,
	numberRenderer: PropTypes.node,
	chartData: PropTypes.object,
	fractionDigits: PropTypes.number,
};

export default ListItem;
