/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import { UserModifyModalHeader } from './UserModifyModalHeader';
import { UserModifyModalFormWrapper } from './UserModifyModalFormWrapper';

const UserModifyModal = (props: {
	setShowModal: unknown;
	type: string;
	user: any;
	action: any;
}) => {
	const { setShowModal, type, user, action } = props;
	useEffect(() => {
		document.body.classList.add('modal-open');
		return () => {
			document.body.classList.remove('modal-open');
		};
	}, []);

	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_add_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-800px">
					{/* begin::Modal content */}
					<div className="modal-content">
						<UserModifyModalHeader setShowModal={setShowModal} type={type} />
						{/* begin::Modal body */}
						<div className="modal-body scroll-y mx-3 mx-xl-5 my-4">
							<UserModifyModalFormWrapper
								type={type}
								user={user}
								setShowModal={setShowModal}
								action={action}
							/>
						</div>
						{/* end::Modal body */}
					</div>
					{/* end::Modal content */}
				</div>
				{/* end::Modal dialog */}
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};

export { UserModifyModal };
