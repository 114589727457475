import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../helpers';

const linkMap = {
	代理数量: {
		path: '/user-management/agent-list',
		search: {},
	},
	系统管理员数量: {
		path: '/user-management/admin-list',
		search: { user_type: 'ADMINISTRATOR_SYS' },
	},
	客服管理员数量: {
		path: '/user-management/admin-list',
		search: { user_type: 'ADMINISTRATOR_CUS' },
	},
};

const CustomizedStatisticsWidget5 = ({
	className,
	color = 'primary',
	svgIcon = 'white',
	iconColor = 'white',
	titleColor = 'white',
	countColor = 'white',
	data = [],
}) => {
	return (
		<a href="#" className={`card bg-${color} hoverable ${className}`}>
			<div className="card-body">
				<KTSVG
					path={`/media/icons/duotune/${svgIcon}`}
					className={`svg-icon-${iconColor} svg-icon-3x ms-n1`}
				/>

				<div className="d-flex align-items-center justify-content-between">
					{data.map((item, index) => (
						<Link
							key={index}
							to={`${linkMap[item.title].path}?search=${JSON.stringify(
								linkMap[item.title].search
							)}`}
						>
							<div key={index}>
								<div className={`text-${countColor} fw-bold fs-2 mb-2 mt-5`}>
									{item.count}
								</div>

								<div className={`fw-semibold text-${titleColor}`}>
									{item.title}
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		</a>
	);
};

CustomizedStatisticsWidget5.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	svgIcon: PropTypes.string,
	iconColor: PropTypes.string,
	titleColor: PropTypes.string,
	countColor: PropTypes.string,
	data: PropTypes.array,
};

export default CustomizedStatisticsWidget5;
