/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import PropTypes from 'prop-types';
import './index.scss';
const SureSetting = ({
	setSureSetting,
	user,
	selectedRows,
	setShowModal,
	action,
}) => {
	const [disabled, setDisabled] = useState(false);
	const enumText = {
		is_financing: {
			true: '可融资',
			false: '不可融资',
		},
		is_short_sale: {
			true: '可融券',
			false: '不可融券',
		},
		stop_buying_switch: {
			true: '是',
			false: '否',
		},
	};
	const updateSetting = async () => {
		setDisabled(true);
		const newSetting = {};
		selectedRows.forEach((item) => {
			if (item.key === 'transaction_commission') {
				newSetting.transaction_commission = Number(
					(item.userSetting / 100).toFixed(4)
				);
			} else {
				newSetting[item.key] = item.userSetting;
			}
		});
		const resp = await request.put(`/users/setting/${user.user_id}`, {
			setting_type: 'transaction',
			setting: newSetting,
		});
		if (resp.setting_type) {
			toast.success('修改成功');
			setDisabled(false);
		}
		setSureSetting(false);
		setShowModal(false);
		action.reload();
	};
	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_sure_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-600px">
					{/* begin::Modal content */}
					<div className="modal-content">
						<div className="modal-header">
							{/* begin::Modal title */}
							<h2 className="fw-bolder">修改交易设置</h2>
							{/* end::Modal title */}

							{/* begin::Close */}
							<div
								className="btn btn-icon btn-sm btn-active-icon-primary"
								data-kt-users-modal-action="close"
								onClick={() => setSureSetting(false)}
								style={{ cursor: 'pointer' }}
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon-1"
								/>
							</div>
							{/* end::Close */}
						</div>
						<div className="modal-body scroll-y mx-3 mx-xl-5 my-5">
							{/* <form id="kt_modal_sure_user_form" className="form" noValidate> */}
							{/* begin::Scroll */}
							<div
								className="d-flex flex-column scroll-y me-n7 pe-7"
								id="kt_modal_add_user_scroll"
								data-kt-scroll="true"
								data-kt-scroll-activate="{default: false, lg: true}"
								data-kt-scroll-max-height="auto"
								data-kt-scroll-dependencies="#kt_modal_add_user_header"
								data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
								data-kt-scroll-offset="300px"
							>
								<div className="fv-row mb-7">
									<div className="card shadow-sm badge-light p-3">
										<h5>{user.full_name}</h5>
										<div className="flex" style={{ lineHeight: '17px' }}>
											<span className="flex">
												<KTSVG path="/media/icons/duotune/electronics/elc002.svg" />
												<span className="ms-1">{user.phone}</span>
											</span>
											<span className="flex ms-3">
												<KTSVG path="/media/icons/duotune/communication/com006.svg" />
												<span className="ms-1">
													{user.user_type === 'TRADER'
														? '普通用户'
														: user.user_type === 'AGENT'
														? '代理'
														: '管理员'}
												</span>
											</span>
											<span className="flex ms-3">
												<KTSVG path="/media/icons/duotune/communication/com014.svg" />
												<span className="ms-1">
													{`${user.parent_username}(${
														user.parent_phone || '无'
													})`}
												</span>
											</span>
											<span className="flex ms-3">
												<KTSVG path="/media/icons/duotune/general/gen047.svg" />
												<span
													className="ms-1"
													style={{
														color: `${user.is_blocked ? '#ff4d4f' : '#50cd89'}`,
													}}
												>
													{user.is_blocked ? '锁定' : '正常'}
												</span>
											</span>
										</div>
									</div>
								</div>
								<div className="card shadow-sm badge-light p-3">
									{selectedRows.map((item, index) => {
										return (
											<div key={index}>
												<div className="fs-6 d-flex justify-content-between my-4 flex-direction-row">
													<div className="fw-semibold">{item.field}</div>
													<span className="fw-bold">
														{item.key == 'transaction_commission'
															? `${item.userSetting}%`
															: item.key == 'max_lever'
															? `${item.userSetting}X`
															: enumText[item.key][item.userSetting]}
													</span>
												</div>
												<div className="separator separator-dashed"></div>
											</div>
										);
									})}
								</div>
							</div>
							{/* end::Scroll */}

							{/* begin::Actions */}
							<div className="text-center pt-10">
								<button
									type="reset"
									onClick={() => setSureSetting(false)}
									className="btn btn-light me-3"
								>
									取消
								</button>

								<button
									// type="submit"
									className="btn btn-primary"
									// data-kt-users-modal-action="submit"
									onClick={updateSetting}
									disabled={disabled}
								>
									确定
									{/* <span className="indicator-label">确定</span> */}
								</button>
							</div>
							{/* end::Actions */}
							{/* </form> */}
						</div>
					</div>
				</div>
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};
SureSetting.propTypes = {
	setSureSetting: PropTypes.func,
	setShowModal: PropTypes.func,
	user: PropTypes.object,
	selectedRows: PropTypes.array,
	action: PropTypes.object,
};
export { SureSetting };
