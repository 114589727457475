import React, { useState, useEffect } from 'react';
import ls from 'local-storage';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';
import {
	getRechargeUnauditNum,
	getWithdrawUnauditNum,
} from '../../../../../utils/service';

const qsData = qs.parse(window.location.search.slice(1));
const { source } = qsData;

const SidebarMenuMain = () => {
	const userInfo =
		useSelector((state) => state.global.userInfo) || ls.get('X-Auth-User-Info');
	const { user_type } = userInfo;
	const [rechargeUnauditNum, setRechargeUnauditNum] = useState('');
	const [withdrawUnauditNum, setWithdrawUnauditNum] = useState('');

	const getUnauditNum = async () => {
		const resp1 = await getRechargeUnauditNum();
		const resp2 = await getWithdrawUnauditNum();
		setRechargeUnauditNum(resp1.pagination.total_data);
		setWithdrawUnauditNum(resp2.pagination.total_data);
	};

	useEffect(() => {
		if (user_type !== 'AGENT' && source !== 'app') {
			getUnauditNum();
		}
	}, []);

	return (
		<>
			{(user_type === 'ADMINISTRATOR' || user_type === 'ADMINISTRATOR_SYS') && (
				<SidebarMenuItemWithSub
					to="/customer"
					title="仪表盘"
					fontIcon="bi-archive"
					icon="/media/icons/duotune/graphs/gra006.svg"
				>
					<SidebarMenuItem
						to="/dashboard"
						// icon="/media/icons/duotune/general/gen008.svg"
						title="工作台"
						hasBullet={true}
						// fontIcon="bi-app-indicator"
					/>
				</SidebarMenuItemWithSub>
			)}
			{/* 用户管理 */}
			<div className="menu-item">
				<div className="menu-content pt-8 pb-2">
					<span className="menu-section text-muted text-uppercase fs-8 ls-1">
						用户管理
					</span>
				</div>
			</div>
			<SidebarMenuItemWithSub
				to="/customer"
				title="用户管理"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/general/gen067.svg"
			>
				<SidebarMenuItem
					to="/user-management/user-list"
					title="用户列表"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/user-management/agent-list"
					title="代理列表"
					hasBullet={true}
				/>
				{(user_type === 'ADMINISTRATOR' ||
					user_type === 'ADMINISTRATOR_SYS') && (
					<SidebarMenuItem
						to="/user-management/admin-list"
						title="管理员列表"
						hasBullet={true}
					/>
				)}
			</SidebarMenuItemWithSub>
			{(user_type === 'ADMINISTRATOR' || user_type === 'ADMINISTRATOR_SYS') && (
				<>
					{/* 产品管理 */}
					<div className="menu-item">
						<div className="menu-content pt-8 pb-2">
							<span className="menu-section text-muted text-uppercase fs-8 ls-1">
								产品管理
							</span>
						</div>
					</div>
					<SidebarMenuItemWithSub
						to="/product"
						title="产品管理"
						fontIcon="bi-archive"
						icon="/media/icons/duotune/abstract/abs046.svg"
					>
						<SidebarMenuItem
							to="/product-management/stock-list"
							title="印尼股票"
							hasBullet={true}
						/>
						<SidebarMenuItem
							to="/product-management/gold-list"
							title="黄金"
							hasBullet={true}
						/>
					</SidebarMenuItemWithSub>
				</>
			)}
			<div className="menu-item">
				<div className="menu-content pt-8 pb-2">
					<span className="menu-section text-muted text-uppercase fs-8 ls-1">
						股票交易
					</span>
				</div>
			</div>
			{/* 持仓管理 */}
			<SidebarMenuItemWithSub
				to="/hold-stock"
				title="股票持仓"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/abstract/abs042.svg"
			>
				<SidebarMenuItem
					to="/order-management/hold-list"
					title="持仓列表"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/order-management/003"
					title="创建股票持仓单"
					hasBullet={true}
				/>
			</SidebarMenuItemWithSub>
			{/* 订单管理 */}
			<SidebarMenuItemWithSub
				to="/order-stock"
				title="股票订单"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/finance/fin003.svg"
			>
				<SidebarMenuItem
					to="/order-management/order-list"
					title="订单列表"
					hasBullet={true}
				/>
			</SidebarMenuItemWithSub>
			{/* 委托管理 */}
			<SidebarMenuItemWithSub
				to="/entrust-stock"
				title="股票委托"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/general/gen011.svg"
			>
				<SidebarMenuItem
					to="/order-management/entrust-list"
					title="委托列表"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/order-management/018"
					title="新建委托单"
					hasBullet={true}
				/>
			</SidebarMenuItemWithSub>

			<div className="menu-item">
				<div className="menu-content pt-8 pb-2">
					<span className="menu-section text-muted text-uppercase fs-8 ls-1">
						黄金交易
					</span>
				</div>
			</div>
			{/* 持仓管理 */}
			<SidebarMenuItemWithSub
				to="/hold-gold"
				title="黄金持仓"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/abstract/abs042.svg"
			>
				<SidebarMenuItem
					to="/order-management/gold-hold-list"
					title="持仓列表"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/order-management/101"
					title="创建股票持仓单"
					hasBullet={true}
				/>
			</SidebarMenuItemWithSub>
			{/* 订单管理 */}
			<SidebarMenuItemWithSub
				to="/order-gold"
				title="黄金订单"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/finance/fin003.svg"
			>
				<SidebarMenuItem
					to="/order-management/gold-order-list"
					title="订单列表"
					hasBullet={true}
				/>
			</SidebarMenuItemWithSub>
			{/* 委托管理 */}
			<SidebarMenuItemWithSub
				to="/entrust-gold"
				title="黄金委托"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/general/gen011.svg"
			>
				<SidebarMenuItem
					to="/order-management/gold-entrust-list"
					title="委托列表"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/order-management/104"
					title="新建委托单"
					hasBullet={true}
				/>
			</SidebarMenuItemWithSub>

			<div className="menu-item">
				<div className="menu-content pt-8 pb-2">
					<span className="menu-section text-muted text-uppercase fs-8 ls-1">
						资金管理
					</span>
				</div>
			</div>
			{/* 资金管理 */}
			{user_type !== 'AGENT' && (
				<>
					{/* <SidebarMenuItemWithSub
						to="/crafted/pages"
						title="资金管理"
						fontIcon="bi-archive"
						icon="/media/icons/duotune/finance/fin002.svg"
					> */}
					<SidebarMenuItem
						to="/order-management/recharge-list"
						title="充值列表"
						badge={rechargeUnauditNum}
						hasBullet={false}
						icon="/media/icons/duotune/finance/fin002.svg"
					/>
					<SidebarMenuItem
						to="/order-management/withdraw-list"
						title="提现列表"
						badge={withdrawUnauditNum}
						hasBullet={false}
						icon="/media/icons/duotune/finance/fin008.svg"
					/>
					<SidebarMenuItem
						to="/order-management/records-list"
						title="资金记录"
						hasBullet={false}
						icon="/media/icons/duotune/files/fil001.svg"
					/>
					<SidebarMenuItem
						to="/order-management/008"
						title="资金互转记录"
						hasBullet={false}
						icon="/media/icons/duotune/files/fil011.svg"
					/>
					{/* </SidebarMenuItemWithSub> */}
				</>
			)}
			{/* 风控设置 */}
			<div className="menu-item">
				<div className="menu-content pt-8 pb-2">
					<span className="menu-section text-muted text-uppercase fs-8 ls-1">
						风控设置
					</span>
				</div>
			</div>
			{(user_type === 'ADMINISTRATOR' || user_type === 'ADMINISTRATOR_SYS') && (
				<>
					<SidebarMenuItemWithSub
						to="/crafted/pages"
						title="风控设置"
						fontIcon="bi-archive"
						icon="/media/icons/duotune/abstract/abs044.svg"
					>
						<SidebarMenuItem
							to="/system-management/risk-settings-stock"
							title="印尼股票风控"
							hasBullet={true}
						/>
						<SidebarMenuItem
							to="/system-management/risk-settings-gold"
							title="黄金风控"
							hasBullet={true}
						/>
					</SidebarMenuItemWithSub>
				</>
			)}
			{/* 系统管理 */}
			<div className="menu-item">
				<div className="menu-content pt-8 pb-2">
					<span className="menu-section text-muted text-uppercase fs-8 ls-1">
						系统管理
					</span>
				</div>
			</div>
			<SidebarMenuItemWithSub
				to="/system-management"
				title="系统管理"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/general/gen062.svg"
			>
				<SidebarMenuItem
					to="/system-management/system-base"
					title="系统设置"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/system-management/013"
					title="产品配置"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/system-management/107"
					title="权限设置"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/system-management/user-login"
					title="登录用户"
					hasBullet={true}
				/>
			</SidebarMenuItemWithSub>
			<SidebarMenuItemWithSub
				to="/crafted/pages"
				title="内容管理"
				fontIcon="bi-archive"
				icon="/media/icons/duotune/abstract/abs014.svg"
			>
				<SidebarMenuItem
					to="/content-management/announcement-list"
					// icon="/media/icons/duotune/coding/cod001.svg"
					title="公告管理"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/content-management/banner-list"
					// icon="/media/icons/duotune/general/gen006.svg"
					title="轮播图管理"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/system-management/agreement-list"
					title="协议管理"
					hasBullet={true}
				/>
				<SidebarMenuItem
					to="/content-management/news-list"
					title="新闻管理"
					hasBullet={true}
				/>
			</SidebarMenuItemWithSub>
			{user_type !== 'AGENT' && (
				<SidebarMenuItemWithSub
					to="/log"
					title="日志管理"
					fontIcon="bi-archive"
					icon="/media/icons/duotune/general/gen005.svg"
				>
					<SidebarMenuItem
						to="log/login-list"
						title="登录日志"
						hasBullet={true}
					/>
					<SidebarMenuItem to="log/009" title="操作日志" hasBullet={true} />
				</SidebarMenuItemWithSub>
			)}
		</>
	);
};

export { SidebarMenuMain };
