import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { toAbsoluteUrl } from '../../../helpers';

import ls from 'local-storage';
import {
	setToken,
	setRefreshToken,
	setUserId,
	setUserInfo,
} from '../../../../store/slices/global';
import { destroyWs } from '../../../../utils/ws';
// import { useAuth } from '../../../../app/modules/auth';
// import { Languages } from './Languages';
// import { toAbsoluteUrl } from '../../../helpers';

const HeaderUserMenu = () => {
	// const { /*currentUser,*/ logout } = useAuth();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.global.userInfo);

	const logout = () => {
		dispatch(setToken({ token: '' }));
		dispatch(setRefreshToken({ refreshToken: '' }));
		dispatch(setUserId({ userId: '' }));
		dispatch(setUserInfo({ userInfo: {} }));
		ls.remove('X-Auth-Token');
		ls.remove('X-Auth-Refresh-Token');
		ls.remove('X-Auth-User-Id');
		ls.remove('X-Auth-User-Info');
		destroyWs();
		navigate('/auth');
	};

	return (
		<div
			className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
			data-kt-menu="true"
		>
			<div className="menu-item px-3">
				<div className="menu-content d-flex align-items-center px-3">
					<div className="symbol symbol-50px me-5">
						{/* <img alt="Logo" src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
						<img
							src="https://api.dicebear.com/6.x/identicon/svg?rowColor=1e88e5&backgroundColor=ffdfbf"
							alt="avatar"
						/>
					</div>

					<div className="d-flex flex-column">
						<div className="fw-bolder d-flex align-items-center fs-5">
							{userInfo.email}
						</div>
						<a href="#" className="fw-bold text-muted text-hover-primary fs-7">
							{userInfo.phone}
						</a>
					</div>
				</div>
			</div>

			<div className="separator my-2"></div>

			<div className="menu-item px-5">
				<Link to={'/my/info'} className="menu-link px-5">
					{/* <span className="symbol symbol-20px me-4">
						<img
							className="rounded-1"
							src={toAbsoluteUrl('/media/flags/united-states.svg')}
							alt="metronic"
						/>
					</span> */}
					个人信息
				</Link>
			</div>

			{/* <div className="menu-item px-5">
				<a href="#" className="menu-link px-5">
					<span className="menu-text">My Projects</span>
					<span className="menu-badge">
						<span className="badge badge-light-danger badge-circle fw-bolder fs-7">
							3
						</span>
					</span>
				</a>
			</div> */}

			{/* <div
				className="menu-item px-5"
				data-kt-menu-trigger="hover"
				data-kt-menu-placement="left-start"
				data-kt-menu-flip="bottom"
			>
				<a href="#" className="menu-link px-5">
					<span className="menu-title">My Subscription</span>
					<span className="menu-arrow"></span>
				</a>

				<div className="menu-sub menu-sub-dropdown w-175px py-4">
					<div className="menu-item px-3">
						<a href="#" className="menu-link px-5">
							Referrals
						</a>
					</div>

					<div className="menu-item px-3">
						<a href="#" className="menu-link px-5">
							Billing
						</a>
					</div>

					<div className="menu-item px-3">
						<a href="#" className="menu-link px-5">
							Payments
						</a>
					</div>

					<div className="menu-item px-3">
						<a href="#" className="menu-link d-flex flex-stack px-5">
							Statements
							<i
								className="fas fa-exclamation-circle ms-2 fs-7"
								data-bs-toggle="tooltip"
								title="View your statements"
							></i>
						</a>
					</div>

					<div className="separator my-2"></div>

					<div className="menu-item px-3">
						<div className="menu-content px-3">
							<label className="form-check form-switch form-check-custom form-check-solid">
								<input
									className="form-check-input w-30px h-20px"
									type="checkbox"
									value="1"
									defaultChecked={true}
									name="notifications"
								/>
								<span className="form-check-label text-muted fs-7">
									Notifications
								</span>
							</label>
						</div>
					</div>
				</div>
			</div> */}

			{/* <div className="menu-item px-5">
				<a href="#" className="menu-link px-5">
					My Statements
				</a>
			</div> */}

			<div className="menu-item px-5 my-1">
				<Link to="/my/password" className="menu-link px-5">
					修改密码
				</Link>
			</div>

			<div className="menu-item px-5 my-1">
				<Link to="/my/validate" className="menu-link px-5">
					二次验证
				</Link>
			</div>

			{/* <Languages /> */}

			<div className="separator my-2"></div>

			<div className="menu-item px-5">
				<a onClick={logout} className="menu-link px-5">
					登出
				</a>
			</div>
		</div>
	);
};

export { HeaderUserMenu };
