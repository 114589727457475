import { ID, Response } from '../../../../../../_metronic/helpers';
export type User = {
	user_type?: string;
	user_id?: number;
	user_role?: string;
	username?: string;
	full_name?: string;
	password1?: number;
	password2?: number;
	phone?: string;
	id_no?: number;
	is_blocked?: string;
	id?: ID;
	name?: string;
	avatar?: string;
	email?: string;
	position?: string;
	role?: string;
	last_login?: string;
	two_steps?: boolean;
	joined_day?: string;
	online?: boolean;
	initials?: {
		label: string;
		state: string;
	};
};
export type AmountSummary = {
	amount?: number;
};

export type UsersQueryResponse = Response<Array<User>>;

export const initialUser: User = {
	avatar: 'avatars/300-6.jpg',
	position: 'Art Director',
	role: 'Administrator',
	name: '',
	email: '',
	full_name: '',
	password1: undefined,
	password2: undefined,
	phone: undefined,
	id_no: undefined,
	username: '',
	is_blocked: '',
	user_role: undefined,
	user_id: undefined,
	user_type: undefined,
};
export const initAmountSummary: AmountSummary = {
	amount: undefined,
};
