import React from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';
import ListItemHolding from './components/ListItemHolding';

export function stockHoldingRenderer(data, key) {
	if (data) {
		return data[key].map((item) => {
			const { symbol, name, counts, holding_percent, tags } = item;
			return (
				<ListItemHolding
					key={symbol}
					name={name}
					code={symbol}
					tags={tags}
					percent={holding_percent}
					stock={counts}
				/>
			);
		});
	} else {
		return '';
	}
}

export function getCardLinkRenderer(path = '/', state) {
	return (
		<div className="card-toolbar">
			<Link to={path} state={state}>
				<span
					type="button"
					className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
				>
					<KTSVG
						path="/media/icons/duotune/arrows/arr064.svg"
						className="svg-icon-1"
					/>
				</span>
			</Link>
		</div>
	);
}
