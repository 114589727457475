import React from 'react';
import moment from 'moment';
import { Button, Tooltip } from 'antd';
import store from '../store/store';
import eventBus from './eventBus';
import { getObjectKeyByValue } from './utils';

export function joinWithOne(
	val,
	record,
	key,
	leftJoin = '',
	rightJoin = '',
	transformer
) {
	if (val === '') return '';
	return `${val}${leftJoin}${
		transformer ? transformer(record[key]) : record[key]
	}${rightJoin}`;
}

export function joinWithOneReverse(
	val,
	record,
	key,
	leftJoin = '',
	rightJoin = ''
) {
	if (val === '') return '';
	return `${record[key]}${leftJoin}${val}${rightJoin}`;
}

export function leftFixedColumnSearchTransformer(value, key) {
	return {
		[key.substr(0, key.length - 2)]: value,
	};
}

export function dateRangeSearchTransformer(value, key) {
	return { [key]: [`${value[0]} 00:00:00`, `${value[1]} 23:59:59`] };
}

export function boolSearchTransformer(value, key) {
	let val;
	switch (value) {
		case 'true':
			val = true;
			break;
		case 'false':
			val = false;
			break;
	}
	return {
		[key]: val,
	};
}

export function beforeDateTransformer(value, key) {
	const date = moment().subtract(value, 'days').format('YYYY-MM-DD');
	return {
		[key]: date,
	};
}

export function blockStatusRenderer(val) {
	let renderer;
	switch (val) {
		case true:
			renderer = <span className="badge badge-light-danger">锁定</span>;
			break;
		case false:
			renderer = <span className="badge badge-light-primary">正常</span>;
			break;
	}
	return renderer;
}

export function auditStatusRenderer(val) {
	let renderer;
	switch (val) {
		case 1:
		case 'SUBMITTED':
			renderer = <span className="badge badge-light-warning">审核中</span>;
			break;
		case 2:
		case 'APPROVED':
			renderer = <span className="badge badge-light-success">通过</span>;
			break;
		case 3:
		case 'REJECTED':
			renderer = <span className="badge badge-light-danger">拒绝</span>;
			break;
		case 'NOT_AUTH':
			renderer = <span className="badge badge-light-dark">未认证</span>;
			break;
	}
	return renderer;
}

export function rechargeStatusRenderer(val) {
	let renderer;
	switch (val) {
		case 'SUBMITTED':
			renderer = <span className="badge badge-light-warning">审核中</span>;
			break;
		case 'APPROVED':
			renderer = <span className="badge badge-light-success">成功</span>;
			break;
		case 'REJECTED':
			renderer = <span className="badge badge-light-danger">失败</span>;
			break;
	}
	return renderer;
}

export function trueOrFalseRenderer(val) {
	let renderer;
	switch (val) {
		case true:
			renderer = <span className="badge badge-light-primary">是</span>;
			break;
		case false:
			renderer = <span className="badge badge-light-danger">否</span>;
			break;
	}
	return renderer;
}

export function showOrHideRenderer(val) {
	let renderer;
	switch (val) {
		case true:
			renderer = <span className="badge badge-light-success">显示</span>;
			break;
		case false:
			renderer = <span className="badge badge-light-danger">隐藏</span>;
			break;
	}
	return renderer;
}

export function newsStatusRenderer(val) {
	let renderer;
	switch (val) {
		case 'DRAFT':
			renderer = <span className="badge badge-light-success">草稿箱</span>;
			break;
		case 'RELEASED':
			renderer = <span className="badge badge-light-primary">已发布</span>;
			break;
		case 'REMOVED':
			renderer = <span className="badge badge-light-danger">已下架</span>;
			break;
	}
	return renderer;
}

export function buyOrSellRenderer(val) {
	let renderer;
	switch (val) {
		case 'BUY':
			renderer = <span className="badge badge-light-danger">买入</span>;
			break;
		case 'SELL':
			renderer = <span className="badge badge-light-success">卖出</span>;
			break;
	}
	return renderer;
}

export function contractStatusRenderer(val) {
	let renderer;
	switch (val) {
		case 'REPAID':
			renderer = <span className="badge badge-light-success">已清算</span>;
			break;
		case 'CONFIRMED':
			renderer = <span className="badge badge-light-primary">进行中</span>;
			break;
	}
	return renderer;
}

export function imageRenderer(url, width = 100, height = 70) {
	return (
		<img
			src={url}
			width={width}
			height={height}
			style={{ borderRadius: '5px', objectFit: 'cover' }}
		/>
	);
}

export function useRoleRenderer(val) {
	let renderer;
	switch (val) {
		case 'ADMINISTRATOR':
			renderer = <span className="badge badge-light-danger">超级管理员</span>;
			break;
		case 'ADMINISTRATOR_SYS':
			renderer = <span className="badge badge-light-warning">系统管理员</span>;
			break;
		case 'ADMINISTRATOR_CUS':
			renderer = <span className="badge badge-light-success">客服管理员</span>;
			break;
		case 'AGENT':
			renderer = <span className="badge badge-light-info">代理</span>;
			break;
	}
	return renderer;
}

export function financeCouponRenderer(val) {
	let renderer;
	switch (val) {
		case 'RZ':
			renderer = <span className="badge badge-light-info">买多</span>;
			break;
		case 'RQ':
			renderer = <span className="badge badge-light-success">卖空</span>;
			break;
	}
	return renderer;
}

export function stockFinanceCouponRenderer(text, record) {
	return (
		<>
			{joinWithOne(text, record, 'symbol', '(', ')')}
			&nbsp;
			{financeCouponRenderer(record.category)}
		</>
	);
}

export function tooltipRenderer(text) {
	return (
		<Tooltip title={text} placement="topLeft">
			<span>{text}</span>
		</Tooltip>
	);
}

export function leverageRenderer(text) {
	return <span className="badge badge-secondary">{text}</span>;
}

export function actionButtonRenderer(text, name, record, action) {
	const key = getObjectKeyByValue(record, text);
	let id;
	switch (key) {
		case 'full_name':
			id = record['user_id'];
			break;
		case 'parent_username':
		case 'agent_name':
			id = record['parent_user_id'];
			break;
		case 'agent_username':
			id = record['agent_user_id'];
			break;
	}
	return (
		<Button
			type="link"
			onClick={() => {
				name = name.charAt(0).toUpperCase() + name.slice(1);
				eventBus.dispatch(`tableAction${name}`, { record, action, id });
			}}
		>
			{text}
		</Button>
	);
}

export function multipleRenderer(num, m = 1) {
	return Number(num) * m;
}

export function multipleToFixedRenderer(num, m = 100, fixed = 2) {
	// if (num === 0) fixed = 0;
	return (Number(num) * m).toFixed(fixed) + '%';
}

export function growAndDecreaseRenderer(val, multiple = 1) {
	let {
		global: { themeMode },
	} = store.getState();
	let color;
	if (val > 0) {
		color = '#d9214e';
		val = (val * multiple).toFixed(1);
		val = `+${val}%`;
	} else if (val < 0) {
		color = '#47be7d';
		val = (val * multiple).toFixed(1);
		val = `${val}%`;
	} else if (val === 0) {
		val = `${val}%`;
		color = themeMode === 'light' ? '#000' : '#fff';
	}
	return <span style={{ color }}>{val}</span>;
}

export function currencyRenderer(text, record) {
	const { currency } = record;
	let locale = 'zh-CN';
	switch (currency) {
		case 'USD':
			locale = 'en-US';
			break;
		case 'HKD':
			locale = 'zh-HK';
			break;
	}
	const result = new Intl.NumberFormat(locale, {
		style: locale === 'zh-HK' ? 'decimal' : 'currency',
		currency,
	}).format(text);

	if (locale === 'zh-HK') {
		return `HK$${result}`;
	} else {
		return result;
	}
}

export function userDetailModalRenderer(text, record, _, action) {
	return (
		<a
			style={{
				color: '#1677ff',
			}}
			onClick={() => {
				eventBus.dispatch(`tableActionDetail`, { record, action });
			}}
		>
			{text}
		</a>
	);
}

export function userEditModalRenderer(text, record, _, action) {
	return (
		<a
			style={{
				color: '#1677ff',
			}}
			onClick={() => {
				eventBus.dispatch(`tableActionGlobalUserEdit`, record);
			}}
		>
			{text}
		</a>
	);
}

export function moneyFormater(value, fracNum = 2) {
	if (!value) {
		return '0.00';
	}
	return new Intl.NumberFormat('zh-CN', {
		style: 'currency',
		currency: 'CNY',
		minimumFractionDigits: fracNum,
		maximumFractionDigits: fracNum,
	}).format(value);
}

export function moneyFormaterWithoutCurrencyPrefix(value, fracNum = 2) {
	return moneyFormater(value, fracNum).replace('¥', '');
}
