import React from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import PropTypes from 'prop-types';

const Tips = (props) => {
	// eslint-disable-next-line react/prop-types
	const {
		tip,
		title,
		path = '/media/icons/duotune/coding/cod009.svg',
		colorClass = 'bg-light-warning border-warning',
	} = props;
	return (
		<div
			className={`notice d-flex mt-4 ${colorClass} rounded border border-dashed px-6 py-3`}
		>
			<KTSVG path={path} className="svg-icon-2tx svg-icon-warning me-4" />
			<div className="d-flex flex-stack flex-grow-1">
				<div className="fw-bold">
					{title && <h4 className="text-gray-800">{title}</h4>}
					<div className="fs-6 text-gray-600">{tip}</div>
				</div>
			</div>
		</div>
	);
};

Tips.propTypes = {
	tip: PropTypes.string || PropTypes.object,
	colorClass: PropTypes.string,
	path: PropTypes.string,
	title: PropTypes.string,
};

export { Tips };
