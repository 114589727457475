import React, { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';

const PrivateRoutes = () => {
	const UserList = lazy(() => import('../pages/user/user'));
	const AdminList = lazy(() => import('../pages/user/admin'));
	const AgentList = lazy(() => import('../pages/user/agent'));
	const RechargeList = lazy(() => import('../pages/order/recharge'));
	const WithdrawList = lazy(() => import('../pages/order/withdraw'));
	const RecordsList = lazy(() => import('../pages/order/records'));
	const NewsList = lazy(() => import('../pages/system/news/news'));
	const AgreementList = lazy(
		() => import('../pages/system/agreement/agreement')
	);
	const AnnouncementList = lazy(
		() => import('../pages/system/announcement/announcement')
	);
	const BaseSettings = lazy(
		() => import('../pages/base-settings/BaseSettings')
	);
	const LogLoginList = lazy(() => import('../pages/log/login'));
	const LogOperationList = lazy(() => import('../pages/log/operation'));
	const UpDownRecordList = lazy(() => import('../pages/log/upDownRecord'));
	const ContractList = lazy(() => import('../pages/order/contract'));
	const StockList = lazy(() => import('../pages/product/stock/stock'));
	const GoldList = lazy(() => import('../pages/product/gold/gold'));
	const FutureList = lazy(() => import('../pages/product/stock/future'));
	const CryptoList = lazy(() => import('../pages/product/crypto/crypto'));
	const CryptoUsdmList = lazy(
		() => import('../pages/product/crypto/crypto-usdm')
	);
	const HoldList = lazy(() => import('../pages/order/hold'));
	const GoldHoldList = lazy(() => import('../pages/order/goldHold'));
	const DealOrderList = lazy(() => import('../pages/order/dealOrder'));
	const EntrustOrderList = lazy(() => import('../pages/order/entrustOrder'));
	const EntrustList = lazy(() => import('../pages/order/entrust'));
	const GoldEntrustList = lazy(() => import('../pages/order/goldEntrust'));
	const OrderList = lazy(() => import('../pages/order/order'));
	const GoldOrderList = lazy(() => import('../pages/order/goldOrder'));
	const CancelOrderList = lazy(() => import('../pages/order/cancelOrder'));
	const AccountDetailList = lazy(() => import('../pages/order/accountDetail'));
	const BannerList = lazy(() => import('../pages/system/banner/banner'));
	const RiskSettings = lazy(() => import('../pages/risk-settings/risks'));
	const RiskSettingsStock = lazy(
		() => import('../pages/risk-settings-stock/risks')
	);
	const RiskSettingsGold = lazy(
		() => import('../pages/risk-settings-gold/risks')
	);
	const My = lazy(() => import('../pages/my/my'));
	const UserLogin = lazy(() => import('../pages/system/login/login'));

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path="auth/*" element={<Navigate to="/dashboard" />} />
				{/* Pages */}
				<Route path="dashboard" element={<DashboardWrapper />} />
				<Route path="builder" element={<BuilderPageWrapper />} />
				<Route path="menu-test" element={<MenuTestPage />} />
				{/* Lazy Modules */}
				{/* 个人信息 */}
				<Route
					path="my/:tabKey"
					element={
						<SuspensedView>
							<My />
						</SuspensedView>
					}
				></Route>
				{/* 用户管理 */}
				<Route path="user-management/*">
					<Route
						path="user-list"
						element={
							<SuspensedView>
								<UserList />
							</SuspensedView>
						}
					/>
					<Route
						path="agent-list"
						element={
							<SuspensedView>
								<AgentList />
							</SuspensedView>
						}
					/>
					<Route
						path="admin-list"
						element={
							<SuspensedView>
								<AdminList />
							</SuspensedView>
						}
					/>
				</Route>
				{/* 产品管理 */}
				<Route path="product-management/*">
					<Route
						path="stock-list"
						element={
							<SuspensedView>
								<StockList />
							</SuspensedView>
						}
					/>
					<Route
						path="gold-list"
						element={
							<SuspensedView>
								<GoldList />
							</SuspensedView>
						}
					/>
					<Route
						path="future-list"
						element={
							<SuspensedView>
								<FutureList />
							</SuspensedView>
						}
					/>
					<Route
						path="crypto-list"
						element={
							<SuspensedView>
								<CryptoList />
							</SuspensedView>
						}
					/>
					<Route
						path="crypto-usdm-list"
						element={
							<SuspensedView>
								<CryptoUsdmList />
							</SuspensedView>
						}
					/>
				</Route>
				{/* 订单管理 */}
				<Route path="order-management/*">
					<Route
						path="contract-list"
						element={
							<SuspensedView>
								<ContractList />
							</SuspensedView>
						}
					/>
					<Route
						path="recharge-list"
						element={
							<SuspensedView>
								<RechargeList />
							</SuspensedView>
						}
					/>
					<Route
						path="withdraw-list"
						element={
							<SuspensedView>
								<WithdrawList />
							</SuspensedView>
						}
					/>
					<Route
						path="records-list"
						element={
							<SuspensedView>
								<RecordsList />
							</SuspensedView>
						}
					/>
					<Route
						path="hold-list"
						element={
							<SuspensedView>
								<HoldList />
							</SuspensedView>
						}
					/>
					<Route
						path="gold-hold-list"
						element={
							<SuspensedView>
								<GoldHoldList />
							</SuspensedView>
						}
					/>
					<Route
						path="deal-order-list"
						element={
							<SuspensedView>
								<DealOrderList />
							</SuspensedView>
						}
					/>
					<Route
						path="entrust-order-list"
						element={
							<SuspensedView>
								<EntrustOrderList />
							</SuspensedView>
						}
					/>
					<Route
						path="entrust-list"
						element={
							<SuspensedView>
								<EntrustList />
							</SuspensedView>
						}
					/>
					<Route
						path="gold-entrust-list"
						element={
							<SuspensedView>
								<GoldEntrustList />
							</SuspensedView>
						}
					/>
					<Route
						path="order-list"
						element={
							<SuspensedView>
								<OrderList />
							</SuspensedView>
						}
					/>
					<Route
						path="gold-order-list"
						element={
							<SuspensedView>
								<GoldOrderList />
							</SuspensedView>
						}
					/>
					<Route
						path="cancel-order-list"
						element={
							<SuspensedView>
								<CancelOrderList />
							</SuspensedView>
						}
					/>
					<Route
						path="account-detail-list"
						element={
							<SuspensedView>
								<AccountDetailList />
							</SuspensedView>
						}
					/>
				</Route>
				{/* 日志管理 */}
				<Route path="log/*">
					<Route
						path="login-list"
						element={
							<SuspensedView>
								<LogLoginList />
							</SuspensedView>
						}
					/>
					<Route
						path="operation-list"
						element={
							<SuspensedView>
								<LogOperationList />
							</SuspensedView>
						}
					/>
					<Route
						path="updown-record-list"
						element={
							<SuspensedView>
								<UpDownRecordList />
							</SuspensedView>
						}
					/>
				</Route>
				{/* 内容管理 */}
				<Route path="content-management/*">
					<Route
						path="news-list"
						element={
							<SuspensedView>
								<NewsList />
							</SuspensedView>
						}
					/>
					<Route
						path="banner-list"
						element={
							<SuspensedView>
								<BannerList />
							</SuspensedView>
						}
					/>
					<Route
						path="announcement-list"
						element={
							<SuspensedView>
								<AnnouncementList />
							</SuspensedView>
						}
					/>
				</Route>
				{/* 系统管理 */}
				<Route path="system-management/*">
					<Route
						path="risk-settings-future"
						element={
							<SuspensedView>
								<RiskSettings />
							</SuspensedView>
						}
					/>
					<Route
						path="risk-settings-stock"
						element={
							<SuspensedView>
								<RiskSettingsStock />
							</SuspensedView>
						}
					/>
					<Route
						path="risk-settings-gold"
						element={
							<SuspensedView>
								<RiskSettingsGold />
							</SuspensedView>
						}
					/>
					<Route
						path="system-base"
						element={
							<SuspensedView>
								<BaseSettings />
							</SuspensedView>
						}
					/>
					<Route
						path="agreement-list"
						element={
							<SuspensedView>
								<AgreementList />
							</SuspensedView>
						}
					/>
					<Route
						path="user-login"
						element={
							<SuspensedView>
								<UserLogin />
							</SuspensedView>
						}
					/>
				</Route>
				{/* Page Not Found */}
				<Route path="*" element={<Navigate to="/error/404" />} />
			</Route>
		</Routes>
	);
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary');
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	});
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
