/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { initialUser } from '../../../modules/apps/user-management/users-list/core/_models';
import clsx from 'clsx';
import { KTSVG } from '../../../../_metronic/helpers';
import { useListView } from '../../../modules/apps/user-management/users-list/core/ListViewProvider';
import { UsersListLoading } from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading';
import { useQueryResponse } from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import './index.scss';

// type Props = {
// 	isUserLoading: boolean;
// 	user: User;
// 	setShowModal: any;
// };

const editUserSchema = Yup.object().shape({
	bank_card_no: Yup.string().required('银行卡号是必填项'),
	bank: Yup.string().required('银行名称是必填项'),
	opening_bank: Yup.string().required('开户行是必填项'),
});

const BankCardModifyModalForm = (props) => {
	// eslint-disable-next-line react/prop-types
	const { user, isUserLoading, setShowModal, action } = props;
	const { setItemIdForUpdate } = useListView();
	const { refetch } = useQueryResponse();
	const [userMsg, setUserMsg] = useState({
		bank_card_no: '',
		bank: '',
		opening_bank: '',
	});
	const [userForEdit] = useState({
		...userMsg,
		bank_card_no: userMsg.bank_card_no || initialUser.bank_card_no,
		bank: userMsg.bank || initialUser.bank,
		opening_bank: userMsg.opening_bank || initialUser.opening_bank,
	});
	const cancel = (withRefresh) => {
		if (withRefresh) {
			refetch();
		}
		setItemIdForUpdate(undefined);
	};

	// const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
	// const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

	const formik = useFormik({
		initialValues: userForEdit,
		validationSchema: editUserSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				updateUser(values);
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				cancel(true);
			}
		},
	});
	const updateUser = async (values) => {
		const resp = await request.put(`/users/${user.user_id}`, {
			...values,
			bank_card_no: String(values.bank_card_no),
		});
		if (resp.user_id) {
			toast.success('修改银行卡成功');
			action.reload();
		}
		setShowModal(false);
	};
	const getUserInfo = async () => {
		const resp = await request.get(`/users/${user.user_id}`);
		formik.setFieldValue('bank_card_no', resp.bank_card_no);
		formik.setFieldValue('bank', resp.bank);
		formik.setFieldValue('opening_bank', resp.opening_bank);
		setUserMsg(resp);
	};
	useEffect(() => {
		getUserInfo();
	}, []);
	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				{/* begin::Scroll */}
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					{/* begin::Cancel */}
					{/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
					{/* end::Cancel */}

					{/* begin::Remove */}
					{/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Remove avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
					{/* end::Remove */}
					{/* </div> */}
					{/* end::Image input */}

					{/* begin::Hint */}
					{/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
					{/* end::Hint */}
					{/* </div> */}
					{/* end::Input group */}
					<div className="fv-row mb-7">
						{/* <div className="symbol symbol-100px symbol-circle mb-7 d-flex justify-content-center">
							<img
								src="https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-6.jpg"
								alt=""
								className="me-10"
							/>
							<div className="d-flex flex-column justify-content-around">
								<b>{user.full_name}</b>
								<i>{user.phone}</i>
								<b
									className={`badge fs-7 m-1 ${
										user.user_type === 'TRADER'
											? 'badge-light-primary'
											: user.user_type === 'AGENT'
											? 'badge-light-warning'
											: 'badge-light-success'
									}`}
								>
									{user.user_type === 'TRADER'
										? '普通用户'
										: user.user_type === 'AGENT'
										? '代理'
										: '管理员'}
								</b>
							</div>
						</div> */}
						<div className="card shadow-sm badge-light p-3">
							<h5>{user.full_name}</h5>
							<div className="flex" style={{ lineHeight: '17px' }}>
								<span className="flex">
									<KTSVG path="/media/icons/duotune/electronics/elc002.svg" />
									<span className="ms-1">{user.phone}</span>
								</span>
								<span className="flex ms-3">
									<KTSVG path="/media/icons/duotune/communication/com006.svg" />
									<span className="ms-1">
										{user.user_type === 'TRADER'
											? '普通用户'
											: user.user_type === 'AGENT'
											? '代理'
											: '管理员'}
									</span>
								</span>
								{(userMsg.parent_username || userMsg.parent_phone) && (
									<span className="flex ms-3">
										<KTSVG path="/media/icons/duotune/communication/com014.svg" />
										<span className="ms-1">
											{`${userMsg.parent_username}(${
												userMsg.parent_phone || '无'
											})`}
										</span>
									</span>
								)}
								<span className="flex ms-3">
									<KTSVG path="/media/icons/duotune/general/gen047.svg" />
									<span
										className="ms-1"
										style={{
											color: `${userMsg.is_blocked ? '#ff4d4f' : '#50cd89'}`,
										}}
									>
										{userMsg.is_blocked ? '锁定' : '正常'}
									</span>
								</span>
							</div>
						</div>
					</div>
					{/* begin::Input group */}
					<div className="fv-row mb-7">
						{/* begin::Label */}
						<label className="required fw-bold fs-6 mb-2">银行卡号</label>
						{/* end::Label */}

						{/* begin::Input */}
						<input
							placeholder="银行卡号"
							{...formik.getFieldProps('bank_card_no')}
							className={clsx(
								'form-control form-control-solid mb-3 ms-1 mb-lg-0',
								{
									'is-invalid':
										formik.touched.bank_card_no && formik.errors.bank_card_no,
								},
								{
									'is-valid':
										formik.touched.bank_card_no && !formik.errors.bank_card_no,
								}
							)}
							type="text"
							name="bank_card_no"
							autoComplete="off"
							disabled={formik.isSubmitting || isUserLoading}
						/>
						{/* end::Input */}
						{formik.touched.bank_card_no && formik.errors.bank_card_no && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.bank_card_no}</span>
								</div>
							</div>
						)}
					</div>
					{/* end::Input group */}
					{/* begin::Input group */}
					<div className="fv-row mb-7">
						{/* begin::Label */}
						<label className="required fw-bold fs-6 mb-2">银行名称</label>
						{/* end::Label */}

						{/* begin::Input */}
						<input
							placeholder="银行名称"
							{...formik.getFieldProps('bank')}
							className={clsx(
								'form-control form-control-solid mb-3 ms-1 mb-lg-0',
								{
									'is-invalid': formik.touched.bank && formik.errors.bank,
								},
								{
									'is-valid': formik.touched.bank && !formik.errors.bank,
								}
							)}
							type="text"
							name="bank"
							autoComplete="off"
							disabled={formik.isSubmitting || isUserLoading}
						/>
						{/* end::Input */}
						{formik.touched.bank && formik.errors.bank && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.bank}</span>
								</div>
							</div>
						)}
					</div>
					{/* end::Input group */}
					{/* begin::Input group */}
					<div className="fv-row mb-7">
						{/* begin::Label */}
						<label className="required fw-bold fs-6 mb-2">开户行</label>
						{/* end::Label */}

						{/* begin::Input */}
						<input
							placeholder="开户行"
							{...formik.getFieldProps('opening_bank')}
							className={clsx(
								'form-control form-control-solid mb-3 ms-1 mb-lg-0',
								{
									'is-invalid':
										formik.touched.opening_bank && formik.errors.opening_bank,
								},
								{
									'is-valid':
										formik.touched.opening_bank && !formik.errors.opening_bank,
								}
							)}
							type="text"
							name="opening_bank"
							autoComplete="off"
							disabled={formik.isSubmitting || isUserLoading}
						/>
						{/* end::Input */}
						{formik.touched.opening_bank && formik.errors.opening_bank && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.opening_bank}</span>
								</div>
							</div>
						)}
					</div>
					{/* end::Input group */}
				</div>
				{/* end::Scroll */}

				{/* begin::Actions */}
				<div className="text-center pt-10">
					<button
						type="reset"
						onClick={() => setShowModal(false)}
						className="btn btn-light me-3"
						data-kt-users-modal-action="cancel"
						disabled={formik.isSubmitting || isUserLoading}
					>
						取消
					</button>

					<button
						type="submit"
						className="btn btn-primary"
						data-kt-users-modal-action="submit"
						disabled={
							isUserLoading ||
							formik.isSubmitting ||
							!formik.isValid ||
							!formik.touched
						}
						data-kt-indicator={
							formik.isSubmitting || isUserLoading ? 'on' : 'off'
						}
					>
						<span className="indicator-label">确定</span>
						{(formik.isSubmitting || isUserLoading) && (
							<span className="indicator-progress">
								等待中...{' '}
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</button>
				</div>
				{/* end::Actions */}
			</form>
			{(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
		</>
	);
};

export { BankCardModifyModalForm };
