/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import PropTypes from 'prop-types';

const ResetSetting = (props) => {
	const {
		setShowModal,
		setSettingTable,
		getSetting,
		data,
		action,
		type,
		tabActive,
	} = props;
	const [disabled, setDisabled] = useState(false);
	useEffect(() => {
		document.body.classList.add('modal-open');
		return () => {
			document.body.classList.remove('modal-open');
		};
	}, []);
	const resetSetting = async () => {
		setDisabled(true);
		// await request.put(`/users/setting/${data.user_id}`, {
		// 	setting_type: 'transaction',
		// 	setting: {},
		// });
		let settingSys = '';
		if (tabActive == 7) {
			settingSys = 'EXCHANGE';
		} else if (tabActive == 4) {
			settingSys = 'INDONESIA';
		} else if (tabActive == 8) {
			settingSys = 'INDONESIA_GOLD';
		}
		await request.put(`/users/setting/${data.user_id}`, {
			setting_type: type.toUpperCase(),
			setting_sys: settingSys,
			// setting: {},
		});
		toast('重置成功');
		setSettingTable(false);
		setShowModal(false);
		getSetting();
		action.reload();
	};
	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_add_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-500px">
					{/* begin::Modal content */}
					<div className="modal-content">
						{/* begin::Modal body */}
						<div
							className="modal-body flex-column mx-3 mx-xl-5 my-7"
							style={{ textAlign: 'center' }}
						>
							{/* end::Input group */}
							<KTSVG
								path="/media/icons/duotune/general/gen044.svg"
								className="svg-icon-3tx svg-icon-warning me-4"
							/>
							<div className="d-flex flex-column mt-3">
								<p className="text-gray-800">
									{data.full_name} {data.phone} 确定清空用户
									{type === 'transaction'
										? '交易'
										: type === 'fee'
										? '手续费'
										: '存取款'}
									设置？
								</p>
								<p className="text-muted">
									清空后，用户无特殊设置，遵循平台设置
								</p>
							</div>
						</div>
						<div className="text-center mb-10">
							<button
								type="reset"
								onClick={() => setShowModal(false)}
								className="btn btn-light me-3"
								data-kt-users-modal-action="cancel"
							>
								取消
							</button>

							<button
								type="submit"
								className="btn btn-primary"
								data-kt-users-modal-action="submit"
								onClick={resetSetting}
								disabled={disabled}
							>
								<span className="indicator-label">确定</span>
							</button>
						</div>
						{/* end::Modal body */}
					</div>
					{/* end::Modal content */}
				</div>
				{/* end::Modal dialog */}
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};

ResetSetting.propTypes = {
	setShowModal: PropTypes.func,
	getSetting: PropTypes.func,
	data: PropTypes.object,
	action: PropTypes.object,
	type: PropTypes.string,
	tabActive: PropTypes.number,
};
export { ResetSetting };
