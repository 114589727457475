import * as Yup from 'yup';

export const editUserSchema1 = Yup.object().shape({
	password1: Yup.string()
		.required('密码不能为空')
		.matches(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}$/,
			'8~12位，包含大小写字母、数字或特殊字符'
		),
	password2: Yup.string()
		.required('密码不能为空')
		.when('password1', {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf([Yup.ref('password1')], '确认密码与密码不一致'),
		}),
});

export const editUserSchema2 = Yup.object().shape({
	username: Yup.string()
		.min(2, '请输入不小于2位数的用户名')
		.required('用户名不能为空'),
	// phone: Yup.string()
	// 	.required('手机号不能为空')
	// 	.matches(/^[1][3,4,5,6.7,8,9][0-9]{9}$/, '请正确填写手机号'),
	phone: Yup.string()
		.required('电话号码不能为空'),
		// .matches(
		// 	/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
		// 	'请正确填写身份证号码'
		// ),
});

export const editUserSchema4 = Yup.object().shape({
	max_lever: Yup.string().required('最大买卖杠杆不能为空'),
	// commission: Yup.string().required('交易手续费不能为空'),
	commission: Yup.string().required('交易利息不能为空'),
});
export const editUserSchema5 = Yup.object().shape({
	fund_password1: Yup.string()
		.required('请输入交易密码')
		.matches(/^\d{6}$/, '6位数字'),
	fund_password2: Yup.string()
		.required('请输入确认交易密码')
		.when('fund_password1', {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref('fund_password1')],
				'确认密码与密码不一致'
			),
		}),
	mfa_code: Yup.string().required('请输入Google验证码'),
});
export const editUserSchema6 = Yup.object().shape({
	full_name: Yup.string()
		.min(2, '请输入不小于2位数的真实姓名')
		.required('真实姓名不能为空'),
	// phone: Yup.string()
	// 	.required('手机号不能为空')
	// 	.matches(/^[1][3,4,5,6.7,8,9][0-9]{9}$/, '请正确填写手机号'),
	id_no: Yup.string()
		.required('身份证号码不能为空'),
		// .matches(
		// 	/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
		// 	'请正确填写身份证号码'
		// ),
});
export const editAgentSchema2 = Yup.object().shape({
	full_name: Yup.string()
		.min(2, '请输入不小于2位数的真实姓名')
		.required('真实姓名不能为空'),
	phone: Yup.string()
		.required('手机号不能为空'),
		// .matches(/^[1][3,4,5,6.7,8,9][0-9]{9}$/, '请正确填写手机号'),
	username: Yup.string()
		.min(2, '请输入不小于2位数的真实姓名')
		.required('真实姓名不能为空'),
});
export const editAdminSchema1 = Yup.object().shape({
	password1: Yup.string()
		.required('密码不能为空')
		.matches(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}$/,
			'8~12位，包含大小写字母、数字或特殊字符'
		),
	password2: Yup.string()
		.required('密码不能为空')
		.when('password1', {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf([Yup.ref('password1')], '确认密码与密码不一致'),
		}),
});
export const editAdminSchema2 = Yup.object().shape({
	username: Yup.string()
		.min(2, '请输入不小于2位数的真实姓名')
		.required('真实姓名不能为空'),
	user_type: Yup.string().required('请选择角色类型'),
});
// export const editUserSchema3 = Yup.object().shape({
// 	parent_user_id: Yup.string().required('请选择所属代理'),
// });

export const userRoleArr = [
	{
		label: '超级管理员',
		value: 'ADMINISTRATOR',
	},
	{
		label: '系统管理员',
		value: 'ADMINISTRATOR_SYS',
	},
	{
		label: '客服管理员',
		value: 'ADMINISTRATOR_CUS',
	},
];
export const items1 = [
	{
		key: '1',
		label: `修改登录密码`,
	},
	{
		key: '2',
		label: `修改个人信息`,
	},
];
export const items2 = [
	{
		key: '3',
		label: `修改所属代理`,
	},
];
export const items3 = [
	{
		key: '7',
		label: '存取款设置',
	},
	{
		key: '4',
		label: `股票风控`,
	},
	{
		key: '8',
		label: `黄金风控`,
	},
	{
		key: '5',
		label: `修改交易密码`,
	},
];
export const items4 = [
	{
		key: '6',
		label: `修改认证信息`,
	},
];
export const enumText = {
	is_financing: {
		true: '可融资',
		false: '不可融资',
	},
	is_short_sale: {
		true: '可融券',
		false: '不可融券',
	},
	stop_buying_switch: {
		true: '是',
		false: '否',
	},
};
