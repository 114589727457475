import React from 'react';
import PropTypes from 'prop-types';

const CustomizedStatisticsWidget4 = ({ className, data, chart = '' }) => {
	return (
		<div className={`card ${className}`}>
			{/* begin::Body */}
			<div className="card-body p-0">
				<div className="card-p">
					<div className="d-flex align-items-center justify-content-between">
						{data.map((item, index) => (
							<div key={index} className="d-flex flex-column">
								<span className="text-dark fw-bold fs-2">
									{new Intl.NumberFormat('zh-CN', {
										currency: 'CNY',
									}).format(item.count)}
								</span>
								<span className="text-muted fw-semibold mt-1">
									{item.title}
								</span>
							</div>
						))}
					</div>
				</div>
				{chart}
			</div>
			{/* end::Body */}
		</div>
	);
};

CustomizedStatisticsWidget4.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array,
	chart: PropTypes.node,
};

export default CustomizedStatisticsWidget4;
